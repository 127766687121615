import React from "react";
import { Avatar } from "@mui/material";
import style from "../../CRM/TaskModal2/style.scss"; // don't delete

const Comment = ({ comment }) => {
  const { user, text, date } = comment;

  // Get the first letter of the user's name
  const userInitial = user.charAt(0);

  return (
    <div className="taskModalComment">
      <Avatar sx={{ width: 32, height: 32 }}>{userInitial}</Avatar>
      <div>
        <div className="taskModalCommentUser">{user}</div>
        <div className="taskModalCommentText">{text}</div>
        <div style={{ color: "#666", fontSize: "0.8rem" }}>{date}</div>
      </div>
    </div>
  );
};

export default Comment;
