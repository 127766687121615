import { Button, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import mainAPI from '../../../../utils/mainAPI';
import { toast } from 'react-toastify';

const CreativeDialog = ({ open, close, refresh }) => {
  const id = Date.now();
  const [type, setType] = useState('cpa');
  const [form, setForm] = useState('banner');
  const [urls, setUrls] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [okveds, setOkveds] = useState(null);
  const [fiasRegionList, setFiasRegionList] = useState([]);
  const [textData, setTextData] = useState(null);
  const [isSocial, setIsSocial] = useState(false);
  const [isNative, setIsNative] = useState(false);
  const [description, setDescription] = useState(null);
  const [mediaDescription, setMediaDescription] = useState(null);
  const [fileName, setFileName] = useState('');
  const fileInput = useRef();
  const [token, setToken] = useState(null);
  function fileChange(e) {
    submitFile();
  }
  function submitFile(e) {
    const formData = new FormData();
    formData.append('creo', fileInput.current.files[0]);
    try {
      mainAPI
        .post('/ord/file', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          setFileName(res.data);
          toast.success('Успешно');
        })
        .catch((e) => {
          toast.error('Ошибка');
          close();
        });
    } catch (e) {}
  }
  function postCreo() {
    const data = {
      id,
      type,
      form,
      urls: urls ? urls.split(',') : [],
      contractId,
      okveds: okveds ? okveds.split(',') : [],
      fiasRegionList: fiasRegionList.length ? fiasRegionList.split(',') : [],
      textData: [textData],
      isNative,
      isSocial,
      description,
      mediaData: [
        {
          mediaUrl: 'https://2leads.dev/api/ord/file/' + fileName,
          description: mediaDescription,
        },
      ],
    };
    mainAPI
      .post('/ord/creative', data)
      .then((res) => {
        setToken(res.data);
        toast.success('Успешно');
      })
      .catch((e) => {
        toast.error(JSON.stringify(e.response.data));
        console.log(e.response.data);
      });
  }

  return (
    <Dialog open={open} fullWidth aria-labelledby='watchtoken-title' aria-describedby='watchtoken-description'>
      <DialogTitle style={{ textAlign: 'center' }}>Добавление креатива</DialogTitle>
      {token ? (
        <DialogContent>{token}</DialogContent>
      ) : (
        <DialogContent sx={{ minWidth: '200px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <InputLabel>Тип кампании</InputLabel>
              <Select size='small' style={{ width: '180px' }} value={type} onChange={(e) => setType(e.target.value)}>
                <MenuItem value='cpa'>cpa</MenuItem>
                <MenuItem value='cpc'>cpc</MenuItem>
                <MenuItem value='cpm'>cpm</MenuItem>
                <MenuItem value='other'>other</MenuItem>
              </Select>
            </div>

            <div>
              <InputLabel>Форма рекламы</InputLabel>
              <Select size='small' style={{ width: '180px' }} value={form} onChange={(e) => setForm(e.target.value)}>
                <MenuItem value='banner'>Баннер</MenuItem>
                <MenuItem value='text-block'>Текстовый блок</MenuItem>
                <MenuItem value='ext-graphic-block'>Текстово-графический блок</MenuItem>
                <MenuItem value='video'>Видеоролик</MenuItem>
                <MenuItem value='audio-rec'>Аудиозапись</MenuItem>
                <MenuItem value='live-video'>Видеотрансляция в прямом эфире</MenuItem>
                <MenuItem value='live-audio'>Аудиотрансляция в прямом эфире</MenuItem>
                <MenuItem value='other'>Иное</MenuItem>
              </Select>
            </div>
            <div>
              <InputLabel> Договор</InputLabel>
              <TextField
                size='small'
                style={{ width: '180px' }}
                value={contractId}
                onChange={(e) => setContractId(e.target.value)}
              />
            </div>
          </div>
          <div>
            <InputLabel>Целевые ссылки, через запятую</InputLabel>
            <TextField
              size='small'
              fullWidth
              value={urls}
              onChange={(e) => {
                setUrls(e.target.value);
              }}
            />
          </div>

          <InputLabel>ОКВЭД через запятую</InputLabel>
          <TextField size='small' fullWidth value={okveds} onChange={(e) => setOkveds(e.target.value)} />

          <InputLabel>Текстовые данные креатива.</InputLabel>
          <TextField fullWidth size='small' value={textData} onChange={(e) => setTextData(e.target.value)} />
          <InputLabel>Описание изображения креатива</InputLabel>
          <TextField
            size='small'
            fullWidth
            value={mediaDescription}
            onChange={(e) => setMediaDescription(e.target.value)}
          />

          <InputLabel>Общее описание объекта рекламирования</InputLabel>
          <TextField size='small' fullWidth value={description} onChange={(e) => setDescription(e.target.value)} />

          <p>
            Уникальные идентификаторы ФИАС регионов показа через запятую. Если массив пустой, то регионом показа
            считается вся Россия
          </p>
          <TextField
            fullWidth
            size='small'
            placeholder='ФИАС'
            value={fiasRegionList}
            onChange={(e) => setFiasRegionList(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                required
                size='small'
                checked={isSocial}
                onChange={(e) => {
                  setIsSocial(e.target.checked);
                }}
              />
            }
            label='Признак социальной рекламы'
          />
          <FormControlLabel
            control={
              <Checkbox
                required
                size='small'
                checked={isNative}
                onChange={(e) => {
                  setIsNative(e.target.checked);
                }}
              />
            }
            label='Признак нативной рекламы '
          />
        </DialogContent>
      )}
      <DialogActions>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <input onChange={fileChange} ref={fileInput} type='file' />
          <div>
            <Button
              onClick={() => {
                postCreo();
              }}
            >
              Сохранить
            </Button>
            <Button
              onClick={() => {
                refresh();
                close();
              }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreativeDialog;
