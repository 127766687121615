import { useState } from 'react';
import { toast } from 'react-toastify';

import GoogleService from '../../../services/GoogleService';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

/**
 *
 * @props
 * @param {boolean} open
 * @param {function} onClose
 * @param {import('socket.io-client').Socket} socket
 * @returns {JSX.Element}
 */
export const AddAccountDialog = ({ open, onClose, socket }) => {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    social: '',
    buyerId: '',
    contractorId: '',
    email: '',
    spent: 0,
    note: '',
  });

  const handleChange = (field) => (e) => {
    let value = e.target.value;
    if (field === 'spent') {
      value = Number(value) || 0;
    }
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const requiredFields = ['id', 'name', 'email'];
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].trim() === '') {
        return false;
      }
    }
    if (!isValidEmail(formData.email)) {
      console.error('Invalid email format.');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      console.error('Validation failed: required fields are empty, contain only spaces, or email is invalid.');
      return;
    }

    try {
      const cleanedData = Object.fromEntries(
        Object.entries(formData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
      );
      await GoogleService.addAccount(cleanedData);
      setFormData({
        id: '',
        name: '',
        social: '',
        buyerId: '',
        contractorId: '',
        email: '',
        spent: 0,
        note: '',
      });
      onClose();
      toast.success('Аккаунт успешно создан');
      await socket.emit('accountChanged');
    } catch (error) {
      console.error('Error creating account:', error);
      toast.error('Ошибка при создании аккаунта');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Добавление нового аккаунта</DialogTitle>
      <DialogContent>
        <TextField label='ID' fullWidth margin='normal' value={formData.id} onChange={handleChange('id')} />
        <TextField label='Название' fullWidth margin='normal' value={formData.name} onChange={handleChange('name')} />
        <TextField
          label='Номер соца'
          fullWidth
          margin='normal'
          value={formData.social}
          onChange={handleChange('social')}
        />
        {/* <TextField
          label='Buyer'
          fullWidth
          margin='normal'
          value={formData.buyerId}
          onChange={handleChange('buyerId')}
        />
        <TextField
          label='Подрядчик'
          fullWidth
          margin='normal'
          value={formData.contractorId}
          onChange={handleChange('contractorId')}
        /> */}
        <TextField
          label='Email'
          fullWidth
          margin='normal'
          value={formData.email}
          onChange={handleChange('email')}
          error={!isValidEmail(formData.email) && formData.email !== ''}
          helperText={!isValidEmail(formData.email) && formData.email !== '' ? 'Некорректный email' : ''}
        />
        <TextField
          label='Баланс'
          type='number'
          fullWidth
          margin='normal'
          value={formData.spent}
          onChange={handleChange('spent')}
        />
        <TextField
          label='Комментарии'
          fullWidth
          margin='normal'
          multiline
          rows={3}
          value={formData.note}
          onChange={handleChange('note')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Отмена
        </Button>
        <Button onClick={handleSave} color='primary' variant='contained' disabled={!validateForm()}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
