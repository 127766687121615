import React, { useEffect, useState } from "react";
import mainAPI from "../../../utils/mainAPI";
import { DragDropContext } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import CardsColumn from "../CardsColumn";

const CardsAdmin = () => {
  const [allCards, setAllCards] = useState([]);

  useEffect(() => {
    getAllCards();
  }, []);
  function getAllCards() {
    mainAPI.get("/cards/all").then((res) => {
      setAllCards(res.data);
    });
  }
  function changeCardStatus(id, status, day) {
    mainAPI
      .post("/cards/status", {
        id: id,
        status: status,
        day: day,
      })
      .then(() => {
        getAllCards();
      })
      .catch((e) => {
        toast.error("Error");
      });
  }
  async function handleOnDragEnd(result) {
    if (result.source && result.destination) {
      if (result.source.droppableId !== result.destination.droppableId) {
        const data = result.destination.droppableId.split("_");
        changeCardStatus(result.draggableId, data[2]);
      }
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div>
          <div className="account_days">
            <CardsColumn status="1" data={allCards} refresh={getAllCards} />
            <CardsColumn status="2" data={allCards} refresh={getAllCards} />
            <CardsColumn status="3" data={allCards} refresh={getAllCards} />
            <CardsColumn status="4" data={allCards} refresh={getAllCards} />
            <CardsColumn status="5" data={allCards} refresh={getAllCards} />
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

export default CardsAdmin;
