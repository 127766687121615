import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import GoogleService from '../../../services/GoogleService';

const EditNoteDialog = ({ open, closeCb, accId, note }) => {
  const [value, setValue] = useState(note);

  async function updateNote() {
    try {
      await GoogleService.updateNote(accId, value.trim());
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Заметка</DialogTitle>
      <DialogContent>
        <TextField value={value} onChange={handleChange} multiline rows={10} />
      </DialogContent>
      <DialogActions>
        <Button onClick={updateNote}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNoteDialog;
