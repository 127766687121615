import {types} from "mobx-state-tree";

const capsStore = types
    .model("capsStore", {
        favoriteItems: types.array(types.string)
    })
    .actions(self => ({
        loadAllItems() {
            const all = localStorage.getItem('favorCaps')
            if (all) {
                self.favoriteItems = JSON.parse(all)
            } else {
                self.favoriteItems = []
            }
        },
        addFavoriteItem(value) {
            if (self.favoriteItems.toJSON().indexOf(value) === (-1)) {
                self.favoriteItems.push(value)
                localStorage.setItem('favorCaps', JSON.stringify(self.favoriteItems.toJSON()))
            }
        },
        removeFavoriteItem(value) {
            const position = self.favoriteItems.toJSON().indexOf(value)
            self.favoriteItems.splice(position, 1)
            localStorage.setItem('favorCaps', JSON.stringify(self.favoriteItems.toJSON()))
        }
    }))
    .views(self => ({
        get allFavoriteItems() {
            return self.favoriteItems.toJSON()
        }
    }))


export default capsStore;
