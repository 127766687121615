import { makeAutoObservable, toJS } from "mobx";
import CrmService from "../utils/crmAPI";
export default class CRMstore {
  projects = [];
  loader = false;

  user = "";

  currentProject = 0;

  openModal = false;

  userList = [];

  constructor() {
    makeAutoObservable(this);
  }

  handleLoader(bool) {
    this.loader = bool;
  }

  setOpenModal(id) {
    this.openModal = id;
  }
  viewProjects() {
    return this.projects;
  }
  async getProjects(user) {
    // socket.emit("SubscribeToProjects", this.user);
    const response = await CrmService.getProjects(user);
    console.log(response);
    this.projects = toJS(response.data);
    // this.handleLoader(true);
  }

  async getSingleProject(projectId) {
    const response = await CrmService.getSingleProject(projectId);
    this.currentProject = toJS(response.data);

    if (
      this.currentProject.users.includes(this.user) ||
      this.currentProject.admins.includes(this.user)
    ) {
      localStorage.setItem("lastProjectId", projectId);
    }
  }
  async refreshProject() {
    const response = await CrmService.getSingleProject(
      toJS(this.currentProject.id)
    );
    this.currentProject = toJS(response.data);
  }

  updateProject(fetchedProject) {
    if (this.currentProject.id === fetchedProject.id) {
      this.currentProject = fetchedProject;
    }
  }

  setUser(userLogin) {
    this.user = userLogin;
  }

  setUsersList(userList) {
    this.userList = userList.sort();
  }

  async addUserToProject(addedUser) {
    if (!this.currentProject.users.includes(addedUser)) {
      const newUserList = [...this.currentProject.users, addedUser];
      await CrmService.changeProjectUsers(newUserList, this.currentProject.id);
    }
  }

  async deleteUserFromProject(deletedUser) {
    if (this.currentProject.users.includes(deletedUser)) {
      const newUserList = this.currentProject.users.filter(
        (user) => user !== deletedUser
      );
      await CrmService.changeProjectUsers(newUserList, this.currentProject.id);
    }
  }

  changeProjectUsersClient(newUserList) {
    this.currentProject.users = newUserList;
  }

  async setTasks(newTasksData, projectId) {
    await CrmService.changeTask(newTasksData, projectId);
  }

  async createNewProject() {
    const newProject = {
      id: `project-${Math.random()}`,
      name: "Новый проект",
      admins: ["dev", "admin", this.user],
      users: ["dev"],
      tags: [],
      taskCounter: 1,
      columns: [
        {
          id: `column-${Math.random()}`,
          columnName: "Сделать",
          icon: "⭕️",
        },
        {
          id: `column-${Math.random()}`,
          columnName: "В процессе",
          icon: "🔆️",
        },
        {
          id: `column-${Math.random()}`,
          columnName: "На рассмотрении",
          icon: "📝",
        },
        {
          id: `column-${Math.random()}`,
          columnName: "Готово",
          icon: "✅",
        },
      ],
      tasks: [],
    };
    await CrmService.createProject(newProject);
  }

  async createNewСolumn() {
    const newColumn = {
      id: `column-${Math.random()}`,
      columnName: "to do",
      icon: "⭐",
    };
    await CrmService.createСolumn(
      [...this.currentProject.columns, newColumn],
      this.currentProject.id
    );
  }

  createСolumnClient(newColumn) {
    this.currentProject.columns.push(newColumn);
  }

  async createNewTask(status, icon, projectId) {
    const newTask = {
      id: `task-${Math.random()}`,
      orderId: this.currentProject.taskCounter,
      icon: icon,
      status: status,
      sortDate: Date.now(),
      title: "Новая задача",
      content: "",
      tag: [],
      createdBy: this.user,
      link: "",
      assignedTo: "all",
      date:
        ("0" + new Date().getHours()).slice(-2) +
        ":" +
        ("0" + new Date().getMinutes()).slice(-2) +
        " " +
        ("0" + new Date().getDate()).slice(-2) +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        new Date().getFullYear(),
      media: [],
    };
    await CrmService.createTask(
      [...this.currentProject.tasks, newTask],
      projectId,
      this.user,
      newTask.id
    );
    // this.currentProject.tasks.push(newTask);
  }

  createTaskClient(newTasksData, userOpen, taskId, taskCounter) {
    this.currentProject.tasks = newTasksData;
    this.currentProject.taskCounter = taskCounter;
    if (this.user === userOpen) {
      this.setOpenModal(taskId);
    }
    function notifyMe() {
      const notification = new Notification(
        `На доску "${this.currentProject.name}" добавлена таска`,
        {
          tag: "ache-mail",
        }
      );
    }
    if (!("Notification" in window))
      alert("Ваш браузер не поддерживает уведомления.");
    else if (Notification.permission === "granted") setTimeout(notifyMe, 200);
    else if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        if (!("permission" in Notification))
          Notification.permission = permission;
        if (permission === "granted") setTimeout(notifyMe, 200);
      });
    }
    if ("Notification" in window) {
      if (window.Notification.permission === "granted") {
        new Notification(
          `На доску "${this.currentProject.name}" добавлена таска`
        );
      }
    }
  }

  async deleteTask(taskId, projectId) {
    await CrmService.deleteTask(taskId, projectId);
  }

  deleteTaskClient(taskId) {
    this.currentProject.tasks = this.currentProject.tasks.filter(
      (task) => task.id !== taskId
    );
  }

  async deleteColumn(columnId) {
    await CrmService.deleteColumn(columnId, this.currentProject.id);
  }

  deleteColumnClient(columnId) {
    this.currentProject.columns = this.currentProject.columns.filter(
      (column) => column.id !== columnId
    );
  }

  async deleteProject(projectId) {
    await CrmService.deleteProject(projectId);
  }

  async changeProjectName(newProjectName) {
    await CrmService.changeProjectName(newProjectName, this.currentProject.id);
  }

  changeProjectNameClient(newProjectName) {
    this.currentProject.name = newProjectName;
  }

  async changeProjectTags(newProjectTags) {
    await CrmService.changeProjectTags(newProjectTags, this.currentProject.id);
  }

  changeProjectTagsClient(newProjectTags) {
    this.currentProject.tags = newProjectTags;
  }

  async changeColumnName(columnId, newColumnName) {
    const newColumnsData = this.currentProject.columns.map((column) => {
      if (column.id === columnId) {
        column.columnName = newColumnName;
        return column;
      }
      return column;
    });
    await CrmService.changeСolumn(newColumnsData, this.currentProject.id);
  }

  changeColumnClient(newColumnsData) {
    this.currentProject.columns = newColumnsData;
  }

  async changeTaskData(newTaskData, taskId) {
    const newTasks = this.currentProject.tasks.map((task) => {
      if (task.id === taskId) {
        task.assignedTo = newTaskData.assignedTo;
        task.createdBy = newTaskData.createdBy;
        task.content = newTaskData.content;
        task.tag = newTaskData.tag;
        task.title = newTaskData.title;
        task.link = newTaskData.link;
        task.comments = newTaskData.comments;
        return task;
      }
      return task;
    });
    await CrmService.changeTask(newTasks, this.currentProject.id);
  }

  changeTaskClient(newTasksData) {
    this.currentProject.tasks = newTasksData;
  }

  async uploadFiles(files, taskId) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file, file.name);
    });
    const res = await CrmService.uploadFiles(formData);
    const newTasks = this.currentProject.tasks.map((task) => {
      if (task.id === taskId) {
        task.media = [...task.media, ...res.data];
        return task;
      }
      return task;
    });
    await CrmService.changeTask(newTasks, this.currentProject.id);
  }

  async deleteFile(fileId, taskId) {
    await CrmService.deleteFile(fileId);
    const newTasks = this.currentProject.tasks.map((task) => {
      if (task.id === taskId) {
        task.media = task.media.filter((file) => file.id !== fileId);
        return task;
      }
      return task;
    });
    await CrmService.changeTask(newTasks, this.currentProject.id);
  }
}
