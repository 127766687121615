import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import useStore from '../../../../hooks/useStore';
import mainAPI from '../../../../utils/mainAPI';

import {
  Dialog,
  DialogActions,
  IconButton,
  DialogTitle,
  TextField,
  Paper,
  Button,
  Typography,
  Pagination,
  Popover,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TableSortLabel,
  Switch,
  FormControlLabel,
} from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import { CustomSkeleton } from '../../components';

import css from './streamsAdspect.module.scss';

export const StreamsAdspect = () => {
  const modes = {
    Filter: 'Фильтр',
    Review: 'Модерация',
    Money: 'Контент',
    White: 'Белая страница',
  };

  const [loading, setLoading] = useState(true);
  const [noLoading, setNoLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [streams, setStreams] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [streamsPerPage, setStreamsPerPage] = useState(20);

  const [anchorEls, setAnchorEls] = useState({});
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [customStreamModes, setCustomStreamModes] = useState({});

  const { appStore } = useStore();

  useEffect(() => {
    loadStreams();
  }, []);

  const user = appStore.userRoles.includes('admin') ? '' : appStore.userLogin;

  const loadStreams = async () => {
    setLoading(true);
    try {
      const response = await mainAPI.get(`/cloaker/getstreams/${user}`);
      const { data } = response.data;

      const modes = {};
      data.forEach((stream) => {
        modes[stream.stream_id] = stream.customMode === 'auto' ? 'auto' : 'manual';
      });
      setCustomStreamModes(modes);

      const sortedStreams = sortStreams(data, getComparator(order, orderBy));
      setStreams(sortedStreams);
      setNoLoading(false);
    } catch (error) {
      console.log('Не удалось загрузить потоки', error);
      setNoLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const changeMode = async (id, mode) => {
    try {
      await mainAPI.patch(`/cloaker/changemode/${id}/${mode}`);
      toast.success('Успешно');
      loadStreams();
    } catch (error) {
      toast.error('Произошла ошибка при смене режима', error);
    }
  };

  const handleStreamModeChange = async (streamId, countries) => {
    const currentMode = customStreamModes[streamId];
    const newMode = currentMode === 'auto' ? 'manual' : 'auto';

    try {
      await mainAPI.patch(`/cloaker/changecustommode/${streamId}`, {
        custommode: newMode,
        countries: countries || [],
      });

      setCustomStreamModes((prevModes) => ({
        ...prevModes,
        [streamId]: newMode,
      }));
      toast.success('Режим успешно изменён');
    } catch (error) {
      console.error('Ошибка при смене режима:', error);
      toast.error('Ошибка при смене режима');
    }
  };

  const deleteStream = async (id) => {
    try {
      await mainAPI.delete(`/cloaker/deletestream/${id}`);
      toast.success('Успешно');
      setDeleteDialog(false);
      loadStreams();
    } catch (error) {
      toast.error('Произошла ошибка при удалении потока', error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleStreamsPerPageChange = (event) => {
    setStreamsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleMenuOpen = (event, streamId) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [streamId]: event.currentTarget,
    }));
  };

  const handleMenuClose = (streamId) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [streamId]: null,
    }));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedStreams = sortStreams(streams, getComparator(isAsc ? 'desc' : 'asc', property));
    setStreams(sortedStreams);
  };

  const sortStreams = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filteredStreams = streams.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  const indexOfLastStream = currentPage * streamsPerPage;
  const indexOfFirstStream = indexOfLastStream - streamsPerPage;
  const currentStreams = filteredStreams.slice(indexOfFirstStream, indexOfLastStream);

  return (
    <>
      {noLoading && (
        <div className={css.noStreams}>
          <h5>Не удалось загрузить потоки по Вашему логину ;(</h5>
        </div>
      )}

      <div className={css.reload}>
        <div className={css.reloadControl}>
          <Typography>Обновить список потоков:</Typography>
          <IconButton
            onClick={() => {
              loadStreams();
            }}
            aria-label='reload'
            disabled={loading}
          >
            <ReplayIcon />
          </IconButton>
        </div>

        <div className={css.paginationControl}>
          <FormControl variant='outlined' className={css.formControl}>
            <InputLabel id='per-page-select-label'>Потоков на странице</InputLabel>
            <Select
              labelId='per-page-select-label'
              value={streamsPerPage}
              onChange={handleStreamsPerPageChange}
              label='Потоков на странице'
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={css.search}>
        <TextField
          label='Поиск потоков..'
          variant='outlined'
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {loading && CustomSkeleton()}
      {!loading && !noLoading && (
        <div className={css.sortHeaders}>
          <Button onClick={() => handleRequestSort('name')}>
            <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? order : 'asc'}>
              Название
            </TableSortLabel>
          </Button>
          <Typography className={css.statusLabel}>Статус</Typography>
          <Button onClick={() => handleRequestSort('updated_at')}>
            <TableSortLabel active={orderBy === 'updated_at'} direction={orderBy === 'updated_at' ? order : 'asc'}>
              Дата изменения
            </TableSortLabel>
          </Button>
          <Button onClick={() => handleRequestSort('mode')}>
            <TableSortLabel active={orderBy === 'mode'} direction={orderBy === 'mode' ? order : 'asc'}>
              Режим
            </TableSortLabel>
          </Button>
        </div>
      )}

      {currentStreams.length > 0 &&
        !loading &&
        currentStreams.map((item, index) => (
          <Paper className={css.streamItem} variant={index % 2 !== 0 ? 'outlined' : 'elevation'} key={item.stream_id}>
            <Typography className={css.streamName} sx={{ fontWeight: 'bold' }}>
              {item.name}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={customStreamModes[item.stream_id] === 'auto'}
                  onChange={() => handleStreamModeChange(item.stream_id, item.countries)}
                  color='primary'
                />
              }
              label={customStreamModes[item.stream_id] === 'auto' ? 'Авто' : 'Ручной'}
            />

            <Typography>
              {new Date(item.updated_at * 1000).toLocaleString('ru-RU', { dateStyle: 'short', timeStyle: 'medium' })}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ marginRight: '20px' }}>Режим:</Typography>
              <Button
                sx={{ width: '170px' }}
                aria-controls='mode-menu'
                aria-haspopup='true'
                variant='contained'
                onClick={(event) => handleMenuOpen(event, item.stream_id)}
              >
                {modes[item.mode]}
              </Button>
              <Popover
                id={'mode-menu-' + item.stream_id}
                open={Boolean(anchorEls[item.stream_id])}
                anchorEl={anchorEls[item.stream_id]}
                onClose={() => handleMenuClose(item.stream_id)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => changeMode(item.stream_id, 'Filter')}>Фильтр</MenuItem>
                <MenuItem onClick={() => changeMode(item.stream_id, 'Review')}>Модерация</MenuItem>
                <MenuItem onClick={() => changeMode(item.stream_id, 'Money')}>Контент</MenuItem>
                <MenuItem onClick={() => changeMode(item.stream_id, 'White')}>Белая страница</MenuItem>
              </Popover>

              <div className={css.streamItem__actions}>
                {/* <IconButton
                    data-id={item.stream_id}
                    onClick={() => setDeleteDialog(item.stream_id)}
                    aria-label='delete'
                  >
                    <DeleteIcon />
                  </IconButton> */}
              </div>
            </div>
          </Paper>
        ))}

      {noLoading && (
        <div>
          <Typography>Потоки не найдены</Typography>
        </div>
      )}

      <Pagination
        count={Math.ceil(filteredStreams.length / streamsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
        siblingCount={1}
        boundaryCount={1}
      />

      <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
        <DialogTitle>Вы уверены?</DialogTitle>
        <DialogActions>
          <Button onClick={() => deleteStream(deleteDialog)}>Удалить</Button>
          <Button onClick={() => setDeleteDialog(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
