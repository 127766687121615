import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import mainAPI from "../../../../utils/mainAPI";
import { toast } from "react-toastify";

const ContractDialog = ({ open, refresh, close }) => {
  const id = Date.now();
  const [type, setType] = useState("intermediary-contract");
  const [clientId, setClientId] = useState(null);
  const [contractorId, setContractorId] = useState(null);
  const [isRegReport, setIsRegReport] = useState(false);
  const [actionType, setActionType] = useState("distribution");
  const [subjectType, setSubjectType] = useState("distribution");
  const [number, setNumber] = useState(null);
  const [date, setDate] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isVat, setIsVat] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [loadedOrganizations, setLoadedOrganizations] = useState([]);

  useEffect(() => {
    mainAPI.get("/ord/organization").then((res) => {
      console.log(res.data);
      setLoadedOrganizations(res.data);
    });
  }, []);
  function postContract() {
    const data = {
      id,
      type,
      clientId,
      contractorId,
      isRegReport,
      actionType,
      subjectType,
      number,
      date,
      amount,
      isVat,
      contractId,
    };
    mainAPI
      .post("/ord/contract", data)
      .then((res) => {
        toast.success("Успешно");
        close();
        refresh();
      })
      .catch((e) => {
        toast.error(JSON.stringify(e.response.data));
        console.log(e.response.data);
      });
  }
  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="watchtoken-title"
      aria-describedby="watchtoken-description"
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Добавление договора
      </DialogTitle>
      <DialogContent sx={{ minWidth: "200px" }}>
        <InputLabel>Тип договора</InputLabel>
        <Select
          size="small"
          label="Тип договора"
          value={type}
          helperText="Тип договора"
          fullWidth
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value="intermediary-contract">
            Посреднический договор
          </MenuItem>
          <MenuItem value="contract">Договор оказания услуг</MenuItem>
          <MenuItem value="additional-agreement">
            Дополнительное соглашение
          </MenuItem>
          <MenuItem value="self-promotion">Самореклама</MenuItem>
        </Select>

        <InputLabel>Контрагент-заказчик: </InputLabel>
        <Select
          onChange={(e) => setClientId(e.target.value)}
          fullWidth
          value={clientId}
          size="small"
        >
          {loadedOrganizations.map((item) => {
            return (
              <MenuItem key={"zak" + item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>

        <InputLabel>Контрагент-исполнитель: </InputLabel>

        <Select
          size="small"
          onChange={(e) => setContractorId(e.target.value)}
          fullWidth
          value={contractorId}
        >
          {loadedOrganizations.map((item) => {
            return (
              <MenuItem key={"isp" + item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>

        <InputLabel>Тип действия</InputLabel>
        <Select
          size="small"
          value={actionType}
          fullWidth
          onChange={(e) => setActionType(e.target.value)}
        >
          <MenuItem value="distribution">
            Действия в целях распространения рекламы
          </MenuItem>
          <MenuItem value="conclude">Заключение договоров</MenuItem>
          <MenuItem value="commercial">Коммерческое представительство</MenuItem>
          <MenuItem value="other">Иное</MenuItem>
        </Select>

        <InputLabel>Предмет договора</InputLabel>
        <Select
          size="small"
          value={subjectType}
          fullWidth
          onChange={(e) => setSubjectType(e.target.value)}
        >
          <MenuItem value="org-distribution ">
            Договор на организацию распространения рекламы
          </MenuItem>
          <MenuItem value="mediation">Посредничество</MenuItem>
          <MenuItem value="distribution">
            Договор на распространение рекламы
          </MenuItem>
          <MenuItem value="other">Иное</MenuItem>
        </Select>

        <TextField
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          label="Номер договора"
          size="small"
          style={{ width: "250px", marginTop: "20px" }}
        />
        <TextField
          value={date}
          onChange={(e) => setDate(e.target.value)}
          label="Дата договора"
          placeholder="yyyy-mm-dd"
          required
          size="small"
          style={{ width: "250px", marginLeft: "52px", marginTop: "20px" }}
        />
        <TextField
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          label="Сумма договора"
          size="small"
          style={{ marginTop: "20px", width: "250px" }}
        />
        <TextField
          label="id родительского договора"
          value={contractId}
          size="small"
          onChange={(e) => setContractId(e.target.value)}
          style={{ width: "250px", marginLeft: "52px", marginTop: "20px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              required
              size="small"
              checked={isRegReport}
              onChange={(e) => {
                setIsRegReport(e.target.checked);
              }}
            />
          }
          label="На исполнителе лежит обязанность регистрировать и репортить креативы "
        />
        <FormControlLabel
          control={
            <Checkbox
              required
              size="small"
              checked={isVat}
              onChange={(e) => {
                setIsVat(e.target.checked);
              }}
            />
          }
          label="Признак 'с НДС'"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => postContract()}>Сохранить</Button>
        <Button
          onClick={() => {
            refresh();
            close();
          }}
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContractDialog;
