import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GoogleService from '../../../services/GoogleService';

const SelectTagsDialog = ({ open, closeCb, tags, userTags, accId }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setSelectedTags(userTags);
  }, []);

  const handleChange = (tag) => {
    setSelectedTags((prev) => (prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]));
  };

  async function updateUserTags() {
    try {
      await GoogleService.updateUserTags(accId, selectedTags);
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }

  const TagRow = ({ data }) => {
    return (
      <div className='g-acc-tag-row'>
        <Checkbox
          checked={selectedTags.includes(data._id)}
          value={data._id}
          onChange={() => handleChange(data._id)}
          size='small'
        />
        <Chip style={{ background: data.color }} size='small' label={data.label} />
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Выбор тегов</DialogTitle>
      <DialogContent>
        {tags.map((item) => (
          <TagRow key={'g' + item._id} data={item} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={updateUserTags}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
export default SelectTagsDialog;
