import { Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./style.scss";
import networks from "../../../assets/networks";
import CapEl from "./CapEl";
import useStore from "../../../hooks/useStore";
import { observer } from "mobx-react-lite";

function CapItem({
  data,
  nofavorites,
  del,
  change,
  home,
  disableEvents,
  networks,
  daysData,
}) {
  const [favItems, setFavItems] = useState([]);
  const { capsStore } = useStore();

  useEffect(() => {
    setFavItems(capsStore.allFavoriteItems);
  }, [capsStore.allFavoriteItems]);
  return (
    <Card className="capItem-paper" sx={{ margin: "10px", minWidth: 150 }}>
      <div className="capItem--wrapper">
        <CardContent>
          <Typography
            sx={{ fontSize: 14, marginLeft: "-15px" }}
            color="text.secondary"
            gutterBottom
          >
            {networks.find((item) => item.id === data[0].network)?.name || null}
          </Typography>
          {data.map((item) => {
            if (favItems.indexOf(item._id) !== -1) {
              return (
                <CapEl
                  home={home}
                  change={change}
                  del={del}
                  daysData={daysData}
                  item={item}
                  favor={!nofavorites}
                />
              );
            }
            return (
              <CapEl
                disableEvents={disableEvents}
                home={home}
                change={change}
                daysData={daysData}
                del={del}
                item={item}
              />
            );
          })}
        </CardContent>
      </div>
    </Card>
  );
}

export default observer(CapItem);
