import React from "react";
import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";

const DeleteModal = ({ openDelete, handleCloseDelete, deleteTask }) => {
  return (
    <Dialog
      open={openDelete}
      onClose={handleCloseDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{"Удалить задачу?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleCloseDelete}>Отмена</Button>
        <Button
          onClick={async () => {
            await deleteTask();
            handleCloseDelete();
          }}
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
