import React, { useState, useRef, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useDrag, useDrop } from "react-dnd";
import TaskModal from "../TaskModal";
import { CRMContext } from "..";
import TaskModal2 from "../TaskModal2";

import comment from "../../../assets/images/comment.svg";
import editingIcon from "../../../assets/images/editingIcon.svg";
import sortingIcon from "../../../assets/images/sortingIcon.svg";
import mainAPI from "../../../utils/mainAPI";

// const Task = ({ item, index, moveItem, status }) => {
const Task = ({ item, index, status }) => {
  const { crmStore } = useContext(CRMContext);
  const [createdByImage, setCreatedByImage] = useState(null);

  const [assignedToImage, setAssignedToImage] = useState(null);
  const project = crmStore.currentProject;

  const userCondition =
    project.admins.includes(crmStore.user) ||
    crmStore.user === item.assignedTo ||
    crmStore.user === item.createdBy ||
    item.assignedTo === "all";

  const ref = useRef(null);
  // useEffect(() => {
  //   mainAPI.get("/primary/tgphoto?login=" + item.createdBy).then((res) => {
  //     setCreatedByImage(res.data);
  //   });
  //   mainAPI.get("/primary/tgphoto?login=" + item.assignedTo).then((res) => {
  //     setAssignedToImage(res.data);
  //   });
  // }, []);
  const [, drop] = useDrop({
    accept: "ITEM",
    hover(item, monitor) {
      if (!userCondition) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "ITEM",
    item: { ...item, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(ref);

  const dateString = item.date.split(" ");
  const formattedDate = `${dateString[0]} - ${dateString[1]}`;

  return (
    <>
      <div
        ref={userCondition ? ref : null}
        style={{
          opacity: isDragging ? 0.4 : 1,
          cursor: userCondition ? "pointer" : "not-allowed",
        }}
        className="task"
        onClick={userCondition ? () => crmStore.setOpenModal(item.id) : null}
      >
        <div className="task-title">
          <p className="task-name">
            {userCondition ? (
              item.title
            ) : (
              <>
                {item.createdBy}
                <ArrowRightAltIcon />
                {item.assignedTo}
              </>
            )}
          </p>
          <div className="task-persons-icons">
            {createdByImage ? (
              <img alt="" src={createdByImage} className="persons-icons" />
            ) : (
              <div className="persons-icons">
                {item.createdBy.split("")[0].toUpperCase()}
              </div>
            )}
            {assignedToImage ? (
              <img alt="" src={assignedToImage} className="persons-icons" />
            ) : (
              <div className="persons-icons">
                {item.assignedTo.split("")[0].toUpperCase()}
              </div>
            )}
          </div>
        </div>
        <div className="task-bottom">
          <div className="task-icons-bottom">
            <div
              className="color-bar"
              style={{ backgroundColor: item.tag[0]?.color }}
            />
            <div
              className="color-bar"
              style={{ backgroundColor: item.tag[1]?.color }}
            />
            <div
              className="color-bar"
              style={{ backgroundColor: item.tag[2]?.color }}
            />
            <div
              className="color-bar"
              style={{ backgroundColor: item.tag[3]?.color }}
            />
            {item.comments?.length >= 1 ? (
              <img src={comment} alt="comment" />
            ) : null}
          </div>
          {/* текст временный, посмотреть как по верстке будет */}
          <p className="task-id">#{item.orderId}</p>
          {/* текст временный, посмотреть как по верстке будет */}
          <p className="task-date">{formattedDate}</p>
        </div>
      </div>
      {crmStore.openModal === item.id && <TaskModal2 key={item.id} item={item} />}
    </>
  );
};

export default observer(Task);
