import io from 'socket.io-client';

const connectToSocket = () => {
  const socket =
    process.env.NODE_ENV === 'development'
      ? io(`ws://localhost:1337`, {
          reconnection: true,
          reconnectionAttempts: 10,
          reconnectionDelay: 2000,
          timeout: 10000,
        })
      : io(`wss://2leads.dev`, {
          reconnection: true,
          reconnectionAttempts: 10,
          reconnectionDelay: 2000,
          timeout: 10000,
        });

  socket.on('connect', () => {
    console.log('Connected to server');
  });

  socket.on('connect_error', (error) => {
    console.log('Connect error: ', error);
  });

  socket.on('error', (error) => {
    console.log('Socket error: ', error);
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });

  return socket;
};

export default connectToSocket;
