import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import useStore from '../../hooks/useStore';
import { useGoogleAccounts, useGoogleLoadData } from '../../hooks';

import connectToSocket from '../CRM/socket';

import GoogleTags from './GoogleTags';
import GoogleAccounts from './GoogleAccounts';
import { GoogleActions } from './GoogleActions';
import { GoogleFilters } from './GoogleFilters';

import { Container, Tab, TablePagination, Tabs, Typography } from '@mui/material';

const ROLES_TO_ADMIN_MODE = ['admin', 'farmer'];

const GoogleMonitoring = () => {
  useEffect(() => {
    document.title = '2leads Dev – Google Monitoring';
  }, []);

  const { appStore } = useStore();
  const adminMode = ROLES_TO_ADMIN_MODE.some((role) => appStore.getUserRoles().includes(role));

  const socket = connectToSocket();

  useEffect(() => {
    socket.on('accountBanned', (accId) => {
      toast.warn(`Аккаунт ${accId} забанен`, {
        autoClose: false,
      });
    });
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const {
    accounts,
    tags,
    buyers,
    contractors,
    loadAccounts,
    rowsPerPage,
    currentPage,
    totalAccounts,
    setCurrentPage,
    handleChangePage,
    handleChangeRowsPerPage,
    searchFilter,
    handleChange,
    buyerFilter,
    setBuyerFilter,
    enabledAccountsFilter,
    setEnabledAccountsFilter,
    handleSort,
    handleApplyFilters,
    sortBy,
    sortDirection,
  } = useGoogleLoadData({ adminMode, type: activeTab === 1 ? 'Архив' : '', socket });
  const { selectedRows, setSelectedRows, onSelect } = useGoogleAccounts({ socket });

  const tabs = [
    {
      visible: true,
      label: 'Аккаунты',
      component: (
        <GoogleAccounts
          onSelect={onSelect}
          searchFilter={searchFilter}
          adminMode={adminMode}
          accounts={accounts}
          tags={tags}
          buyers={buyers}
          contractors={contractors}
          loadAccounts={loadAccounts}
          selectedRows={selectedRows}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      visible: true,
      label: 'Архив',
      component: (
        <GoogleAccounts
          onSelect={onSelect}
          searchFilter={searchFilter}
          adminMode={adminMode}
          accounts={accounts}
          tags={tags}
          buyers={buyers}
          contractors={contractors}
          loadAccounts={loadAccounts}
          selectedRows={selectedRows}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      ),
    },
    { visible: adminMode, label: 'Теги', component: <GoogleTags searchFilter={searchFilter} /> },
  ];

  return (
    <>
      <Typography variant='h4' sx={{ textAlign: 'left', ml: 6 }}>
        Google Monitoring
      </Typography>
      <Container maxWidth={false}>
        <Tabs
          value={activeTab}
          sx={{ mb: 2, width: 'fit-content' }}
          onChange={(e, n) => {
            setActiveTab(n);
            setSelectedRows([]);
            setCurrentPage(0);
          }}
          aria-label='Google Monitoring Tabs'
        >
          {tabs
            .filter((tab) => tab.visible)
            .map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
        </Tabs>

        <GoogleFilters
          searchFilter={searchFilter}
          type={tabs[activeTab]?.label}
          adminMode={adminMode}
          buyers={buyers}
          contractors={contractors}
          tags={tags}
          selectedBuyer={buyerFilter}
          enabledAccountsFilter={enabledAccountsFilter}
          accounts={accounts}
          socket={socket}
          handleChange={handleChange}
          onBuyerChange={(e) => {
            setBuyerFilter(e.buyerId);
            setCurrentPage(0);
          }}
          setEnabledAccountsFilter={(value) => {
            setEnabledAccountsFilter(value);
            setCurrentPage(0);
          }}
          handleApplyFilters={handleApplyFilters}
        />

        {tabs[activeTab]?.component}

        {adminMode && <GoogleActions type={tabs[activeTab]?.label} selectedRows={selectedRows} socket={socket} />}
      </Container>

      {tabs[activeTab]?.label !== 'Теги' && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={totalAccounts}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            backgroundColor: '#0000004a',
            my: 2,
          }}
        />
      )}
    </>
  );
};

export default GoogleMonitoring;
