import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { SketchPicker } from "react-color";

const TagModal = ({ openAddTag, handleCloseAddTag, addTagsToProject }) => {
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("#ffffff");

  const handleColorChange = (color) => {
    setTagColor(color.hex);
  };

  return (
    <Dialog open={openAddTag} onClose={handleCloseAddTag}>
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Tag Name"
          fullWidth
          variant="standard"
          onChange={(e) => setTagName(e.target.value)}
        />
        <SketchPicker color={tagColor} onChange={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddTag}>Cancel</Button>
        <Button
          onClick={() => {
            addTagsToProject(tagName, tagColor);
            handleCloseAddTag();
          }}
          disabled={tagName.length === 0}
        >
          Add Tag
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagModal;
