import { Button, Fab, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrganizationDialog from "./OrganizationDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import mainAPI from "../../../utils/mainAPI";
const Organization = () => {
  const [addDialog, setAddDialog] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  
  useEffect(() => {
    mainAPI.get("/ord/organization").then((res) => {
      setOrganizations(res.data);
    });
  }, []);
  function switchType(key) {
    switch (key) {
      case "ffl":
        return "Иностранное физическое лицо";

      case "ful":
        return "Иностранное юридическое лицо";

      case "ip":
        return "ИП РФ";

      case "fl":
        return " Физическое лицо (РФ)";

      case "ul":
        return "Юридическое лицо (РФ)";

      default:
        break;
    }
  }
  return (
    <div>
      {addDialog ? (
        <OrganizationDialog open={addDialog} close={setAddDialog} />
      ) : null}
      {organizations.length ? (
        <TableContainer>
          <Table sx={{ width: "max-content" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>ОРС</TableCell>
                <TableCell>RR</TableCell>
                <TableCell>ИНН</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>EPAY</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Рег.номер</TableCell>
                <TableCell>ОСКМ</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>ОКВЭД</TableCell>
                <TableCell>Платформы</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.map((row, rowIndex) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{switchType(row.type)}</TableCell>
                  <TableCell>{row.isOrs}</TableCell>
                  <TableCell>{row.isRr}</TableCell>
                  <TableCell>{row.inn}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.epayNumber}</TableCell>
                  <TableCell>{row.mobilePhone}</TableCell>
                  <TableCell>{row.regNumber}</TableCell>
                  <TableCell>{row.oskmNumber}</TableCell>
                  <TableCell>{row.rsUrl}</TableCell>
                  <TableCell>{row.okveds}</TableCell>
                  <TableCell>
                    {row.platforms.map((item, index) => {
                      return (
                        <div
                          style={{
                            height: "50px",
                            overflowY: "scroll",
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px 0",
                            fontSize: "10px",
                          }}
                        >
                          #{index}
                          <div style={{ fontSize: "10px" }}>
                            Тип:
                            {item.type === "is"
                              ? "Информационная система"
                              : item.type === "apps"
                              ? "Приложение"
                              : "Сайт"}
                          </div>
                          <div style={{ fontSize: "10px" }}>
                            {item.isOwned
                              ? "Является владельцем"
                              : "Не является владельцем"}
                          </div>
                          <div style={{ fontSize: "10px" }}>
                            Название: {item.name}
                          </div>
                          <div style={{ fontSize: "10px" }}>{item.url}</div>
                        </div>
                      );
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <Fab
        style={{ position: "absolute", right: "50px", bottom: "50px" }}
        color="primary"
        onClick={() => setAddDialog(true)}
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Organization;
