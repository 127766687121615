import React, { useEffect } from "react";
import mainAPI from "../../../utils/mainAPI";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  TextField,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
function CostsTab() {
  const [adAccounts, setAdAccounts] = useState([]);
  const [addAcountDialog, setAddAcountDialog] = useState(false);
  const [newAccountId, setNewAccountId] = useState("");
  const [newAccountName, setNewAccountName] = useState("");
  useEffect(() => {
    mainAPI.get("/cost/accounts").then((res) => {
      setAdAccounts(res.data);
    });
  }, []);
  function drawAccounts() {
    return adAccounts.map((item, index) => {
      return (
        <Paper
          className="pixelItem"
          style={{ display: "flex", justifyContent: "space-between" }}
          variant={index % 2 !== 0 ? "outlined" : "elevation"}
          key={index}
        >
          <div>{item.id}</div>
          {item.name}
          <IconButton
            data-id={item.id}
            onClick={(e) => {
              deleteAccount(item.id);
            }}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Paper>
      );
    });
  }
  function deleteAccount(id) {
    mainAPI
      .post("/cost/deleteaccount", { id: id })
      .then((res) => {
        toast.success("Успешно!");
        mainAPI.get("/cost/accounts").then((res) => {
          setAdAccounts(res.data);
        });
      })
      .catch((e) => {
        toast.error("Ошибка");
      });
  }
  function saveNewAccount() {
    if (newAccountId.length && newAccountName.length) {
      mainAPI
        .post("/cost/account", { name: newAccountName, id: newAccountId })
        .then((res) => {
          toast.success("Успешно");
          mainAPI.get("/cost/accounts").then((res) => {
            setAdAccounts(res.data);
          });
          setAddAcountDialog(false);
          setNewAccountId("");
          setNewAccountName("");
        })
        .catch((e) => {
          toast.error("Ошибка");
        });
    }
  }
  return (
    <>
      <div>
        <Button onClick={setAddAcountDialog}>Добавить</Button>
        {drawAccounts()}
      </div>
      <Dialog
        open={addAcountDialog}
        onClose={() => setAddAcountDialog(false)}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">
          {"Добавление аккаунта"}
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              style={{ marginTop: "10px" }}
              label="ID"
              value={newAccountId}
              type="number"
              onChange={(e) => setNewAccountId(e.target.value)}
              placeholder="3061169317438840"
            />
            <TextField
              style={{ marginTop: "20px" }}
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.target.value)}
              label="Название"
              placeholder="M-S-XXX-2leads_69 [HTLV ICNT] (3061169317438840)"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveNewAccount}>Добавить</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CostsTab;
