import {types} from "mobx-state-tree";
import appStore from "./appStore";
import capsStore from "./capsStore";
import fbStore from "./fbserviceStore";

const RootStore = types
    .model("RootStore", {
        appStore: types.optional(appStore, {
            isAuth: false,
            appTheme: "dark",

        }),
        fbStore: types.optional(fbStore, {}),
        capsStore: types.optional(capsStore, {})
    })

export default RootStore;
