import { useState } from 'react';

import { FilterDialog } from './FilterDialog';

import { Button } from '@mui/material';

/**
 *
 * @param { contractors: { _id: string, name: string }[] } param0
 * @param { tags: { _id: string, name: string }[] } param1
 * @param { handleApplyFilters: () => void } param2
 * @returns {JSX.Element}
 */
export const FilterButton = ({ contractorsList, tagsList, handleApplyFilters }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant='contained'
        sx={{
          textWrap: 'nowrap',
          fontSize: '12px',
          minHeight: '56px',
          minWidth: '120px',
          backgroundColor: '#333',
          color: '#fff',
          padding: '10px 20px',
          borderRadius: '6px 6px 6px 6px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          transition: 'background-color 0.3s, color 0.3s',
          '&:hover': {
            color: '#333',
          },
          '&:hover svg': {
            fill: '#333',
          },
          '& svg': {
            minWidth: '24px',
          },
        }}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
          <path
            d='M3 6.875C3 6.51033 3.14487 6.16059 3.40273 5.90273C3.66059 5.64487 4.01033 5.5 4.375 5.5H19.9583C20.323 5.5 20.6727 5.64487 20.9306 5.90273C21.1885 6.16059 21.3333 6.51033 21.3333 6.875C21.3333 7.23967 21.1885 7.58941 20.9306 7.84727C20.6727 8.10513 20.323 8.25 19.9583 8.25H4.375C4.01033 8.25 3.66059 8.10513 3.40273 7.84727C3.14487 7.58941 3 7.23967 3 6.875ZM6.66667 12.375C6.66667 12.0103 6.81153 11.6606 7.06939 11.4027C7.32726 11.1449 7.67699 11 8.04167 11H16.2917C16.6563 11 17.0061 11.1449 17.2639 11.4027C17.5218 11.6606 17.6667 12.0103 17.6667 12.375C17.6667 12.7397 17.5218 13.0894 17.2639 13.3473C17.0061 13.6051 16.6563 13.75 16.2917 13.75H8.04167C7.67699 13.75 7.32726 13.6051 7.06939 13.3473C6.81153 13.0894 6.66667 12.7397 6.66667 12.375ZM10.7917 16.5C10.427 16.5 10.0773 16.6449 9.81939 16.9027C9.56153 17.1606 9.41667 17.5103 9.41667 17.875C9.41667 18.2397 9.56153 18.5894 9.81939 18.8473C10.0773 19.1051 10.427 19.25 10.7917 19.25H13.5417C13.9063 19.25 14.2561 19.1051 14.5139 18.8473C14.7718 18.5894 14.9167 18.2397 14.9167 17.875C14.9167 17.5103 14.7718 17.1606 14.5139 16.9027C14.2561 16.6449 13.9063 16.5 13.5417 16.5H10.7917Z'
            fill='currentColor'
          />
        </svg>
        <span>Фильтры</span>
      </Button>
      <FilterDialog
        open={open}
        onClose={() => setOpen(false)}
        contractorsList={contractorsList}
        tagsList={tagsList}
        onApplyFilters={handleApplyFilters}
      />
    </>
  );
};
