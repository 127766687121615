import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import mainAPI from "../../../utils/mainAPI";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
const Platforms = () => {
  const [addDialog, setAddDialog] = useState(false);
  const [addPlatformType, setAddPlatformType] = useState("is");
  const [addPlatformIsOwned, setAddPlatformIsOwned] = useState(false);
  const [addPlatformName, setAddPlatformName] = useState(null);
  const [addPlatformUrl, setAddPlatformUrl] = useState(null);
  const [loadedPlatforms, setLoadedPlatforms] = useState([]);
  useEffect(() => {
    loadPlatforms();
  }, []);
  function loadPlatforms() {
    mainAPI.get("/ord/platforms").then((res) => {
      if (res.data.length) {
        function dynamicSort(property) {
          var sortOrder = 1;
          if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
          }
          return function (a, b) {
            var result =
              a[property] < b[property]
                ? -1
                : a[property] > b[property]
                ? 1
                : 0;
            return result * sortOrder;
          };
        }
        res.data.sort(dynamicSort("name"));
        setLoadedPlatforms(res.data);
      }
    });
  }
  function deletePlatform(id) {
    mainAPI.delete("/ord/platform?id=" + id).then((res) => {
      loadPlatforms();
    });
  }
  function postNewPlatform() {
    mainAPI
      .post("/ord/platform", {
        isOwned: addPlatformIsOwned,
        type: addPlatformType,
        name: addPlatformName,
        url: addPlatformUrl,
      })
      .then((res) => {
        toast.success("Успешно");
        setAddPlatformName(null);
        setAddPlatformUrl(null);
        loadPlatforms();
        setAddDialog(false);
      });
  }
  function switchType(key) {
    switch (key) {
      case "is":
        return "Информационная система";

      case "apps":
        return "Приложение";

      case "site":
        return "Cайт";

      default:
        break;
    }
  }
  return (
    <>
      <div>
        <Dialog open={addDialog}>
          <DialogTitle>Добавление платформы</DialogTitle>
          <DialogContent>
            <FormControlLabel
              label="Площадка принадлежит контрагенту "
              control={
                <Checkbox
                  onChange={(e) => {
                    setAddPlatformIsOwned(e.target.checked);
                  }}
                  required
                  size="small"
                  checked={addPlatformIsOwned}
                />
              }
            />
            <br />
            <Select
              style={{ width: "300px" }}
              size="small"
              value={addPlatformType}
              onChange={(e) => {
                setAddPlatformType(e.target.value);
              }}
            >
              <MenuItem value="is"> Информационная система</MenuItem>
              <MenuItem value="apps"> Приложение</MenuItem>
              <MenuItem value="site">Сайт</MenuItem>
            </Select>
            <br />
            <TextField
              size="small"
              style={{ marginTop: "15px" }}
              label="Название"
              value={addPlatformName}
              onChange={(e) => {
                setAddPlatformName(e.target.value);
              }}
            ></TextField>
            <TextField
              style={{ marginTop: "15px" }}
              size="small"
              label="URL"
              value={addPlatformUrl}
              onChange={(e) => {
                setAddPlatformUrl(e.target.value);
              }}
              helperText="Обязательно, если тип площадки не Информационная система"
            ></TextField>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => postNewPlatform()}>Сохранить</Button>
            <Button onClick={() => setAddDialog(false)}>Отмена</Button>
          </DialogActions>
        </Dialog>

        {loadedPlatforms.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell width="50px">Принадлежит контрагенту</TableCell>
                  <TableCell>Тип</TableCell>
                  <TableCell>URL</TableCell>

                  <TableCell>Действия</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadedPlatforms.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <Checkbox size="small" checked={row.isOwned} />
                    </TableCell>
                    <TableCell>{switchType(row.type)}</TableCell>
                    <TableCell>{row.url}</TableCell>
                    <TableCell align="right" width="50px">
                      <IconButton onClick={() => deletePlatform(row._id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </div>
      <Fab
        style={{ position: "absolute", right: "50px", bottom: "50px" }}
        color="primary"
        onClick={() => setAddDialog(true)}
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default Platforms;
