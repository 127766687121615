import { toast } from 'react-toastify';

/**
 * Экспорт данных в формате CSV
 * @param {Object} params
 * @param {Array<Object>} params.data - Данные для экспорта
 * @param {string} params.fileName - Имя файла (без расширения)
 * @param {Array<{ id: string, label: string }>} params.columns - Описание колонок
 */
export const exportCSV = ({ data, fileName, columns }) => {
  if (!data || !data.length) {
    toast.error('Нет данных для экспорта');
    return;
  }

  if (!columns || !columns.length) {
    toast.error('Нет информации о колонках для экспорта');
    return;
  }

  try {
    const csvContent = [
      columns.map((column) => column.label).join(';'),
      ...data.map((item) =>
        columns
          .map((column) => {
            const value = item[column.id];
            return value !== undefined && value !== null ? `"${String(value).replace(/"/g, '""')}"` : '';
          })
          .join(';')
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при экспорте CSV:', error);
    toast.error('Произошла ошибка при экспорте CSV');
  }
};
