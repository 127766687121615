import { useState } from 'react';

import GoogleService from '../../../services/GoogleService';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

export const SelectTopupDialog = ({ open, closeCb, accId, topup }) => {
  const [value, setValue] = useState(String(topup || ''));
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (!/^\d*\.?\d*$/.test(inputValue)) {
      setError(true);
      return;
    }

    setError(false);
    setValue(inputValue);
  };

  async function updateTopup() {
    try {
      await GoogleService.updateTopup(accId, parseFloat(value));
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Пополнения</DialogTitle>
      <DialogContent>
        <TextField
          value={value}
          onChange={handleChange}
          fullWidth
          label='Пополнения'
          error={error}
          helperText={error ? 'Введите корректное число' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCb()}>Отменить</Button>
        <Button onClick={updateTopup} disabled={error || !value.trim()}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
