import { TableCell, TableRow } from '@mui/material';

import { filterGoogleAccounts } from '../../../utils';

import AccountRow from './AccountRow';

/**
 *
 * @param { accounts: GoogleAccount[]}  param0
 * @param { tags: Object}  param1
 * @param { buyers: Object}  param2
 * @param { contractors: Object}  param3
 * @param { adminMode: boolean}  param4
 * @param { loadAccounts: Object}  param5
 * @param { onSelect: Object}  param6
 * @param { columns: Object}  param7
 * @param { searchFilter: string}  param8
 * @param { selectedRows: []}  param9
 * @returns {JSX.Element}
 *
 *
 * @typedef {Object} GoogleAccount
 * @property {boolean} archived - Статус архивирования аккаунта.
 * @property {number} spent - Траты аккаунта.
 * @property {string} buyerId - ID buyer.
 * @property {string} contractorId - ID подрядчика.
 * @property {string} date - Дата создания аккаунта.
 * @property {boolean} enabled - Статус активности аккаунта.
 * @property {string} id - Уникальный идентификатор аккаунта.
 * @property {string} name - Название аккаунта.
 * @property {string} note - Комментарии.
 * @property {string} social - Номер соца аккаунта.
 * @property {Array<string>} tags - Массив тегов, связанных с аккаунтом.
 * @property {number} verification - Статус верификации аккаунта.
 * @property {string} _id - Уникальный MongoDB идентификатор аккаунта.
 */
export const AccountRowsRender = ({
  accounts,
  tags,
  buyers,
  contractors,
  adminMode,
  loadAccounts,
  onSelect,
  columns,
  searchFilter,
  selectedRows,
}) => {
  return (
    <>
      {accounts.length ? (
        filterGoogleAccounts({ accounts, searchFilter }).map((item) => (
          <AccountRow
            onSelect={onSelect}
            adminMode={adminMode}
            key={item._id}
            refreshAccs={loadAccounts}
            buyers={buyers}
            contractors={contractors}
            tags={tags}
            data={item}
            selectedRows={selectedRows}
          />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={columns.length} align='center'>
            Нет данных
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
