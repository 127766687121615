import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import tlbotsAPI from '../../../utils/tlbotsAPI';
import { Container, Paper, Button } from '@mui/material';
import TgUserDialog from './TgUserDialog';

const UserItem = ({ data, setUser }) => {
  return (
    <Paper className='userItem' key={data.name}>
      <p>{data.name || `${data.firstName} ${data.lastName}`}</p>
      <div className='userItem-buttons-wrapper'>
        <Button variant='contained' onClick={() => setUser(data)}>
          Управление
        </Button>
      </div>
    </Paper>
  );
};

const TgChatsTab = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  useEffect(() => {
    loadUsers();
  }, []);
  async function loadUsers() {
    try {
      const users = await tlbotsAPI.get('/users');
      setAllUsers(users.data);
    } catch (error) {
      toast.error('ERR');
    }
  }

  return (
    <>
      <Container>
        {allUsers.map((i) => (
          <UserItem refresh={() => loadUsers()} key={i._id} data={i} setUser={(id) => setSelectedUser(id)} />
        ))}
      </Container>
      {selectedUser && (
        <TgUserDialog
          open={selectedUser}
          refresh={() => loadUsers()}
          close={() => setSelectedUser(null)}
          user={selectedUser?.id}
        />
      )}
    </>
  );
};

export default TgChatsTab;
