import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ArrowDownward, ArrowUpward, ModeComment, SwapVert } from '@mui/icons-material';

import { AccountRowsRender } from './AccountRowsRender';

import './style.scss';

/**
 *
 * @param {{onSelect: (accounts: Object) => void}} param0
 * @param {{ searchFilter: string }} param1
 * @param {{ adminMode: boolean }} param2
 * @param {{ accounts: [] }} param3
 * @param {{ tags: [] }} param4
 * @param {{ buyers: [] }} param5
 * @param {{ contractors: [] }} param6
 * @param {{ loadAccounts: () => void }} param7
 * @param {{ selectedRows: [] }} param8
 * @param {{ handleSort: () => void }} param9
 * @param {{ sortBy: string }} param10
 * @param {{ sortDirection: string }} param11
 * @returns
 */
const GoogleAccounts = ({
  onSelect,
  searchFilter,
  adminMode,
  accounts,
  tags,
  buyers,
  contractors,
  loadAccounts,
  selectedRows,
  handleSort,
  sortBy,
  sortDirection,
}) => {
  const columns = [
    { id: 'checkbox', label: '', align: 'left', sortable: false },
    { id: 'name', label: 'Название', align: 'left', sortable: true },
    { id: 'social', label: 'Номер соца', align: 'left', sortable: true },
    adminMode && { id: 'buyerId', label: 'Баер', align: 'left', sortable: true },
    { id: 'contractorId', label: 'Подрядчик', align: 'left', sortable: true },
    { id: 'email', label: 'Почта', align: 'left', sortable: true },
    { id: 'date', label: 'Дата', align: 'left', sortable: true },
    { id: 'topup', label: 'Пополнения', align: 'left', sortable: true },
    { id: 'spent', label: 'Баланс', align: 'left', sortable: true },
    { id: 'comments', label: '', align: 'center', icon: <ModeComment fontSize='small' />, sortable: false },
    { id: 'verification', label: 'Вериф', align: 'center', sortable: true },
    { id: 'tags', label: 'Теги', align: 'left', sortable: true },
  ].filter(Boolean);

  const getSortIcon = (columnId) => {
    if (sortBy === columnId) {
      return sortDirection === 'asc' ? <ArrowUpward fontSize='small' /> : <ArrowDownward fontSize='small' />;
    }
    return <SwapVert sx={{ fill: '#333' }} />;
  };

  const handleColumnSort = (columnId) => {
    handleSort(columnId);
  };

  return (
    <div>
      <TableContainer className='g-acc-table-container'>
        <Table sx={{ minWidth: 1400 }}>
          <TableHead className='g-acc-table-head'>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ textWrap: 'nowrap' }}>{column.label}</span>
                    {column.icon && <IconButton size='small'>{column.icon}</IconButton>}
                    {column.sortable && (
                      <IconButton size='small' onClick={() => handleColumnSort(column.id)} style={{ marginLeft: 8 }}>
                        {getSortIcon(column.id)}
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <AccountRowsRender
              accounts={accounts}
              tags={tags}
              buyers={buyers}
              contractors={contractors}
              adminMode={adminMode}
              loadAccounts={loadAccounts}
              onSelect={onSelect}
              columns={columns}
              searchFilter={searchFilter}
              selectedRows={selectedRows}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default GoogleAccounts;
