const { default: mainAPI } = require('../utils/mainAPI');

class GoogleService {
  // админка, блок баер
  async getAllBuyers() {
    return mainAPI.get('/google/buyers');
  }
  async getAllUsers() {
    return mainAPI.get('/google/users');
  }
  async createNewBuyer(payload) {
    return mainAPI.post('/google/buyer', payload);
  }
  async deleteBuyer(id) {
    return mainAPI.delete('/google/buyer?id=' + id);
  }
  async updateBuyer(payload) {
    return mainAPI.put('/google/buyer', payload);
  }

  // админка, блок подрядчик

  async getAllContractors() {
    return mainAPI.get('/google/contractors');
  }
  async createNewContractor(payload) {
    return mainAPI.post('/google/contractor', payload);
  }
  async deleteContractor(id) {
    return mainAPI.delete('/google/contractor?id=' + id);
  }
  async updateContractor(payload) {
    return mainAPI.put('/google/contractor', payload);
  }

  // админка, блок tag

  async getAllTags() {
    return mainAPI.get('/google/tags');
  }
  async createNewTag(payload) {
    return mainAPI.post('/google/tag', payload);
  }
  async deleteTag(id) {
    return mainAPI.delete('/google/tag?id=' + id);
  }
  async updateTag(payload) {
    return mainAPI.put('/google/tag', payload);
  }

  //блок акков
  async getAllAccountsToAdmin({ archived, page, perPage, search, buyer, enabled, sortBy, sortDirection, filters }) {
    return mainAPI.post(
      `/google/accounts/${archived}/${page}/${perPage}?search=${search}&buyer=${buyer}&enabled=${enabled}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
      { filters }
    );
  }

  async getBuyerAccounts({ userId, archived, page, perPage, search, enabled, sortBy, sortDirection, filters }) {
    return mainAPI.post(
      `/google/buyerAccounts/${userId}/${archived}/${page}/${perPage}?search=${search}&enabled=${enabled}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
      { filters }
    );
  }

  async updateUserTags(accId, data) {
    return mainAPI.put('/google/accountTags', { accId, payload: data });
  }
  async updateNote(accId, note) {
    return mainAPI.put('/google/accountNote', { accId, note });
  }
  async updateSocial(accId, social) {
    return mainAPI.put('/google/accountSocial', { accId, social });
  }
  async updateAccountBuyer(accId, buyerId) {
    return mainAPI.put('/google/accountBuyer', { accId, buyerId });
  }
  async updateAccountContractor(accId, contractorId) {
    return mainAPI.put('/google/accountContractor', { accId, contractorId });
  }
  async updateEmail(accId, email) {
    return mainAPI.put('/google/accountEmail', { accId, email });
  }
  async updateTopup(accId, topup) {
    return mainAPI.put('/google/updateTopup', { accId, topup });
  }
  async updateAccountVerification(accId, verification) {
    return mainAPI.put('/google/accountVerification', { accId, verification });
  }

  async addAccount(payload) {
    return mainAPI.post('/google/addAccount', payload);
  }
  async archivedRestoreAccount(accId, archived) {
    return mainAPI.put(`/google/archivedRestoreAccount/${accId}/${archived}`);
  }
  async deleteAccount(accId) {
    return mainAPI.delete(`/google/deleteAccount/${accId}`);
  }
}
export default new GoogleService();
