import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography} from "@mui/material";
import React,{useState, useEffect} from "react";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import mainAPI from "../../../utils/mainAPI";

export default function CheckCampaignModal({open, close}) {
        const [campId,setCampId] = useState(null)
        const [loadedData,setLoadedData] = useState(null)
        useEffect(()=>{
            setCampId(null)
            setLoadedData(null)
        },[open])

        function checkCampaign(){
            mainAPI.get(`/primary/checkcampaign?campid=${campId}`).then(res=>{
                setLoadedData(res.data)
                console.log(res.data)
            })
        }
        return <Dialog
            open={open}
            onClose={() => close()}
            aria-labelledby="watchtoken-title"
            aria-describedby="watchtoken-description"
        >
            <DialogTitle>{loadedData?loadedData.name:`Проверка кампании`}</DialogTitle>
            <DialogContent sx={{minWidth: "200px"}}>
                <Box sx={{p:2}}>
                    {loadedData?<>
                        {loadedData.landings.map(item=>{
                            return <div style={{display:"flex", justifyContent:"space-between"}}>
                                <Typography>Апи на лэнде {item.landName}</Typography>
                                <Typography>{item.offerLink&&item.fbPixel&&item.ourPixel?    <CheckIcon color="primary"/> :
                                    <CloseIcon color="secondary"/>}</Typography>
                            </div>
                        })}
                        <br/>
                        <div style={{display:"flex", justifyContent:"space-between"}}> <Typography>Traffic source : </Typography>{loadedData.traffic_sources == "2"? <CheckIcon color="primary"/> :
                            <CloseIcon color="secondary"/>}</div>
                        {loadedData.offers.map(item=>{
                            return <div style={{display:"flex", justifyContent:"space-between"}}>
                                <Typography>Апи на офере {item.id} {item.name}</Typography>
                                <Typography>{item.api?    <CheckIcon color="primary"/> :
                                    <CloseIcon color="secondary"/>}</Typography>
                            </div>
                        })}
                    </>:<TextField value={campId}
                                              onChange={(e)=>setCampId(e.target.value)}
                                              placeholder="123"
                                              label="Campaign ID"
                                              autoFocus
                    />}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{margin:'0 auto'}}
                    variant='outlined'
                    onClick={()=>checkCampaign()}
                >Проверить</Button>
            </DialogActions>
        </Dialog>
}