import { observer } from "mobx-react-lite";
import React from "react";

const Column = ({ isOver, children }) => {
  const bgColor = isOver ? "yellow" : "";

  return (
    <div style={{ backgroundColor: { bgColor }, padding: "0 10px 10px 10px" }}>
      {children}
    </div>
  );
};

export default observer(Column);
