import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  Button,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import tlbotsAPI from '../../../../utils/tlbotsAPI';

const TgUserDialog = ({ open, close, user, refresh }) => {
  const [userGroups, setUsersGroups] = useState([]);

  useEffect(() => {
    loadUserGroups();
  }, []);

  async function loadUserGroups() {
    try {
      const response = await tlbotsAPI.get('/usergroups?id=' + user);
      setUsersGroups(response.data);
    } catch (error) {
      toast.error('ERR');
    }
  }
  async function deleteUserFromGroup(userId, chatId) {
    try {
      await tlbotsAPI.delete(`/usergroup?userId=${userId}&chatId=${chatId}`);
      await loadUserGroups();
    } catch (error) {
      toast.error('DEL ERR');
    }
  }
  async function deleteUserFromDatabase(userId) {
    try {
      await tlbotsAPI.delete(`/user?userId=${userId}`);
      await refresh();
      await close();
    } catch (error) {
      toast.error('DEL ERR');
    }
  }
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Группы пользователя</DialogTitle>
      <DialogContent>
        {userGroups.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Роль</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userGroups.map((i) => (
                <TableRow key={i.name}>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i.role}</TableCell>
                  <TableCell>
                    <Button onClick={() => deleteUserFromGroup(user, i.chatId)}>Удалить</Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        ) : (
          'У пользователя нет групп'
        )}
      </DialogContent>
      <DialogActions>
        {!userGroups.length && <Button onClick={() => deleteUserFromDatabase(user)}>Удалить юзера</Button>}
        <Button onClick={() => close()}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};
export default TgUserDialog;
