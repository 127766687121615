import axios from 'axios';
import connectToSocket from '../components/CRM/socket';

const $api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:1337/' : 'https://2leads.dev/api/',
  withCredentials: true,
});

const socket = connectToSocket();
export default class CrmService {
  static async createTask(newTasksData, projectId, userOpen, taskId) {
    socket.emit('CreateTask', { newTasksData, projectId, userOpen, taskId });
  }

  static async changeTask(newTasksData, projectId) {
    socket.emit('ChangeTask', { newTasksData, projectId });
  }

  static async changeTaskData(changedTask, projectId) {
    socket.emit('ChangeTaskData', { changedTask, projectId });
  }

  static async deleteTask(taskId, projectId) {
    socket.emit('DeleteTask', { taskId, projectId });
  }

  static async deleteColumn(columnId, projectId) {
    socket.emit('DeleteColumn', { columnId, projectId });
  }

  static async deleteProject(projectId) {
    socket.emit('DeleteProject', { projectId });
  }

  static async createProject(newProject) {
    socket.emit('CreateProject', { newProject });
  }

  static async changeProjectName(newProjectName, projectId) {
    socket.emit('ChangeProjectName', { newProjectName, projectId });
  }

  static async changeProjectTags(newProjectTags, projectId) {
    socket.emit('ChangeProjectTags', { newProjectTags, projectId });
  }

  static async changeProjectUsers(newUserList, projectId) {
    socket.emit('ChangeProjectUsers', { newUserList, projectId });
  }

  static async createСolumn(newColumnsData, projectId) {
    socket.emit('CreateСolumn', { newColumnsData, projectId });
  }

  static async changeСolumn(newColumnsData, projectId) {
    socket.emit('ChangeСolumn', { newColumnsData, projectId });
  }

  static async deleteFile(fileId) {
    socket.emit('DeleteFile', { fileId });
  }

  static async getProjects(user) {
    return $api.post('/crm/projects', { user });
  }

  static async getSingleProject(projectId) {
    return $api.get(`/crm/project/${projectId}`);
  }
  static async createLeftNoteItem(projectId, data) {
    return $api.post(`/crm/leftnotes/${projectId}`, data);
  }
  static async createRightNoteItem(projectId, data) {
    return $api.post(`/crm/rightnotes/${projectId}`, data);
  }
  static async deleteLeftNote(projectId, id) {
    return $api.delete(`/crm/leftnotes?project=${projectId}&id=${id}`);
  }
  static async deleteRightNote(projectId, id) {
    return $api.delete(`/crm/rightnotes?project=${projectId}&id=${id}`);
  }

  static async uploadFiles(formData) {
    return $api.post('/crm/upload-files', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  // static async downloadFile(fileId) {
  //   return $api.get(`/crm/download-file/${fileId}`, { responseType: "blob" });
  // }
  // static async createTask(newTask, projectId) {
  //   return $api.post("/crm/create-task", { newTask, projectId });
  // }
  // static async changeTask(newTasksData, projectId) {
  //   return $api.post("/crm/change-task", { newTasksData, projectId });
  // }
}
