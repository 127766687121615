import axios from 'axios';

export default axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:1337/' : 'https://2leads.dev/api/',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Expose-Headers': 'Set-Cookie',
    'Access-Control-Allow-Methods': 'GET, POST',
  },
  withCredentials: true,
});
