import {
  Checkbox,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CreativeDialog from "./CreativeDialog";
import mainAPI from "../../../utils/mainAPI";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { toast } from "react-toastify";
const Creative = () => {
  const [creativeDialog, setCreativeDialog] = useState(false);
  const [loadedCreatives, setLoadedCreatives] = useState([]);
  useEffect(() => {
    refresh();
  }, []);
  function refresh() {
    function dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
          a[property] > b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
    mainAPI.get("/ord/creative").then((res) => {
      setLoadedCreatives(res.data.sort(dynamicSort("date")));
    });
  }
  function getDate(date) {
    const d = new Date(date);
    return d.toLocaleDateString() + " " + d.toLocaleTimeString();
  }
  return (
    <div>
      {creativeDialog ? (
        <CreativeDialog
          refresh={() => refresh()}
          open={creativeDialog}
          close={() => setCreativeDialog(false)}
        />
      ) : null}
      {loadedCreatives.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Токен</TableCell>
                <TableCell>URL</TableCell>

                <TableCell>Тип</TableCell>
                <TableCell>Договор</TableCell>
                <TableCell>ОКВЭД</TableCell>
                <TableCell>ФИАС</TableCell>
                <TableCell>Текстовые данные</TableCell>
                <TableCell>Описание изображения</TableCell>
                <TableCell>Общее описание</TableCell>
                <TableCell>Нативная</TableCell>
                <TableCell>Социальная</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadedCreatives.map((item) => {
                return (
                  <TableRow>
                    <TableCell>{getDate(item.date)}</TableCell>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>
                      <ContentCopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item.token);
                          toast.success("Скопировано");
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                        href={item.mediaData[0].mediaUrl}
                      >
                        <AttachFileIcon />
                      </a>
                    </TableCell>

                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.contractId}</TableCell>
                    <TableCell>{item.okveds.join(", ")}</TableCell>
                    <TableCell>{item.fiasRegionList.join(", ")}</TableCell>
                    <TableCell>{item.textData}</TableCell>
                    <TableCell>{item.mediaData[0].description}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      <Checkbox checked={item.isNative} />
                    </TableCell>
                    <TableCell>
                      <Checkbox checked={item.isSocial} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      <Fab
        style={{ position: "absolute", right: "50px", bottom: "50px" }}
        color="primary"
        onClick={() => setCreativeDialog(true)}
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Creative;
