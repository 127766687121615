import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GoogleService from '../../../../services/GoogleService';

const BuyerDialog = ({ users, editModeData, open, closeAction }) => {
  const [label, setLabel] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  useEffect(() => {
    setLabel(editModeData?.label?.trim() || '');
    setSelectedUser(editModeData?.user || '');
  }, [editModeData]);

  async function saveNewBuyer() {
    try {
      await GoogleService.createNewBuyer({ label: label.trim(), user: selectedUser });
      setLabel('');
      setSelectedUser('');
      closeAction();
    } catch (error) {
      console.log(error);
    }
  }

  async function updateBuyer() {
    try {
      await GoogleService.updateBuyer({ _id: editModeData._id, label: label.trim(), user: selectedUser });
      setLabel('');
      setSelectedUser('');
      closeAction();
    } catch (error) {
      console.log(error);
    }
  }

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  return (
    <Dialog open={open} onClose={() => closeAction()}>
      <DialogTitle>{editModeData.label ? 'Редактирование баера' : 'Добавление баера'}</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField label='ФИО' size='small' value={label} onChange={handleLabelChange} />
        <Select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          label='Пользователь'
          size='small'
          sx={{ mt: 2 }}
        >
          {users.length &&
            users.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.login}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!label.trim().length || !selectedUser}
          onClick={editModeData.label ? updateBuyer : saveNewBuyer}
        >
          {editModeData.label ? 'Обновить' : 'Сохранить'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyerDialog;
