import React, { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { observer } from 'mobx-react-lite';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SketchPicker } from 'react-color';
import Modal from 'react-modal';
import { CRMContext } from '..';
import { toJS } from 'mobx';

Modal.setAppElement('#root');

const TaskModal = ({ item }) => {
  const { crmStore } = useContext(CRMContext);

  // const item = crmStore.currentProject.tasks.find((item) => item.id === itemId);

  const [dragMedia, setDragMedia] = useState(false);

  const [saveText, setSaveText] = useState(false);

  const [files, setFiles] = useState(item.media);

  const [loading, setLoading] = useState(false);

  const [openAddTag, setOpenAddTag] = useState(false);

  const [tagName, setTagName] = useState('');

  const [tagColor, setTagColor] = useState('#ffffff');

  const [formData, setFormData] = useState({
    title: item.title,
    content: item.content,
    tag: item.tag,
    assignedTo: item.assignedTo,
  });

  useEffect(() => {
    setFormData({
      title: item.title,
      content: item.content,
      tag: item.tag,
      assignedTo: item.assignedTo,
    });
  }, [item]);

  useEffect(() => {
    setFiles(item.media);
  }, [item]);

  const handleOpenAddTag = () => {
    setOpenAddTag(true);
  };

  const handleCloseAddTag = () => {
    setOpenAddTag(false);
  };

  const handleColorChange = (color) => {
    setTagColor(color.hex);
  };

  const handleChangeAssign = (value) => {
    setFormData({ ...formData, assignedTo: value });
  };

  const handleChangeTags = (e, value) => {
    setFormData({ ...formData, tag: value });
  };

  const handleChangeTitle = (value) => {
    setFormData({ ...formData, title: value });
  };

  const handleChangeContent = (value) => {
    setFormData({ ...formData, content: value });
  };

  const addTagsToProject = () => {
    crmStore.changeProjectTags([
      ...crmStore.currentProject.tags,
      { id: `tag-${Math.random()}`, name: tagName, color: tagColor },
    ]);
  };

  const dragStartHandler = (e) => {
    e.preventDefault();
    setDragMedia(true);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDragMedia(false);
  };

  async function onDropHandler(e) {
    e.preventDefault();
    let newFiles = Object.values(e.dataTransfer.files);
    let modifiedFiles = newFiles.map((file) => {
      const newName = CyrillicToTranslit().transform(file.name, '_');
      const modifiedFile = new File([file], newName, {
        type: file.type,
      });
      return modifiedFile;
    });
    // setFiles((prevState) => [...prevState, ...newFiles]);
    setDragMedia(false);
    await crmStore.changeTaskData(formData, item.id);
    await crmStore.uploadFiles(modifiedFiles, item.id);
  }

  async function fileUpload(e) {
    e.preventDefault();
    let newFiles = Object.values(e.target.files);
    let modifiedFiles = newFiles.map((file) => {
      const newName = CyrillicToTranslit().transform(file.name, '_');
      const modifiedFile = new File([file], newName, {
        type: file.type,
      });
      return modifiedFile;
    });
    // setFiles((prevState) => [...prevState, ...newFiles]);
    await crmStore.changeTaskData(formData, item.id);
    await crmStore.uploadFiles(modifiedFiles, item.id);
  }

  const httpsLink = /(https?:\/\/[^\s]+)/g;
  const httpLink = /(http?:\/\/[^\s]+)/g;

  return (
    <Modal
      isOpen={crmStore.openModal === item.id ? true : false}
      onRequestClose={() => {
        crmStore.changeTaskData(item.id, formData);
        crmStore.setOpenModal(false);
      }}
      className='modal'
      overlayClassName='overlay'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextField
          sx={{
            flex: '0 0 60%',
            height: '50px',
            fontSize: '1.5rem',
          }}
          inputProps={{ style: { height: '50px', fontSize: '1.5rem' } }}
          value={formData.title}
          disabled={!(crmStore.currentProject.admins.includes(crmStore.user) || crmStore.user === item.createdBy)}
          variant='standard'
          size='medium'
          name='title'
          onChange={(e) => handleChangeTitle(e.target.value)}
        />
        <button className='close-btn' onClick={() => crmStore.setOpenModal(false)}>
          X
        </button>
      </div>

      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1.5rem' }}>
          <Autocomplete
            sx={{ minWidth: '300px' }}
            multiple
            disableCloseOnSelect
            disableClearable
            id='tags-standard'
            options={crmStore.currentProject.tags}
            getOptionLabel={(option) => option.name}
            defaultValue={formData.tag}
            onChange={(e, value) => handleChangeTags(e, value)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.name}
                  label={option.name}
                  avatar={
                    <Avatar
                      sx={{
                        backgroundColor: option.color,
                        width: 20,
                        height: 20,
                      }}
                      children=''
                    />
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} variant='outlined' label='Tags' placeholder='Tags' />}
          />
          {crmStore.currentProject.admins.includes(crmStore.user) ? (
            <Button size='medium' variant='contained' sx={{ marginLeft: '1rem' }} onClick={() => handleOpenAddTag()}>
              Add Tag
            </Button>
          ) : null}
        </div>
        <h2 style={{ display: 'flex', alignItems: 'center' }}>
          <>
            {item.createdBy}
            <ArrowRightAltIcon fontSize='large' />
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              variant='standard'
              sx={{ fontSize: '1.5rem', marginLeft: '0.35rem' }}
              disabled={
                !(
                  crmStore.currentProject.admins.includes(crmStore.user) ||
                  crmStore.user === item.createdBy ||
                  formData.assignedTo === 'all'
                )
              }
              value={formData.assignedTo}
              onChange={(e) => handleChangeAssign(e.target.value)}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={crmStore.user}>{crmStore.user}</MenuItem>
              {crmStore.userList.map((user) => {
                if (user.login === crmStore.user) {
                  return null;
                }
                if (crmStore.currentProject.admins.includes(crmStore.user) || crmStore.user === item.createdBy) {
                  return <MenuItem value={user.login}>{user.login}</MenuItem>;
                }
              })}
            </Select>
          </>
        </h2>
        <h2 className='task-modal-description'>
          Описание{' '}
          <IconButton variant='contained' onClick={() => setSaveText(!saveText)}>
            <BorderColorIcon />
          </IconButton>
        </h2>
        {/* {(crmStore.currentProject.admins.includes(crmStore.user) && saveText) ||
        (crmStore.user === item.createdBy && saveText) ? ( */}
        {saveText ? (
          <TextField
            sx={{
              width: '100%',
            }}
            value={formData.content}
            multiline
            name='content'
            onChange={(e) => handleChangeContent(e.target.value)}
          />
        ) : (
          <p
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              cursor: 'pointer',
            }}
            onClick={() => setSaveText(!saveText)}
          >
            {formData.content.split('\n').map((word, index) => {
              if (httpsLink.test(word)) {
                return (
                  <a key={index} href={word} target='_blank'>
                    {word}
                  </a>
                );
              }
              if (httpLink.test(word)) {
                return (
                  <a key={index} href={word} target='_blank'>
                    {word}
                  </a>
                );
              }
              return <p>{word}</p>;
            })}
          </p>
        )}

        <h2>Вложения</h2>
        {files.map((file, index) => (
          <ul style={{ listStyle: 'none' }}>
            <li style={{ display: 'flex', alignItems: 'center' }}>
              {file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' ? (
                <>
                  <img
                    className='image-preview'
                    src={
                      process.env.NODE_ENV === 'development'
                        ? `http://localhost:1337/crm/get-preview/${file.id}`
                        : `https://2leads.dev/api/crm/get-preview/${file.id}`
                    }
                    alt='Preview'
                  />
                  <div className='file-buttons'>
                    <a
                      href={
                        process.env.NODE_ENV === 'development'
                          ? `http://localhost:1337/crm/download-file/${file.id}`
                          : `https://2leads.dev/api/crm/download-file/${file.id}`
                      }
                      style={{ textDecoration: 'none' }}
                    >
                      <Button>
                        <ArrowCircleDownIcon />
                      </Button>
                    </a>
                    <IconButton aria-label='delete' onClick={() => crmStore.deleteFile(file.id, item.id)}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </>
              ) : (
                <>
                  <a
                    href={
                      process.env.NODE_ENV === 'development'
                        ? `http://localhost:1337/crm/download-file/${file.id}`
                        : `https://2leads.dev/api/crm/download-file/${file.id}`
                    }
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant='contained'>
                      {file.name} <ArrowCircleDownIcon />
                    </Button>
                  </a>
                  <IconButton aria-label='delete' onClick={() => crmStore.deleteFile(file.id, item.id)}>
                    <CancelIcon />
                  </IconButton>
                </>
              )}
            </li>
          </ul>
        ))}
        {
          // item.media.map(media => {
          //   return (
          //     media
          //   )
          // })
          dragMedia ? (
            <div
              className='dropMedia-area-active'
              onDragStart={(e) => dragStartHandler(e)}
              onDragLeave={(e) => dragLeaveHandler(e)}
              onDragOver={(e) => dragStartHandler(e)}
              onDrop={(e) => onDropHandler(e)}
            >
              <UploadFileIcon /> Отпустите файлы для загрузки
            </div>
          ) : (
            <div
              className='dropMedia-area'
              onDragStart={(e) => dragStartHandler(e)}
              onDragLeave={(e) => dragLeaveHandler(e)}
              onDragOver={(e) => dragStartHandler(e)}
            >
              <FileDownloadIcon /> Перетащите файлы что бы загрузить или
              <label htmlFor='contained-button-file'>
                <Input
                  accept='*'
                  sx={{ display: 'none' }}
                  id='contained-button-file'
                  inputProps={{ multiple: true }}
                  type='file'
                  onChange={(e) => fileUpload(e)}
                />
                <Button variant='contained' sx={{ marginLeft: '0.5rem' }} component='span'>
                  Загрузить
                </Button>
              </label>
            </div>
          )
        }
        <div className='task-bottom'>
          <LoadingButton
            variant='contained'
            loading={loading}
            loadingPosition='start'
            startIcon={<SaveIcon />}
            onClick={async () => {
              setLoading(true);
              await crmStore.changeTaskData(formData, item.id);
              setLoading(false);
              crmStore.setOpenModal(false);
            }}
          >
            Сохранить
          </LoadingButton>
          <div className='task-date'>
            <span>{item.date}</span>
          </div>
        </div>
        <Dialog open={openAddTag} onClose={handleCloseAddTag}>
          <DialogTitle>Add Tag</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Tag Name'
              fullWidth
              variant='standard'
              onChange={(e) => setTagName(e.target.value)}
            />
            <SketchPicker color={tagColor} onChange={handleColorChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddTag}>Cancel</Button>
            <Button
              onClick={() => {
                addTagsToProject();
                handleCloseAddTag();
              }}
              disabled={tagName.length === 0}
            >
              Add Tag
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Modal>
  );
};

export default observer(TaskModal);
