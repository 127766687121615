import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import GoogleService from '../../../services/GoogleService';

const EditSocialDialog = ({ open, closeCb, accId, social }) => {
  const [value, setValue] = useState(social);

  async function updateSocial() {
    try {
      await GoogleService.updateSocial(accId, value.trim());
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Соц</DialogTitle>
      <DialogContent>
        <TextField value={value} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={updateSocial}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSocialDialog;
