import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  TextField,
  IconButton,
  Grid,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import mainAPI from '../../../../utils/mainAPI';
import { toast } from 'react-toastify';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';

const UserCard = ({ open, data, close, refresh }) => {
  const [fio, setFio] = useState(null);
  const [department, setDepartment] = useState(null);
  const [tg, setTg] = useState(null);
  const [rolesChecked, setRolesChecked] = React.useState([]);
  const [rolesLeft, setRolesLeft] = React.useState([0, 1, 2, 3]);
  const [rolesRight, setRolesRight] = React.useState([4, 5, 6, 7, 8]);
  const rolesLeftChecked = intersection(rolesChecked, rolesLeft);
  const rolesRightChecked = intersection(rolesChecked, rolesRight);
  const [rolesUserName, setRolesUserName] = useState(null);
  const [userRolesDialog, setUserRolesDialog] = useState(false);
  const [img, setImg] = useState(null);
  useEffect(() => {
    console.log(data);
    setFio(data?.fio);
    setDepartment(data?.department);
    setTg(data?.tg);
    getTgImage();
  }, []);
  const allRoles = ['user', 'admin', 'admin_caps', 'faq_admin', 'buyer', 'farmer', 'sms_admin', 'sms_payoneer'];

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }
  function getTgImage() {
    mainAPI
      .get('/primary/tgphoto?id=' + data.tg)
      .then((res) => {
        setImg(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function postChanges() {
    mainAPI
      .post('/primary/user', {
        login: data.login,
        tg,
        fio,
        department,
      })
      .then(() => {
        toast.success('Успешно');
        close();
      });
  }
  function updateUserRoles(e) {
    const payload = {
      login: data.login,
      roles: rolesLeft,
    };
    mainAPI.post('/primary/user-roles', payload).then((res) => {
      if (res.status === 200) {
        toast.success('успешно');
      }
    });
    setUserRolesDialog(false);
  }
  function loadUserRoles(e) {
    mainAPI.get('/primary/user-roles?login=' + data?.login).then((res) => {
      setRolesLeft(res.data);
      setRolesRight(not(allRoles, res.data));
    });
  }
  const handleRolesToggle = (value) => () => {
    const currentIndex = rolesChecked.indexOf(value);
    const newChecked = [...rolesChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRolesChecked(newChecked);
  };
  const rolesNumberOfChecked = (items) => intersection(rolesChecked, items).length;
  const groupsNumberOfChecked = (items) => intersection(rolesChecked, items).length;

  const handleRolesToggleAll = (items) => () => {
    if (rolesNumberOfChecked(items) === items.length) {
      setRolesChecked(not(rolesChecked, items));
    } else {
      setRolesChecked(union(rolesChecked, items));
    }
  };
  const handleRolesCheckedRight = () => {
    setRolesRight(rolesRight.concat(rolesLeftChecked));
    setRolesLeft(not(rolesLeft, rolesLeftChecked));
    setRolesChecked(not(rolesChecked, rolesLeftChecked));
  };
  const handleRolesCheckedLeft = () => {
    setRolesLeft(rolesLeft.concat(rolesRightChecked));
    setRolesRight(not(rolesRight, rolesRightChecked));
    setRolesChecked(not(rolesChecked, rolesRightChecked));
  };
  function removeUser() {
    mainAPI.post('/primary/delete-user', { username: data?.login }).then((response) => {
      if (response.status === 200) {
        toast.success('Успешно');
        refresh();
        close();
      }
    });
  }
  const customRolesList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleRolesToggleAll(items)}
            checked={rolesNumberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={rolesNumberOfChecked(items) !== items.length && rolesNumberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${rolesNumberOfChecked(items)}/${items.length} выбрано`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((value) => {
          return value !== 'user' ? (
            <>
              <ListItem key={value} role='listitem' button onClick={handleRolesToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={rolesChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': `"transfer-list-all-item-${value}-label"`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={`"transfer-list-all-item-${value}-label"`} primary={value} />
              </ListItem>
            </>
          ) : null;
        })}
        <ListItem />
      </List>
    </Card>
  );
  return (
    <>
      <Dialog open={open}>
        <DialogActions>
          <IconButton onClick={() => close()} size='small' variant='contained'>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogTitle style={{ textAlign: 'center', marginTop: '-40px' }}>Карточка юзера</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                height: '140px',
                width: '140px',
                borderRadius: '50%',
                background: '#D9D9D9',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '20px',
              }}
            >
              {data?.tg ? (
                <img
                  style={{
                    height: '140px',
                    width: '140px',
                    borderRadius: '50%',
                  }}
                  src={img}
                />
              ) : (
                <p style={{ color: '#000', fontSize: '50px' }}>{data?.login.split('')[0]}</p>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                value={fio}
                style={{ marginTop: '10px' }}
                onChange={(e) => setFio(e.target.value)}
                size='small'
                label='ФИО'
              />

              <TextField
                style={{ marginTop: '10px' }}
                value={tg}
                onChange={(e) => setTg(e.target.value)}
                size='small'
                label='Telegram'
              />
              <Select
                style={{ marginTop: '10px' }}
                size='small'
                label='Должность'
                placeholder='Должность'
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <MenuItem value='designer'>Designer</MenuItem>
                <MenuItem value='buyer_sweep'>Buyer sweep</MenuItem>
                <MenuItem value='buyer_cpi'>Buyer CPI</MenuItem>
                <MenuItem value='affiliate'>Affiliate</MenuItem>
                <MenuItem value='buyer_web'>Buyer web</MenuItem>
                <MenuItem value='manager'>Manager</MenuItem>
              </Select>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={() => removeUser()}>
            Удалить юзера
          </Button>
          <Button
            size='small'
            onClick={() => {
              setUserRolesDialog(true);
              loadUserRoles();
            }}
          >
            Роли
          </Button>
          <Button variant='contained' onClick={() => postChanges()} size='small'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={userRolesDialog} onClose={() => setUserRolesDialog(false)} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Роли пользователя</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent='center' alignItems='center'>
            <Grid item>{customRolesList('Текущие', rolesLeft)}</Grid>
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleRolesCheckedRight}
                  disabled={rolesLeftChecked.length === 0}
                  aria-label='Роли пользователя'
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleRolesCheckedLeft}
                  disabled={rolesRightChecked.length === 0}
                  aria-label='Текущие роли'
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customRolesList('Возможные', rolesRight)}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateUserRoles} color='primary'>
            Применить
          </Button>
          <Button onClick={() => setUserRolesDialog(false)} color='primary'>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserCard;
