import { Button } from '@mui/material';
import { EnabledDisabledIcon } from '../GoogleIcons';

/**
 * Компонент кнопки фильтрации для активных/неактивных аккаунтов
 * @param {Object} props
 * @param {string} props.filterValue - Значение фильтра ('enabled' или 'disabled')
 * @param {boolean} props.isActive - Является ли текущая кнопка активной
 * @param {number} props.count - Количество аккаунтов для фильтра
 * @param {Function} props.onClick - Функция, которая будет вызвана при клике на кнопку
 * @param {string} props.bgColor - Цвет фона кнопки
 * @param {string} props.iconFillColor - Цвет заливки иконки
 */
export const EnabledFilterBtn = ({ filterValue, isActive, count, onClick, bgColor, iconFillColor }) => (
  <Button
    onClick={onClick}
    style={{
      minHeight: '56px',
      minWidth: '120px',
      backgroundColor: isActive ? bgColor : '#333',
      color: '#fff',
      fontSize: '12px',
      padding: '10px',
      borderRadius: filterValue === 'enabled' ? '6px 0 0 6px' : '0 6px 6px 0',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      transition: 'background-color 0.3s',
    }}
  >
    <EnabledDisabledIcon iconFillColor={iconFillColor} />
    {filterValue === 'enabled' ? 'Enabled' : 'Disabled'}: <span>{count}</span>
  </Button>
);
