import React, { createContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./styles.css";
import CRMstore from "../../store/CRMstore";
import ProjectDrawer from "./ProjectDrawer";
import SingleDesk from "./SingleDesk";

const crmStore = new CRMstore();

export const CRMContext = createContext({
  crmStore,
});

const CRM = ({ user }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <CRMContext.Provider value={{ crmStore }}>
        <ProjectDrawer user={user}>
          <SingleDesk />
        </ProjectDrawer>
      </CRMContext.Provider>
    </DndProvider>
  );
};

export default CRM;
