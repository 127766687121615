import React, { useEffect, useState } from "react";
import mainAPI from "../../../utils/mainAPI";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DragDropContext } from "react-beautiful-dnd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import useStore from "../../../hooks/useStore";
import CardsColumn from "../CardsColumn";
import DatePicker from "react-date-picker";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "./style.scss";
const BuyersCards = () => {
  const { appStore } = useStore();
  const [selectedUser, setSelectedUser] = useState("");
  const [cardsData, setCardsData] = useState([]);
  const [createDialogCardNumber, setCreateDialogCardNumber] = useState("");
  const [createDialogCardBank, setCreateDialogCardBank] = useState("");
  const [createDialogCardUser, setCreateDialogCardUser] = useState("");
  const [createDialogCardDate, setCreateDialogCardDate] = useState(Date.now());
  const [createDialogCardService, setCreateDialogCardService] = useState("");
  const [createDialogCardComment, setCreateDialogCardComment] = useState("");
  const [createDialogCardCurrency, setCreateDialogCardCurrency] =
    useState("USD");
  const [createDialog, setCreateDialog] = useState(false);

  useEffect(() => {
    setSelectedUser(appStore.getUserId());
  }, []);
  useEffect(() => {
    getUserCards();
  }, [selectedUser]);

  function getUserCards() {
    try {
      mainAPI.get("/cards/user?id=" + selectedUser).then((res) => {
        setCardsData(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  }
  function drawUserSelect() {
    return appStore.getCardsUsers()?.map((item) => {
      return (
        <MenuItem key={"user" + item.id} value={item.id}>
          {item.login}
        </MenuItem>
      );
    });
  }
  async function handleOnDragEnd(result) {
    if (result.source && result.destination) {
      if (appStore.getUserRoles().includes("farmer")) {
        if (result.source.droppableId !== result.destination.droppableId) {
          const data = result.destination.droppableId.split("_");
          changeAccountStatus(result.draggableId, data[2]);
        }
      } else {
        const end = result.destination.droppableId.split("_");
        if (+end[2] === 1 || +end[2] === 3 || +end[2] === 4) {
          if (result.source && result.destination) {
            if (result.source.droppableId !== result.destination.droppableId) {
              changeAccountStatus(result.draggableId, end[2]);
            }
          }
        }
      }
    }
  }
  function changeAccountStatus(id, status) {
    mainAPI
      .post("/cards/status", {
        id: id,
        status: status,
      })
      .then(() => {
        getUserCards();
      })
      .catch((e) => {
        toast.error("Error");
      });
  }
  function postCreateCard() {
    mainAPI
      .post("/cards/new", {
        bank: createDialogCardBank,
        currency: createDialogCardCurrency,
        date: createDialogCardDate,
        service: createDialogCardService,
        number: createDialogCardNumber,
        buyer_id: createDialogCardUser,
        comment: createDialogCardComment,
        status: 1,
      })
      .then((res) => {
        toast.success("Успешно");
        setCreateDialogCardBank("");
        setCreateDialogCardCurrency("USD");
        setCreateDialogCardDate("");
        setCreateDialogCardService("");
        setCreateDialogCardNumber("");
        setCreateDialogCardUser("");
        setCreateDialogCardComment("");
        setCreateDialog(false);
        getUserCards();
      })
      .catch((e) => {
        toast.error("Ошибка");
      });
  }
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {appStore.getUserRoles().indexOf("farmer") !== -1 ? (
            <FormControl size="small" style={{ width: "170px" }}>
              <InputLabel id="demo-simple-select-label">
                Пользователь
              </InputLabel>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {drawUserSelect()}
              </Select>
            </FormControl>
          ) : null}
          {/* <div style={{ display: "flex" }}>
            <div className="accounts_count_el">
              <span className="account_round account_round_green"></span>{" "}
              Активные:{" "}
              {accountsData.filter((item) => item.status === 1).length}
            </div>
            <div className="accounts_count_el">
              <span className="account_round account_round_yellow"></span> На
              проверке:
              {accountsData.filter((item) => item.status === 2).length}
            </div>
            <div className="accounts_count_el">
              <span className="account_round account_round_red"></span>{" "}
              Неактивные:
              {accountsData.filter((item) => item.status === 3).length}
            </div>
          </div> */}
          {appStore.getUserRoles().indexOf("farmer") !== -1 ? (
            <Button
              variant="outlined"
              size="small"
              style={{ marginLeft: "30px" }}
              onClick={() => {
                setCreateDialog(true);
              }}
            >
              + Добавить карту
            </Button>
          ) : null}
        </div>
        <div className="account_days">
          <CardsColumn status="1" data={cardsData} refresh={getUserCards} />
          <CardsColumn status="2" data={cardsData} refresh={getUserCards} />
          <CardsColumn status="3" data={cardsData} refresh={getUserCards} />
          <CardsColumn status="4" data={cardsData} refresh={getUserCards} />
          <CardsColumn status="5" data={cardsData} refresh={getUserCards} />
        </div>
      </DragDropContext>
      <Dialog
        open={createDialog}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">Добавление карты</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", paddingRight: "20px" }}>
            <div>
              {" "}
              <TextField
                label="Банк"
                placeholder="втб"
                value={createDialogCardBank}
                onChange={(e) => setCreateDialogCardBank(e.target.value)}
                size="small"
              />
              <br />
              <TextField
                label="Номер карты"
                style={{ marginTop: "15px" }}
                placeholder="1032"
                value={createDialogCardNumber}
                onChange={(e) => setCreateDialogCardNumber(e.target.value)}
                size="small"
              />
              <br />
              <TextField
                label="Сервис"
                size="small"
                style={{ width: "100%", marginTop: "10px" }}
                value={createDialogCardService}
                onChange={(e) => setCreateDialogCardService(e.target.value)}
              />
              <br />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DatePicker
                  value={createDialogCardDate}
                  onChange={setCreateDialogCardDate}
                />
                <FormControl
                  size="small"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Пользователь
                  </InputLabel>
                  <Select
                    value={createDialogCardUser}
                    onChange={(e) => setCreateDialogCardUser(e.target.value)}
                  >
                    {drawUserSelect()}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label2">Валюта</InputLabel>
                  <Select
                    placeholder="Валюта"
                    size="small"
                    style={{ width: "100%", marginTop: "10px" }}
                    value={createDialogCardCurrency}
                    onChange={(e) =>
                      setCreateDialogCardCurrency(e.target.value)
                    }
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="RUB">RUB</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <TextField
            label="Комментарий"
            size="small"
            multiline
            rows={7}
            style={{ width: "100%", marginTop: "20px" }}
            value={createDialogCardComment}
            onChange={(e) => setCreateDialogCardComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => postCreateCard()}>
            Создать
          </Button>
          <Button
            onClick={() => {
              setCreateDialogCardBank("");
              setCreateDialogCardCurrency("");
              setCreateDialogCardDate("");
              setCreateDialogCardService("");
              setCreateDialogCardNumber("");
              setCreateDialogCardUser("");
              setCreateDialogCardComment("");
              setCreateDialog(false);
            }}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuyersCards;
