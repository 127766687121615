import {types} from "mobx-state-tree"

const fbStore = types.model('appStore', {
    userName: types.maybe(types.string),
    token: types.maybe(types.string),
    userRoles: types.maybe(types.array(types.string)),
    navTitle: types.maybe(types.string)
}).actions(self => ({
    setUserName(name) {
        self.userName = name
    },
    setToken(token) {
        self.token = token
    },

    setNavTitle(title) {
        self.navTitle = title
    },
    setUserRoles(roles) {
        self.userRoles = roles
    }
})).views(self => ({
    getToken() {
        return self.token
    },
    getNavTitle() {
        return self.navTitle
    },
    getUserName() {
        return self.userName
    },
    getUserRoles() {
        return self.userRoles
    }
}))


export default fbStore