import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import GoogleService from '../../../services/GoogleService';

const SelectBuyerDialog = ({ open, closeCb, accId, buyers }) => {
  const [value, setValue] = useState('');

  async function updateBuyer() {
    try {
      await GoogleService.updateAccountBuyer(accId, value);
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Выбор баера</DialogTitle>
      <DialogContent>
        <Select value={value} onChange={(e) => setValue(e.target.value)} style={{ minWidth: 200 }}>
          {buyers.length &&
            buyers.map((i) => (
              <MenuItem key={'kk' + i._id} value={i._id}>
                {i.label}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateBuyer}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
export default SelectBuyerDialog;
