/**
 * Фильтрует массив объектов аккаунтов по заданному фильтру.
 *
 * @param {Object} param0 - Входные параметры.
 * @param {Array<Object>} param0.accounts - Массив аккаунтов.
 * @param {string} param0.searchFilter - Фильтр для поиска.
 * @returns {Array<Object>} Отфильтрованный массив аккаунтов.
 *
 * @typedef {Object} GoogleAccount
 * @property {boolean} archived - Статус архивирования аккаунта.
 * @property {number} spent - Траты аккаунта.
 * @property {string} buyerId - ID buyer.
 * @property {string} contractorId - ID подрядчика.
 * @property {string} date - Дата создания аккаунта.
 * @property {boolean} enabled - Статус активности аккаунта.
 * @property {string} id - Уникальный идентификатор аккаунта.
 * @property {string} name - Название аккаунта.
 * @property {string} note - Комментарии.
 * @property {string} social - Номер соца аккаунта.
 * @property {Array<string>} tags - Массив тегов, связанных с аккаунтом.
 * @property {number} verification - Статус верификации аккаунта.
 * @property {string} _id - Уникальный MongoDB идентификатор аккаунта.
 */
export const filterGoogleAccounts = ({ accounts, searchFilter }) => {
  const lowercasedFilter = searchFilter.toLowerCase();
  return accounts.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string' || typeof value === 'number') {
        return String(value).toLowerCase().includes(lowercasedFilter);
      }
      return false;
    })
  );
};
