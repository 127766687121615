import { useGoogleAccounts } from '../../../hooks';

import { exportCSV } from '../../../utils';

import { Box } from '@mui/material';

import { ActionIcons } from '../GoogleIcons';
import { ActionButton } from './ActionButton';

/**
 *
 * @param {{ type: 'Аккаунты' | 'Архив' | 'Теги' }} param0
 * @param {{ selectedRows: [] }} param1
 * @returns
 */
export const GoogleActions = ({ type, selectedRows, socket }) => {
  const { handleRestore, handleDeleteOrArchive } = useGoogleAccounts({ socket });

  const columns = [
    { id: 'id', label: 'ID' },
    { id: 'social', label: 'Social' },
    { id: 'name', label: 'Название' },
    { id: 'buyerId', label: 'Buyer' },
    { id: 'contractorId', label: 'Подрядчик' },
    { id: 'topup', label: 'Пополнения' },
    { id: 'spent', label: 'Траты' },
    { id: 'date', label: 'Дата' },
    { id: 'enabled', label: 'Enabled' },
    { id: 'archived', label: 'Archived' },
    { id: 'note', label: 'Комментарии' },
    { id: 'tags', label: 'Теги' },
    { id: 'verification', label: 'Верифицирован' },
  ];
  const actionButtons = [
    {
      visible: type !== 'Теги',
      icon: <ActionIcons type={'export'} color='#D88817' />,
      label: 'Экспорт CSV',
      onClick: () => exportCSV({ data: selectedRows, fileName: type, columns: columns }),
    },
    {
      visible: type === 'Архив',
      icon: <ActionIcons type={'restore'} color='#FFF500' />,
      label: 'Восстановить',
      onClick: async () => await handleRestore({ selectedRows }),
    },
    {
      visible: type !== 'Теги',
      icon: <ActionIcons type={'delete'} color='#C91D1D' />,
      label: type === 'Архив' ? 'Удалить навсегда' : 'Удалить в Архив',
      onClick: async () => await handleDeleteOrArchive({ selectedRows, type }),
    },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '24px', my: '24px' }}>
      {actionButtons
        .filter((button) => button.visible)
        .map((button) => (
          <ActionButton key={button.label} button={button} />
        ))}
    </Box>
  );
};
