import React, { useEffect, useState } from "react";
import mainAPI from "../../../utils/mainAPI";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AccountDay from "./AccountDay";

import { DragDropContext } from "react-beautiful-dnd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "./style.scss";
import useStore from "../../../hooks/useStore";
const BuyerStats = () => {
  const { appStore } = useStore();
  const [selectedUser, setSelectedUser] = useState("");
  const [accountsData, setAccountsData] = useState([]);
  const [createDialogAccountNumber, setCreateDialogAccountNumber] =
    useState("");
  const [createDialogSelectedDay, setCreateDialogSelectedDay] = useState(1);
  const [createDialogSelectedUser, setCreateDialogSelectedUser] = useState("");
  const [createDialogSelectedType, setCreateDialogSelectedType] = useState(1);
  const [createDialogOwnerName, setCreateDialogOwnerName] = useState("");
  const [createDialogOwnerContact, setCreateDialogOwnerContact] = useState("");
  const [createDialogIban, setCreateDialogIban] = useState("");
  const [createDialog, setCreateDialog] = useState(false);

  useEffect(() => {
    setSelectedUser(appStore.getUserId());
  }, []);
  useEffect(() => {
    getUserAccounts();
  }, [selectedUser]);

  function drawUserSelect() {
    return appStore.getFarmUsers()?.map((item) => {
      return (
        <MenuItem key={"user" + item.id} value={item.id}>
          {item.login}
        </MenuItem>
      );
    });
  }
  function postCreateAccount() {
    mainAPI
      .post("/farm/account", {
        name: createDialogAccountNumber,
        status: 1,
        buyer_id: createDialogSelectedUser,
        day: createDialogSelectedDay,
        type: createDialogSelectedType,
        owner_name: createDialogOwnerName,
        owner_contact: createDialogOwnerContact,
        iban: createDialogIban,
      })
      .then((res) => {
        toast.success("Успешно");
        setCreateDialogAccountNumber("");
        setCreateDialogIban("");
        setCreateDialogOwnerName("");
        setCreateDialogOwnerContact("");
        setCreateDialogSelectedDay(1);
        setCreateDialogSelectedType(1);
        setCreateDialog(false);
        getUserAccounts();
      })
      .catch((e) => {
        toast.error("Ошибка");
      });
  }
  function getUserAccounts() {
    mainAPI.get("/farm/accounts?id=" + selectedUser).then((res) => {
      setAccountsData(res.data);
    });
  }
  function changeAccountStatus(id, status, day) {
    mainAPI
      .post("/farm/changeaccountstatus", {
        id: id,
        status: status,
        day: day,
      })
      .then(() => {
        getUserAccounts();
      })
      .catch((e) => {
        toast.error("Error");
      });
  }
  async function handleOnDragEnd(result) {
    if (result.source.droppableId !== result.destination.droppableId) {
      const data = result.destination.droppableId.split("_");
      changeAccountStatus(result.draggableId, data[1], data[2]);
    }
  }
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {appStore.getUserRoles().indexOf("farmer") !== -1 ? (
            <FormControl size="small" style={{ width: "170px" }}>
              <InputLabel id="demo-simple-select-label">
                Пользователь
              </InputLabel>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {drawUserSelect()}
              </Select>
            </FormControl>
          ) : null}
          <div style={{ display: "flex" }}>
            <div className="accounts_count_el">
              <span className="account_round account_round_green"></span>{" "}
              Активные:{" "}
              {accountsData.filter((item) => item.status === 1).length}
            </div>
            <div className="accounts_count_el">
              <span className="account_round account_round_yellow"></span> На
              проверке:
              {accountsData.filter((item) => item.status === 2).length}
            </div>
            <div className="accounts_count_el">
              <span className="account_round account_round_red"></span>{" "}
              Неактивные:
              {accountsData.filter((item) => item.status === 3).length}
            </div>
          </div>
          {appStore.getUserRoles().indexOf("farmer") !== -1 ? (
            <Button
              variant="outlined"
              size="small"
              style={{ marginLeft: "30px" }}
              onClick={() => {
                setCreateDialog(true);
              }}
            >
              + Добавить аккаунт
            </Button>
          ) : null}
        </div>
        <div className="account_days">
          <AccountDay day="1" data={accountsData} refresh={getUserAccounts} />
          <AccountDay day="2" data={accountsData} refresh={getUserAccounts} />
          <AccountDay day="3" data={accountsData} refresh={getUserAccounts} />
          <AccountDay day="4" data={accountsData} refresh={getUserAccounts} />
          <AccountDay day="5" data={accountsData} refresh={getUserAccounts} />
        </div>
      </DragDropContext>
      <Dialog
        open={createDialog}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">
          Добавление аккаунта
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", paddingTop: "10px" }}>
            <TextField
              label="Номер"
              placeholder="1032"
              value={createDialogAccountNumber}
              onChange={(e) => setCreateDialogAccountNumber(e.target.value)}
              style={{ width: "80px" }}
              size="small"
            />
            <FormControl style={{ width: "150px", marginLeft: "10px" }}>
              <InputLabel id="demo-simple-select-label">День</InputLabel>
              <Select
                size="small"
                value={createDialogSelectedDay}
                onChange={(e) => setCreateDialogSelectedDay(e.target.value)}
              >
                <MenuItem value={1}>Понедельник</MenuItem>
                <MenuItem value={2}>Вторник</MenuItem>
                <MenuItem value={3}>Среда</MenuItem>
                <MenuItem value={4}>Четверг</MenuItem>
                <MenuItem value={5}>Пятница</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: "100px", marginLeft: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Тип аккаунта
              </InputLabel>
              <Select
                size="small"
                value={createDialogSelectedType}
                onChange={(e) => setCreateDialogSelectedType(e.target.value)}
              >
                <MenuItem value={1}>TW ES</MenuItem>
                <MenuItem value={2}>TW KE</MenuItem>
                <MenuItem value={3}>PP KE</MenuItem>
                <MenuItem value={4}>TW GE</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Имя владельца"
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
            value={createDialogOwnerName}
            onChange={(e) => setCreateDialogOwnerName(e.target.value)}
          />
          <br />
          <TextField
            label="Контакт владельца"
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
            value={createDialogOwnerContact}
            onChange={(e) => setCreateDialogOwnerContact(e.target.value)}
          />
          <br />
          <FormControl
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <InputLabel id="demo-simple-select-label">Пользователь</InputLabel>
            <Select
              value={createDialogSelectedUser}
              onChange={(e) => setCreateDialogSelectedUser(e.target.value)}
            >
              {drawUserSelect()}
            </Select>
          </FormControl>
          <br />
          <TextField
            label="IBAN / E-mail / Phone"
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
            value={createDialogIban}
            onChange={(e) => setCreateDialogIban(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => postCreateAccount()}>
            Создать
          </Button>
          <Button
            onClick={() => {
              setCreateDialogAccountNumber("");
              setCreateDialogIban("");
              setCreateDialogOwnerName("");
              setCreateDialogOwnerContact("");
              setCreateDialogSelectedDay(1);
              setCreateDialogSelectedType(1);
              setCreateDialog(false);
            }}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuyerStats;
