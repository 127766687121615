import React, {useRef, useState} from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

import FileDownload from "js-file-download";
import mainAPI from "../../../utils/mainAPI";
import {toast} from "react-toastify";


export default function UnicModal({open, close}){
    const [fileName,setFileName] = useState("")
    const fileInput=useRef()
    function fileChange(e){
        submitFile()
    }
    function submitFile(e) {
        setFileName(fileInput.current.files[0].name)
        const formData = new FormData();

        formData.append('file', fileInput.current.files[0])
        try {
            mainAPI.post("/unic", formData, {
                headers: {
                    "Contetnt-Type": "multipart/form-data",
                },
                responseType: 'blob',
                withCredentials:true
            }).then((res)=>{
                if (res.status===200){
                    toast.success('Успешно')
                    FileDownload(res.data, `unic_${Date.now()}.zip`)
                    close()
                } else {
                    toast.error('Ошибка')
                    close()
                }

            }).catch((e)=>{
                toast.error('Ошибка')
                close()
            })
        } catch (e){
        }
    }
    return <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
    >
        <DialogTitle>Уникализатор</DialogTitle>
        <DialogContent sx={{minWidth: "200px"}}>
            <input accept=".zip"  onChange={fileChange}  ref={fileInput} type="file"/>
        </DialogContent>
        <DialogActions>

        </DialogActions>
    </Dialog>
}