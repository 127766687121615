import { Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import useStore from "../../../hooks/useStore";
import { observer } from "mobx-react-lite";
import mainAPI from "../../../utils/mainAPI";
import LinearProgress from "@mui/material/LinearProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function CapEl({ item, favor, del, daysData, change, home, disableEvents }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const { capsStore, appStore } = useStore();
  const [totalValue, setTotalValue] = useState(0);
  const [days, setDays] = useState([]);
  useEffect(() => {
    if (daysData) {
      const data = daysData.filter((dd) => {
        return (
          dd.geo === item.geo &&
          dd.timezone === item.timezone &&
          dd.network === item.network &&
          dd.limit === item.limit &&
          dd.keyword === item.keyword &&
          dd.customDates === item.customDates
        );
      });
      setDays(data);
    }
    let total = (item.leads / item.limit) * 100;
    setTimeout(() => {
      setTotalValue(total <= 100 ? total : 100);
    }, 2000 * Math.random());
  }, []);
  useEffect(() => {
    let total = (item.leads / item.limit) * 100;
    setTimeout(() => {
      setTotalValue(total <= 100 ? total : 100);
    }, 2000 * Math.random());
  }, [item]);

  function deleteRule() {
    del(item._id);
    mainAPI.delete("bot/delete?id=" + item._id);
    setAnchorEl(null);
  }

  function changeRule() {
    setAnchorEl(null);
    change(item);
  }

  function addToFavor(e) {
    if (!disableEvents) {
      capsStore.addFavoriteItem(e.currentTarget.dataset.id);
    }
  }
  function drawDays() {
    return days.map((item) => {
      return <p>{item.leads / item.limit}</p>;
    });
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          style={{ padding: 0, margin: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            onContextMenu={(e) => {
              e.preventDefault();
              if (
                !home &&
                appStore.getUserRoles().indexOf("admin_caps") !== -1 &&
                !disableEvents
              ) {
                setAnchorEl(e.currentTarget);
              }
            }}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              minHeight: "25px",
              justifyContent: "space-between",
            }}
            variant="body2"
          >
            <p
              data-id={item._id}
              onClick={addToFavor}
              style={{
                padding: "0",
                cursor: "pointer",
                margin: 0,
                display: "block",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {" "}
                <div>
                  {item.text} {item.geo} {item.keyword} {item.timezone}{" "}
                  {item.dates === 1
                    ? "Сегодня"
                    : item.dates === 11
                    ? "Эта неделя"
                    : "от " + item.customDates}
                </div>
                <div>
                  <Typography
                    variant="p"
                    color={
                      item.status === 3
                        ? "secondary"
                        : item.status === 1
                        ? "primary.main"
                        : "text.primary"
                    }
                  >
                    {" "}
                    {item.leads + "/" + item.limit + " "} (
                    {Math.floor((item.leads / item.limit) * 100)}%)
                  </Typography>
                </div>
              </div>
            </p>
            {favor ? (
              <StarIcon
                style={{ cursor: "pointer" }}
                onClick={() => capsStore.removeFavoriteItem(item._id)}
              />
            ) : null}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{drawDays()}</Typography>
        </AccordionDetails>
      </Accordion>

      <LinearProgress
        sx={{ marginTop: "10px" }}
        color={totalValue < 100 ? "primary" : "secondary"}
        value={totalValue}
        variant="determinate"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={changeRule}>Изменить</MenuItem>
        <MenuItem onClick={deleteRule}>Удалить</MenuItem>
      </Menu>
    </>
  );
}

export default observer(CapEl);
