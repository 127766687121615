import React, { useEffect, useState } from 'react';
import TagRow from '../TagRow';
import { Button, IconButton } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BuyerDialog from './BuyerDialog';
import GoogleService from '../../../../services/GoogleService';
import customSort from '../assets/CustomSort';
const BuyersTable = ({ searchFilter }) => {
  const [openBuyerDialog, setOpenBuyerDialog] = useState(false);
  const [buyers, setBuyers] = useState([]);
  const [editModeData, setEditModeData] = useState({});
  const [users, setUsers] = useState([]);
  const [direction, setDirection] = useState(false);
  useEffect(() => {
    loadBuyers();
    loadUsers();
  }, []);
  useEffect(() => {
    loadBuyers();
  }, [openBuyerDialog]);

  async function loadBuyers() {
    try {
      const buyers = await GoogleService.getAllBuyers();
      setBuyers(buyers.data);
    } catch (error) {
      console.log(error);
    }
  }
  async function loadUsers() {
    try {
      const users = await GoogleService.getAllUsers();
      setUsers(users.data);
    } catch (error) {
      console.log(error);
    }
  }
  function handleAddClick() {
    setEditModeData({});
    setOpenBuyerDialog(true);
  }
  function openEditDialog(data) {
    setEditModeData(data);
    setOpenBuyerDialog(true);
  }
  async function deleteBuyer(id) {
    try {
      await GoogleService.deleteBuyer(id);
      loadBuyers();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className='g-table g-table--buyer'>
        <div className='g-table-head'>
          Пользователи
          <IconButton onClick={() => setDirection(!direction)}>
            <SwapVertIcon />
          </IconButton>
        </div>
        {buyers.length &&
          buyers
            .filter((i) => i.label.includes(searchFilter))
            .sort((a, b) => customSort(a, b, direction))
            .map((item) => {
              return (
                <TagRow
                  deleteCb={() => deleteBuyer(item._id)}
                  editCb={openEditDialog}
                  refreshCb={loadBuyers}
                  key={'p' + item.user + item.label}
                  data={item}
                />
              );
            })}
        <div className='g-table-add'>
          <Button onClick={handleAddClick}>Добавить +</Button>
        </div>
      </div>
      {openBuyerDialog && (
        <BuyerDialog
          users={users}
          editModeData={editModeData}
          open={openBuyerDialog}
          closeAction={() => setOpenBuyerDialog(false)}
        />
      )}
    </>
  );
};
export default BuyersTable;
