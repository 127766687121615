import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStore";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../App";
import { useState } from "react";
import mainAPI from "../../utils/mainAPI";
// import fbserviceAPI from "../../utils/fbserviceAPI";
import { toast } from "react-toastify";

function Login() {
  const { fbStore } = useStore();
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();

    data.append("username", login);
    data.append("password", password);

    mainAPI
      .post("/primary/authenticate", { login: login, password: password })
      .then((res) => {
        if (res.status === 200) {
          //

          window.location.reload();
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error("Неверный логин или пароль");
        } else {
          toast.error("Сервер не отвечает");
        }
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: 0,
        }}
      ></div>

      <ThemeProvider theme={darkTheme}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ zIndex: 100, color: "#fff" }}
            >
              Вход
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Логин"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Войти
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default observer(Login);
