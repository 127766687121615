import {
  Button,
  Checkbox,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ContractDialog from "./ContractDialog";
import AddIcon from "@mui/icons-material/Add";
import mainAPI from "../../../utils/mainAPI";
const Contract = () => {
  const [addContractDialog, setAddContractDialog] = useState(false);
  const [loadedContracts, setLoadedContracts] = useState([]);
  const [loadedOrganizations, setLoadedOrganizations] = useState([]);
  useEffect(() => {
    refresh();
  }, []);
  function refresh() {
    mainAPI.get("/ord/contracts").then((res) => {
      setLoadedContracts(res.data);
    });
    mainAPI.get("/ord/organization").then((res) => {
      setLoadedOrganizations(res.data);
    });
  }
  function findOrganization(id) {
    const data = loadedOrganizations.find((item) => item.id === id);
    if (data) {
      return data.name;
    }
  }

  function switchContractType(key) {
    switch (key) {
      case "intermediary-contract":
        return "Посреднический договор";
      case "contract":
        return "договор оказания услуг";
      case "additional-agreement":
        return "дополнительное соглашение";
      case "self-promotion":
        return "самореклама";
      default:
        break;
    }
  }

  function switchActionType(key) {
    switch (key) {
      case "distribution":
        return "Действия в целях распространения рекламы";
      case "conclude":
        return "Заключение договоров";
      case "commercial":
        return "Коммерческое представительство";
      case "other":
        return "Иное";
      default:
        break;
    }
  }
  function switchSubjectType(key) {
    switch (key) {
      case "org-distribution":
        return "Договор на организацию распространения рекламы";
      case "mediation":
        return "Посредничество";
      case "distribution":
        return "Договор на распространение рекламы";
      case "other":
        return "Иное";
      default:
        break;
    }
  }
  return (
    <div>
      <ContractDialog
        refresh={() => {
          refresh();
        }}
        close={() => setAddContractDialog(false)}
        open={addContractDialog}
      />
      {loadedContracts.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>Заказчик</TableCell>
                <TableCell>Контрагент</TableCell>
                <TableCell>Isregreport</TableCell>
                <TableCell>Тип действия</TableCell>
                <TableCell>Предмет договора</TableCell>
                <TableCell>Номер</TableCell>
                <TableCell width={"100px"} style={{ width: "100px" }}>
                  Дата
                </TableCell>
                <TableCell>Стоимость</TableCell>
                <TableCell>с НДС</TableCell>
                <TableCell>Родительский договор</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadedContracts.map((item) => {
                return (
                  <TableRow>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{switchContractType(item.type)}</TableCell>
                    <TableCell>{findOrganization(item.clientId)}</TableCell>
                    <TableCell>{findOrganization(item.clientId)}</TableCell>
                    <TableCell>
                      <Checkbox checked={item.isRegReport} />
                    </TableCell>
                    <TableCell>{switchActionType(item.actionType)}</TableCell>
                    <TableCell>{switchSubjectType(item.subjectType)}</TableCell>
                    <TableCell>{item.number}</TableCell>
                    <TableCell width="100px" style={{ width: "100px" }}>
                      {item.date}
                    </TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>
                      <Checkbox checked={item.isVat} />
                    </TableCell>
                    <TableCell>{item.contractId}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      <Fab
        style={{ position: "absolute", right: "50px", bottom: "50px" }}
        color="primary"
        onClick={() => setAddContractDialog(true)}
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Contract;
