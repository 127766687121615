import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs, Typography } from "@mui/material";
import BuyerStats from "./BuyerStats/Index";
import AccountsStats from "./AccountsStats";
import useStore from "../../hooks/useStore";
import mainAPI from "../../utils/mainAPI";

const Farm = () => {
  const { appStore } = useStore();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    mainAPI.get("/farm/users").then((res) => {
      appStore.setFarmUsers(res.data);
    });
  }, []);
  return (
    <>
      <Typography variant="h4" style={{ textAlign: "left", marginLeft: 50 }}>
        Аренда аккаунтов
      </Typography>
      <Container maxWidth={false}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {appStore.getUserRoles().indexOf("farmer") !== -1 ? (
            <Tabs
              value={activeTab}
              sx={{ marginBottom: "15px", width: "fit-content" }}
              onChange={(e, n) => setActiveTab(n)}
              aria-label="basic tabs example"
            >
              <Tab label="Аккаунты баера" />
              <Tab label="Сводка аккаунтов" />
              {/* <Tab label="ETC" /> */}
            </Tabs>
          ) : null}
        </div>
        {activeTab === 0 ? <BuyerStats /> : null}
        {activeTab === 1 ? <AccountsStats /> : null}
      </Container>
    </>
  );
};

export default Farm;
