import { Typography } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import UsersTab from './UsersTab/UsersTab';
import TgChatsTab from './TgChatsTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ overflowY: 'scroll', maxHeight: '80vh' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Users = () => {
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <>
      <Typography variant='h4' style={{ textAlign: 'left', marginLeft: 50 }}>
        Управление пользователями
      </Typography>

      <Box sx={{ width: '100%', mt: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChange} aria-label='basic tabs example'>
            <Tab label='Пользователи 2leads.dev' />
            <Tab label='TGЧаты' />
          </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
          <UsersTab />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <TgChatsTab />
        </CustomTabPanel>
      </Box>
    </>
  );
};
export default Users;
