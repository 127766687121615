import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import RefreshIcon from "@mui/icons-material/Refresh";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import AddUserModal from "../AddUserModal";
import { CRMContext } from "..";
import connectToSocket from "../socket";
import CrmService from "../../../utils/crmAPI";
import mainAPI from "../../../utils/mainAPI";
import { toast } from "react-toastify";

const drawerWidth = 240;
const httpsLink = /(https?:\/\/[^\s]+)/g;
const httpLink = /(http?:\/\/[^\s]+)/g;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const NoteDialog = ({ data, open, close, side }) => {
  const { crmStore } = React.useContext(CRMContext);
  async function deleteNote() {
    try {
      if (side === "left") {
        await CrmService.deleteLeftNote(crmStore.currentProject.id, data.id);
      } else {
        await CrmService.deleteRightNote(crmStore.currentProject.id, data.id);
      }
      await crmStore.refreshProject();
      toast.success("Успешно");
      close();
    } catch (e) {
      toast.error("Ошибка");
    }
  }
  return (
    <Dialog open={open}>
      <DialogTitle>{data?.title}</DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        {data?.content.split("\n").map((word, index) => {
          if (httpsLink.test(word)) {
            return (
              <a
                key={index}
                href={word}
                rel="noreferrer"
                style={{ color: "lightblue", fontSize: "12px" }}
                target="_blank"
              >
                {word}
              </a>
            );
          }
          if (httpLink.test(word)) {
            return (
              <a
                key={index}
                rel="noreferrer"
                style={{ color: "lightblue", fontSize: "12px" }}
                href={word}
                target="_blank"
              >
                {word}
              </a>
            );
          }
          return <p>{word}</p>;
        })}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => deleteNote()}>Удалить</Button>
        <Button onClick={() => close()}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};

function ProjectDrawer({ children, user }) {
  const { crmStore } = React.useContext(CRMContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [openleftNoteDialog, setOpenLeftNoteDialog] = React.useState(false);
  const [openRightNoteDialog, setOpenRightNoteDialog] = React.useState(false);
  const [leftNoteCreateType, setLeftNoteCreateType] = React.useState("default");
  const lastProjectId = localStorage.getItem("lastProjectId");
  const [leftNoteCreateContent, setleftNoteCreateContent] = React.useState("");
  const [leftNoteCreateTitle, setLeftNoteCreateTitle] = React.useState("");
  const [rightNoteCreateContent, setRightNoteCreateContent] =
    React.useState("");
  const [rightNoteCreateTitle, setRightNoteCreateTitle] = React.useState("");
  const [openNote, setOpenNote] = React.useState(false);
  const [noteData, setNoteData] = React.useState(null);
  const [noteSide, setNoteSide] = React.useState("left");

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [toggleInput, setToggleInput] = React.useState(false);
  const [projectName, setProjectName] = React.useState(
    toggleInput ? crmStore.currentProject.name : null
  );

  React.useEffect(() => {
    async function fetchSingleProject(projectId) {
      crmStore.setUser(user);
      await crmStore.getSingleProject(projectId);
      await crmStore.getProjects(user);
      crmStore.handleLoader(true);
    }
    if (location.pathname === "/crm" || location.pathname === "/crm/") {
      lastProjectId === null
        ? fetchSingleProject("project-0.7712521718719683")
        : navigate(`/crm/${lastProjectId}`);
    } else {
      fetchSingleProject(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      );
    }

    const socket = connectToSocket();

    socket.on("UpdateProjects", async (data) => {
      const fetchedProject = data;
      navigate(`/crm/${fetchedProject.id}`);

      if (crmStore.currentProject.id === fetchedProject.id) {
        crmStore.updateProject(fetchedProject);
      }
    });

    socket.on("CreateTaskClient", (data) => {
      const { newTasksData, projectId, userOpen, taskId, taskCounter } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.createTaskClient(newTasksData, userOpen, taskId, taskCounter);
      }
    });

    socket.on("ChangeTaskClient", (data) => {
      const { newTasksData, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.changeTaskClient(newTasksData);
      }
    });

    socket.on("ChangeСolumnClient", (data) => {
      const { newColumnsData, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.changeColumnClient(newColumnsData);
      }
    });

    socket.on("ChangeProjectNameClient", (data) => {
      const { newProjectName, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.changeProjectNameClient(newProjectName);
      }
    });

    socket.on("ChangeProjectTagsClient", (data) => {
      const { newProjectTags, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.changeProjectTagsClient(newProjectTags);
      }
    });

    socket.on("DeleteColumnClient", (data) => {
      const { columnId, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.deleteColumnClient(columnId);
      }
    });

    socket.on("DeleteTaskClient", (data) => {
      const { taskId, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.deleteTaskClient(taskId);
      }
    });

    socket.on("CreateСolumnClient", (data) => {
      const { newColumn, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.createСolumnClient(newColumn);
      }
    });

    socket.on("CreateProjectClient", async () => {
      await crmStore.getProjects(user);
    });

    socket.on("ChangeProjectUsersClient", (data) => {
      const { newUserList, projectId } = data;
      if (crmStore.currentProject.id === projectId) {
        crmStore.changeProjectUsersClient(newUserList);
      }
    });
  }, []);

  React.useEffect(() => {
    crmStore.handleLoader(false);
    async function fetchSingleProject(projectId) {
      crmStore.setUser(user);
      await crmStore.getSingleProject(projectId);
      await crmStore.getProjects(user);
      crmStore.handleLoader(true);
    }
    if (location.pathname === "/crm" || location.pathname === "/crm/") {
      lastProjectId === null
        ? fetchSingleProject("project-0.7712521718719683")
        : navigate(`/crm/${lastProjectId}`);
    } else {
      fetchSingleProject(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      );
    }
  }, [location.pathname]);

  React.useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Escape") {
        if (toggleInput) {
          handleChangeInput(crmStore.currentProject.name);
          setToggleInput(false);
        }
      } else if (event.key === "Enter") {
        if (toggleInput) {
          await handleChangeProjectName();
          setToggleInput(false);
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [toggleInput]);

  const project = crmStore.currentProject;

  async function handleChangeProjectName() {
    await crmStore.changeProjectName(projectName);
  }

  const handleChangeInput = (newProjectName) => {
    setProjectName(newProjectName);
  };

  const handleCreateProject = () => {
    crmStore.createNewProject();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onOpen = () => setShow(true);

  const onClose = () => setShow(false);
  async function saveLeftNoteItem() {
    try {
      await CrmService.createLeftNoteItem(crmStore.currentProject.id, {
        type: leftNoteCreateType,
        content: leftNoteCreateContent,
        title: leftNoteCreateTitle,
      });
      await crmStore.refreshProject();
      toast.success("Успешно");
      setOpenLeftNoteDialog(false);
    } catch (e) {
      toast.error("Ошибка");
    }
  }
  async function saveRightNoteItem() {
    try {
      await CrmService.createRightNoteItem(crmStore.currentProject.id, {
        content: rightNoteCreateContent,
        title: rightNoteCreateTitle,
      });
      await crmStore.refreshProject();
      toast.success("Успешно");
      setOpenRightNoteDialog(false);
    } catch (e) {
      console.log(e);
      toast.error("Ошибка");
    }
  }
  return (
    <>
      {crmStore.loader ? (
        crmStore.currentProject.users.includes(crmStore.user) ||
        crmStore.currentProject.admins.includes(crmStore.user) ? (
          <>
            <Box
              sx={{ display: "flex" }}
              // position="relative"
              // style={{ width: "100%", marginTop: "-30px" }}
              // open={true}
            >
              <AppBar position="fixed" open={open}>
                <Toolbar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box sx={{ marginLeft: "50px", display: "flex" }}>
                    {crmStore.currentProject.headerLeft.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={
                            item.type === "default"
                              ? "note-button"
                              : "note-button note-button-red"
                          }
                          onClick={() => {
                            setOpenNote(true);

                            setNoteSide("left");
                            setNoteData(item);
                          }}
                        >
                          {item.type === "sick" ? (
                            <div>
                              <p style={{ textAlign: "left" }}>
                                Больничный <br />
                                <span style={{ fontSize: "10px" }}>
                                  {item.content}
                                </span>
                              </p>
                            </div>
                          ) : null}
                          {item.type === "vacation" ? (
                            <div>
                              <p style={{ textAlign: "left" }}>
                                Отпуск <br />
                                <span style={{ fontSize: "10px" }}>
                                  {item.content}
                                </span>
                              </p>
                            </div>
                          ) : null}
                          {item.type === "default" ? item.title : null}
                        </div>
                      );
                    })}
                    <IconButton
                      onClick={() => setOpenLeftNoteDialog(true)}
                      size="small"
                    >
                      <AddIcon fontSize="10px" />
                    </IconButton>
                  </Box>

                  {crmStore.currentProject.admins.includes(crmStore.user) ? (
                    <>
                      {toggleInput ? (
                        <TextField
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1rem",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                          variant="standard"
                          inputProps={{
                            style: {
                              width: "350px",
                              fontSize: "1.2rem",
                              textAlign: "center",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={async () => {
                                  await handleChangeProjectName();
                                  setToggleInput(!toggleInput);
                                }}
                              >
                                <CheckIcon />
                              </IconButton>
                            ),
                          }}
                          value={projectName}
                          size="medium"
                          onChange={(e) => handleChangeInput(e.target.value)}
                        />
                      ) : (
                        <h2
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.25rem",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        >
                          <IconButton
                            onClick={async () =>
                              await crmStore.getSingleProject(
                                crmStore.currentProject.id
                              )
                            }
                          >
                            <RefreshIcon />
                          </IconButton>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setProjectName(project.name);
                              setToggleInput(!toggleInput);
                            }}
                          >
                            {project.name}
                          </p>
                        </h2>
                      )}
                    </>
                  ) : (
                    <h2
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.25rem",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      <IconButton
                        onClick={async () =>
                          await crmStore.getSingleProject(
                            crmStore.currentProject.id
                          )
                        }
                      >
                        <RefreshIcon />
                      </IconButton>
                      {project.name}
                    </h2>
                  )}
                  <IconButton
                    onClick={() => setOpenRightNoteDialog(true)}
                    size="small"
                  >
                    <AddIcon fontSize="10px" />
                  </IconButton>
                  {crmStore.currentProject.headerRight.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="note-button"
                        onClick={() => {
                          setOpenNote(true);
                          setNoteSide("right");
                          setNoteData(item);
                        }}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                  {crmStore.currentProject.admins.includes(crmStore.user) ? (
                    <Button onClick={onOpen}>
                      <SupervisedUserCircleIcon />
                    </Button>
                  ) : null}
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                    sx={{ ...(open && { display: "none" }) }}
                  >
                    <AccountTreeIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            </Box>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                },
              }}
              variant="persistent"
              anchor="right"
              open={open}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {crmStore.projects.map((project, index) => (
                  <Link
                    key={project.id}
                    to={`/crm/${project.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <ListItem button key={project.name}>
                      {project.name}
                      {/* <ListItemText
                      button
                      onClick={() => handleProjectId(project.id)}
                      primary={project.name}
                    /> */}
                      {project.admins.includes(crmStore.user) ? (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            navigate(`/crm/project-0.7712521718719683`);
                            crmStore.deleteProject(project.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </ListItem>
                  </Link>
                ))}
              </List>
              <Divider />
              <Button
                onClick={() => handleCreateProject()}
                sx={{
                  marginTop: "0.5rem",
                  borderRadius: "30px",
                }}
              >
                + новый проект
              </Button>
            </Drawer>

            <AddUserModal onClose={onClose} show={show} />

            {children}
          </>
        ) : (
          <h2>Доска недоступна, тебя нет в списке юзеров ¯\_(ツ)_/¯</h2>
        )
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="success" />
        </Box>
      )}
      <Dialog open={openleftNoteDialog}>
        <DialogTitle>Добавление заметки</DialogTitle>
        <DialogContent style={{ minWidth: "500px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Select
              size="small"
              label="Тип"
              value={leftNoteCreateType}
              onChange={(e) => setLeftNoteCreateType(e.target.value)}
            >
              <MenuItem value="default">Обычный</MenuItem>
              <MenuItem value="sick">Больничный</MenuItem>
              <MenuItem value="vacation">Отпуск</MenuItem>
            </Select>
            {leftNoteCreateType === "default" ? (
              <TextField
                style={{ marginTop: "10px" }}
                size="small"
                placeholder="Заголовок"
                value={leftNoteCreateTitle}
                onChange={(e) => setLeftNoteCreateTitle(e.target.value)}
              />
            ) : null}
            <TextField
              multiline
              style={{ marginTop: "10px" }}
              minRows={5}
              value={leftNoteCreateContent}
              onChange={(e) => setleftNoteCreateContent(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLeftNoteDialog(false)}>Close</Button>
          <Button onClick={() => saveLeftNoteItem()}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRightNoteDialog}>
        <DialogTitle>Добавление заметки</DialogTitle>
        <DialogContent style={{ minWidth: "500px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              style={{ marginTop: "10px" }}
              size="small"
              placeholder="Заголовок"
              value={rightNoteCreateTitle}
              onChange={(e) => setRightNoteCreateTitle(e.target.value)}
            />

            <TextField
              multiline
              style={{ marginTop: "10px" }}
              minRows={5}
              value={rightNoteCreateContent}
              onChange={(e) => setRightNoteCreateContent(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRightNoteDialog(false)}>Close</Button>
          <Button onClick={() => saveRightNoteItem()}>Save</Button>
        </DialogActions>
      </Dialog>
      <NoteDialog
        open={openNote}
        data={noteData}
        side={noteSide}
        close={() => setOpenNote(false)}
      />
    </>
  );
}

export default observer(ProjectDrawer);