import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import useStore from './useStore';

import GoogleService from '../services/GoogleService';

/**
 *
 * @param {{ adminMode: boolean }} param0
 * @param {{ type: string }} param1
 * @param {import('socket.io-client').Socket} socket
 * @returns {{
 *   accounts: Object[],                // Список аккаунтов
 *   tags: Object[],                   // Список тегов
 *   buyers: Object[],                 // Список покупателей
 *   contractors: Object[],            // Список подрядчиков
 *   searchFilter: string,                // Значение фильтра для поиска
 *   buyerFilter: string,           // Фильтр для покупателей
 *   enabledAccountsFilter: string, // Фильтр для активности аккаунтов
 *   sortBy: string,                // Поле для сортировки
 *   sortDirection: string,         // Направление сортировки
 *   setCurrentPage: (page: number) => void,  // Функция для установки текущей страницы
 *   loadAccounts: (params: {search: string, buyer: string, enabled: string}) => void,  // Функция для загрузки аккаунтов
 *   loadTags: () => void,          // Функция для загрузки тегов
 *   loadBuyers: () => void,        // Функция для загрузки покупателей
 *   loadContractors: () => void,   // Функция для загрузки подрядчиков
 *   rowsPerPage: number,           // Количество строк на странице
 *   currentPage: number,           // Текущая страница
 *   totalAccounts: number,         // Общее количество аккаунтов
 *   handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void,  // Функция для смены страницы
 *   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void,  // Функция для смены количества строк на странице
 *   handleChange: () => void,      // Функция для применения фильтра
 *   handleSort: (columnId: string) => void,  // Функция для сортировки
 *   handleApplyFilters: (newFilters: Object) => void,  // Функция для применения фильтров
 *   setBuyerFilter: (value: string) => void,  // Функция для изменения фильтра покупателей
 *   setEnabledAccountsFilter: (value: string) => void // Функция для изменения фильтра активности аккаунтов
 * }}
 */
export const useGoogleLoadData = ({ adminMode, type = 'Аккаунты', socket }) => {
  const { appStore } = useStore();
  const userId = appStore.getUserId();

  const [accounts, setAccounts] = useState([]);
  const [tags, setTags] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [totalAccounts, setTotalAccounts] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchFilter, setSearchFilter] = useState('');
  const [buyerFilter, setBuyerFilter] = useState('');
  const [enabledAccountsFilter, setEnabledAccountsFilter] = useState('');
  const [filters, setFilters] = useState({});

  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortDirection('desc');
    }
  };

  const handleDebouncedFilterChange = useCallback(
    debounce((value) => {
      setSearchFilter(value.trim());
      setCurrentPage(0);
    }, 300),
    []
  );

  const handleChange = (e) => {
    const value = e.target.value;
    handleDebouncedFilterChange(value);
  };

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    socket.on('accountChanged', () => {
      loadAccounts({
        search: searchFilter,
        buyer: buyerFilter,
        enabled: enabledAccountsFilter,
        sortBy,
        sortDirection,
        ...filters,
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [socket, searchFilter, buyerFilter, enabledAccountsFilter, sortBy, sortDirection, filters]);

  useEffect(() => {
    loadAccounts({
      search: searchFilter,
      buyer: buyerFilter,
      enabled: enabledAccountsFilter,
      sortBy,
      sortDirection,
      filters,
    });
    loadTags();
    loadBuyers();
    loadContractors();
  }, [
    adminMode,
    type,
    currentPage,
    rowsPerPage,
    searchFilter,
    buyerFilter,
    enabledAccountsFilter,
    sortBy,
    sortDirection,
    filters,
  ]);

  async function fetchDataAccounts(loader, setter, label, params = {}) {
    try {
      const { data } = await loader(params);
      setter(data.items || []);
      setTotalAccounts(data.total || 0);
    } catch (error) {
      console.error(`Ошибка загрузки ${label}:`, error);
    }
  }

  async function fetchData(loader, setter, label) {
    try {
      const { data } = await loader();
      setter(data);
    } catch (error) {
      console.error(`Ошибка загрузки ${label}:`, error);
    }
  }

  async function loadBuyerAccountsToBuyer({ userId, search, enabled, sortBy, sortDirection, filters }) {
    fetchDataAccounts(GoogleService.getBuyerAccounts, setAccounts, 'аккаунтов', {
      userId,
      archived: type === 'Архив',
      page: currentPage,
      perPage: rowsPerPage,
      search,
      enabled,
      sortBy,
      sortDirection,
      filters,
    });
  }

  async function loadAccountsToAdmin({ search, buyer, enabled, sortBy, sortDirection, filters }) {
    fetchDataAccounts(GoogleService.getAllAccountsToAdmin, setAccounts, 'аккаунтов', {
      archived: type === 'Архив',
      page: currentPage,
      perPage: rowsPerPage,
      search,
      buyer,
      enabled,
      sortBy,
      sortDirection,
      filters,
    });
  }

  async function loadAccounts({
    search = '',
    buyer = '',
    enabled = '',
    sortBy = 'date',
    sortDirection = 'desc',
    filters = {},
  }) {
    if (adminMode) {
      loadAccountsToAdmin({ search, buyer, enabled, sortBy, sortDirection, filters });
    } else {
      loadBuyerAccountsToBuyer({ userId, search, buyer, enabled, sortBy, sortDirection, filters });
    }
  }

  async function loadTags() {
    fetchData(GoogleService.getAllTags, setTags, 'тегов');
  }

  async function loadBuyers() {
    fetchData(GoogleService.getAllBuyers, setBuyers, 'баеров');
  }

  async function loadContractors() {
    fetchData(GoogleService.getAllContractors, setContractors, 'подрядчиков');
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  return {
    accounts,
    tags,
    buyers,
    contractors,
    totalAccounts,
    rowsPerPage,
    currentPage,
    searchFilter,
    buyerFilter,
    enabledAccountsFilter,
    sortBy,
    sortDirection,
    setCurrentPage,
    loadAccounts,
    loadTags,
    loadBuyers,
    loadContractors,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChange,
    handleSort,
    handleApplyFilters,
    setBuyerFilter,
    setEnabledAccountsFilter,
  };
};
