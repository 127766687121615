import { useState } from 'react';

import { toast } from 'react-toastify';

import GoogleService from '../services/GoogleService';

/**
 * @param {import('socket.io-client').Socket} socket
 * @returns {{ selectedRows: [], onSelect: Function,setSelectedRows: Function, handleRestore: Function, handleDeleteOrArchive: Function, }} Hooks API
 */
export const useGoogleAccounts = ({ socket }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const onSelect = (selectedItem) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row._id === selectedItem._id);

      if (isSelected) {
        return prevSelectedRows.filter((row) => row._id !== selectedItem._id);
      } else {
        return [...prevSelectedRows, selectedItem];
      }
    });
  };

  const handleRestore = async ({ selectedRows }) => {
    try {
      if (selectedRows.length === 0) {
        return toast.error('Выберите аккаунты');
      }
      await Promise.all(selectedRows.map((row) => GoogleService.archivedRestoreAccount(row._id, false)));
    } catch (error) {
      console.error('Error updating accounts:', error);
    } finally {
      setSelectedRows([]);
      await socket.emit('accountChanged');
    }
  };

  const handleDeleteOrArchive = async ({ selectedRows, type }) => {
    try {
      if (selectedRows.length === 0) {
        return toast.error('Выберите аккаунты');
      }
      if (type === 'Архив') {
        await Promise.all(selectedRows.map((row) => GoogleService.deleteAccount(row._id)));
      } else {
        await Promise.all(selectedRows.map((row) => GoogleService.archivedRestoreAccount(row._id, true)));
      }
    } catch (error) {
      console.error('Error updating accounts:', error);
    } finally {
      setSelectedRows([]);
      await socket.emit('accountChanged');
    }
  };

  return {
    selectedRows,
    onSelect,
    setSelectedRows,
    handleRestore,
    handleDeleteOrArchive,
  };
};
