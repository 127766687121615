import { AddAccountButton } from '../GoogleAccounts/AddAccountButton';
import { EnabledFilterBtn } from './EnabledFilterBtn';
import { FilterButton } from './FilterButton';

import { TextField, MenuItem, Select, InputLabel, FormControl, Box } from '@mui/material';

/**
 *
 * @param { searchFilter: string } param0
 * @param { handleChange: () => void } param1
 * @param { type: 'Аккаунты' | 'Архив' | 'Теги' } param2
 * @param { adminMode: boolean } param3
 * @param { buyers: { _id: string, name: string }[] } param4
 * @param { contractors: { _id: string, name: string }[] } param5
 * @param { tags: { _id: string, name: string }[] } param6
 * @param { onBuyerChange: ({buyerId: string}) => void } param7
 * @param { selectedBuyer: string } param8
 * @param { enabledAccountsFilter: string } param9
 * @param { setEnabledAccountsFilter: (string) => void } param10
 * @param { accounts: [] } param11
 * @param { socket: import('socket.io-client').Socket } param12
 * @param { handleApplyFilters: () => void } param13
 * @returns {JSX.Element}
 */
export const GoogleFilters = ({
  searchFilter,
  handleChange,
  type,
  adminMode,
  buyers,
  contractors,
  tags,
  onBuyerChange,
  selectedBuyer,
  enabledAccountsFilter,
  setEnabledAccountsFilter,
  accounts,
  socket,
  handleApplyFilters,
}) => {
  const handleEnabledFilter = (filterValue) => {
    setEnabledAccountsFilter(enabledAccountsFilter === filterValue ? '' : filterValue);
  };

  const enabledCount = accounts.filter((account) => account.enabled).length;
  const disabledCount = accounts.filter((account) => !account.enabled).length;

  return (
    <Box style={{ margin: '20px 0', display: 'flex', alignItems: 'center', gap: '10px' }}>
      {adminMode && type !== 'Теги' && (
        <FormControl style={{ minWidth: '130px' }}>
          <InputLabel id='buyer-select-label'>Все аккаунты</InputLabel>
          <Select
            labelId='buyer-select-label'
            value={selectedBuyer}
            onChange={(e) => onBuyerChange({ buyerId: e.target.value })}
          >
            <MenuItem value=''>Все аккаунты</MenuItem>
            {buyers.map((buyer) => (
              <MenuItem key={buyer._id} value={buyer._id}>
                {buyer.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {type !== 'Теги' && (
        <FormControl
          style={{
            width: 'min(340px, 100%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <EnabledFilterBtn
            filterValue='enabled'
            isActive={enabledAccountsFilter === 'enabled'}
            count={enabledCount}
            onClick={() => handleEnabledFilter('enabled')}
            iconColor='#00FF6F'
            bgColor='rgb(1, 54, 24)'
            iconFillColor='#00FF6F'
          />
          <EnabledFilterBtn
            filterValue='disabled'
            isActive={enabledAccountsFilter === 'disabled'}
            count={disabledCount}
            onClick={() => handleEnabledFilter('disabled')}
            iconColor='#FF0000'
            bgColor='rgb(201, 29, 29)'
            iconFillColor='#FF0000'
          />
        </FormControl>
      )}

      {adminMode && type === 'Аккаунты' && <AddAccountButton socket={socket} />}
      {type !== 'Теги' && (
        <FilterButton contractorsList={contractors} tagsList={tags} handleApplyFilters={handleApplyFilters} />
      )}

      <TextField
        sx={{ background: '#333333', width: '60%' }}
        label='Поиск'
        fullWidth
        defaultValue={searchFilter}
        onChange={handleChange}
      />
    </Box>
  );
};
