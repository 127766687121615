import React, { useEffect, useState } from 'react';
import TagRow from '../TagRow';
import { Button, IconButton } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TagDialog from './TagDialog';
import GoogleService from '../../../../services/GoogleService';
import customSort from '../assets/CustomSort';

const TagsTable = ({ searchFilter }) => {
  const [openTagDialog, setOpenTagDialog] = useState(false);
  const [tags, setTags] = useState([]);
  const [editModeData, setEditModeData] = useState({});
  const [direction, setDirection] = useState(false);
  useEffect(() => {
    loadTags();
  }, []);
  useEffect(() => {
    loadTags();
  }, [openTagDialog]);

  async function loadTags() {
    try {
      const tags = await GoogleService.getAllTags();
      setTags(tags.data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleAddClick() {
    setEditModeData({});
    setOpenTagDialog(true);
  }
  function openEditDialog(data) {
    setEditModeData(data);
    setOpenTagDialog(true);
  }
  async function deleteTag(id) {
    try {
      await GoogleService.deleteTag(id);
      loadTags();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className='g-table g-table--tags'>
        <div className='g-table-head'>
          Теги
          <IconButton onClick={() => setDirection(!direction)}>
            <SwapVertIcon />
          </IconButton>
        </div>
        {tags.length &&
          tags
            .filter((i) => i.label.includes(searchFilter))
            .sort((a, b) => customSort(a, b, direction))
            .map((item) => {
              return (
                <TagRow
                  deleteCb={() => deleteTag(item._id)}
                  editCb={openEditDialog}
                  refreshCb={loadTags}
                  key={'c' + item.label}
                  data={item}
                />
              );
            })}
        <div className='g-table-add'>
          <Button onClick={handleAddClick}>Добавить +</Button>
        </div>
      </div>
      {openTagDialog && (
        <TagDialog editModeData={editModeData} open={openTagDialog} closeAction={() => setOpenTagDialog(false)} />
      )}
    </>
  );
};
export default TagsTable;
