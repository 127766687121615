import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import { observer } from "mobx-react-lite";
import mainAPI from "../../../utils/mainAPI";
import { CRMContext } from "..";

Modal.setAppElement("#root");

const AddUserModal = ({ show, onClose }) => {
  const [successToast, setSuccessToast] = useState(false);

  const [errorToast, setErrorToast] = useState(false);
  const { crmStore } = useContext(CRMContext);

  useEffect(() => {
    mainAPI.get("/primary/all-users").then((res) => {
      crmStore.setUsersList(res.data);
    });
  }, []);

  const project = crmStore.currentProject;

  return (
    <>
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        className="modal"
        overlayClassName="overlay"
      >
        <div className="close-btn-ctn">
          <h1 style={{ flex: "1 90%" }}>Выберите пользователя</h1>
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>
        <div>
          <List
            sx={{
              width: "100%",
              height: "360px",
              bgcolor: "background.paper",
              overflow: "auto",
            }}
            dense
            component="div"
            role="list"
          >
            {crmStore.userList.map((user) => (
              <ListItem key={user.login} role="listitem">
                <ListItemText
                  id={`"transfer-list-all-item-${user.login}-label"`}
                  primary={user.login}
                />
                <Button
                  color={
                    project.users.includes(user.login) ? "error" : "primary"
                  }
                  onClick={() => {
                    if (project.users.includes(user.login)) {
                      crmStore.deleteUserFromProject(user.login);
                    } else {
                      crmStore.addUserToProject(user.login);
                    }
                  }}
                >
                  {project.users.includes(user.login) ? "x" : "+"}
                </Button>
              </ListItem>
            ))}
          </List>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={successToast}
        severity="success"
        autoHideDuration={1500}
        onClose={() => setSuccessToast(false)}
        message="Пользователь добавлен"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSuccessToast(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={errorToast}
        autoHideDuration={1500}
        onClose={() => setErrorToast(false)}
        message="Ошибка"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorToast(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default observer(AddUserModal);
