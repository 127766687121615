import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs, Typography } from "@mui/material";

import useStore from "../../hooks/useStore";
import mainAPI from "../../utils/mainAPI";
import CardsStats from "./CardsAdmin";
import CardsAdmin from "./CardsAdmin";
import BuyersCards from "./BuyersCards";

const CardsRent = () => {
  const { appStore } = useStore();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    try {
      mainAPI.get("/cards/users").then((res) => {
        appStore.setCardsUsers(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <Typography variant="h4" style={{ textAlign: "left", marginLeft: 50 }}>
        Аренда карт
      </Typography>
      <Container maxWidth={false}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {appStore.getUserRoles().includes("farmer") ? (
            <Tabs
              value={activeTab}
              sx={{ marginBottom: "15px", width: "fit-content" }}
              onChange={(e, n) => setActiveTab(n)}
              aria-label="basic tabs example"
            >
              <Tab label="Мои карты" />
              <Tab label="Сводка карт" />
            </Tabs>
          ) : null}
        </div>
        {activeTab === 0 ? <BuyersCards /> : null}
        {activeTab === 1 ? <CardsAdmin /> : null}
      </Container>
    </>
  );
};

export default CardsRent;
