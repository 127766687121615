import React, { useEffect, useState } from 'react';
import TagRow from '../TagRow';
import { Button, IconButton } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ContractorDialog from './ContractorDialog';
import GoogleService from '../../../../services/GoogleService';
import customSort from '../assets/CustomSort';

const ContractorsTable = ({ searchFilter }) => {
  const [openContractorDialog, setOpenContractorDialog] = useState(false);
  const [contractors, setContractors] = useState([]);
  const [editModeData, setEditModeData] = useState({});
  const [direction, setDirection] = useState(false);
  useEffect(() => {
    loadContractors();
  }, []);
  useEffect(() => {
    loadContractors();
  }, [openContractorDialog]);

  async function loadContractors() {
    try {
      const contractors = await GoogleService.getAllContractors();
      setContractors(contractors.data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleAddClick() {
    setEditModeData({});
    setOpenContractorDialog(true);
  }
  function openEditDialog(data) {
    setEditModeData(data);
    setOpenContractorDialog(true);
  }
  async function deleteContractor(id) {
    try {
      await GoogleService.deleteContractor(id);
      loadContractors();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className='g-table g-table--contractor'>
        <div className='g-table-head'>
          Подрядчики
          <IconButton onClick={() => setDirection(!direction)}>
            <SwapVertIcon />
          </IconButton>
        </div>
        {contractors.length &&
          contractors
            .filter((i) => i.label.includes(searchFilter))
            .sort((a, b) => customSort(a, b, direction))
            .map((item) => {
              return (
                <TagRow
                  deleteCb={() => deleteContractor(item._id)}
                  editCb={openEditDialog}
                  refreshCb={loadContractors}
                  key={'c' + item.label}
                  data={item}
                />
              );
            })}
        <div className='g-table-add'>
          <Button onClick={handleAddClick}>Добавить +</Button>
        </div>
      </div>
      {openContractorDialog && (
        <ContractorDialog
          editModeData={editModeData}
          open={openContractorDialog}
          closeAction={() => setOpenContractorDialog(false)}
        />
      )}
    </>
  );
};
export default ContractorsTable;
