import React, { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.scss";

import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import mainAPI from "../../../utils/mainAPI";
import { toast } from "react-toastify";
import useStore from "../../../hooks/useStore";
import DatePicker from "react-date-picker";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
function convertDate(date) {
  const mydate = new Date(Date.parse(date));
  var yyyy = mydate.getFullYear().toString();
  var mm = (mydate.getMonth() + 1).toString();
  var dd = mydate.getDate().toString();

  var mmChars = mm.split("");
  var ddChars = dd.split("");

  return (
    (ddChars[1] ? dd : "0" + ddChars[0]) +
    "." +
    (mmChars[1] ? mm : "0" + mmChars[0]) +
    "." +
    yyyy
  );
}

const Card = ({ data, refresh }) => {
  const [editDialogCardNumber, setEditDialogCardNumber] = useState("");
  const [editDialogCardBank, setEditDialogCardBank] = useState("");
  const [editDialogCardUser, setEditDialogCardUser] = useState("");
  const [editDialogCardDate, setEditDialogCardDate] = useState("");
  const [editDialogCardService, setEditDialogCardService] = useState("");
  const [editDialogCardCurrency, setEditDialogCardCurrency] = useState("");
  const [editDialogCardComment, setEditDialogCardComment] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const open = Boolean(anchorEl);
  const { appStore } = useStore();
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setEditDialogCardBank(data.bank);
    setEditDialogCardUser(data.buyer_id);
    setEditDialogCardComment(data.comment);
    setEditDialogCardDate(data.date);
    setEditDialogCardCurrency(data.currency);
    setEditDialogCardNumber(data.number);
    setEditDialogCardService(data.service);
  }, []);
  function saveChanges() {
    const postData = {
      _id: data._id,
      bank: editDialogCardBank,
      currency: editDialogCardCurrency,
      date: editDialogCardDate,
      service: editDialogCardService,
      number: editDialogCardNumber,
      buyer_id: editDialogCardUser,
      status: data.status,
      comment: editDialogCardComment,
    };
    mainAPI
      .post("/cards/updatecard", { data: postData })
      .then((res) => {
        toast.success("Успешно");
        setEditDialog(false);
        refresh();
      })
      .catch((e) => {
        toast.error("Ошибка");
      });
  }
  function switchType() {
    switch (data.type) {
      case 1:
        return "TW ES";
      case 2:
        return "TW KE";
      case 3:
        return "PP KE";
      case 4:
        return "TW GE";
      default:
        break;
    }
  }
  function drawUserSelect() {
    return appStore.getCardsUsers()?.map((item) => {
      return (
        <MenuItem key={"user" + item.id} value={item.id}>
          {item.login}
        </MenuItem>
      );
    });
  }
  function deleteCard() {
    mainAPI.delete("/cards/card?id=" + data._id).then((res) => {
      toast.success("Успешно");
      refresh();
      handleMenuClose();
    });
  }
  function getUserName() {
    const allUsers = appStore.getCardsUsers();
    const user = allUsers.filter((item) => item.id == data.buyer_id);
    return user[0]?.login || null;
  }
  return (
    <>
      <Tooltip title={data.time ? data.time : null}>
        <div
          onClick={
            appStore.getUserRoles().indexOf("farmer") !== -1
              ? handleMenuClick
              : null
          }
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className={
            data.status === 1
              ? "account_item card_1"
              : data.status === 2
              ? "account_item card_2"
              : data.status === 3
              ? "account_item card_3"
              : data.status === 4
              ? "account_item card_4"
              : "account_item card_5"
          }
        >
          {data.number.slice(-4)}
        </div>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <EditIcon
            onClick={() => setEditDialog(true)}
            style={{ height: "15px", margin: "0 5px", cursor: "pointer" }}
          />{" "}
          <p style={{ fontSize: "10px" }}>|</p>
          <DeleteIcon
            onClick={() => {
              deleteCard();
            }}
            style={{
              height: "15px",
              margin: "0 5px",
              color: "red",
              cursor: "pointer",
            }}
          />
        </div>
        <MenuItem onClick={() => navigator.clipboard.writeText(getUserName())}>
          Пользователь: {getUserName()}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
          }}
        >
          {data.number}
        </MenuItem>
        <MenuItem>{data.bank}</MenuItem>

        <MenuItem>{data.service}</MenuItem>

        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(data.date);
          }}
        >
          {convertDate(data.date)}
        </MenuItem>
        <MenuItem onClick={() => navigator.clipboard.writeText(data.currency)}>
          {data.currency}
        </MenuItem>
        <MenuItem>{data.comment}</MenuItem>
      </Menu>
      <Dialog
        open={editDialog}
        close={() => setEditDialog(false)}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">
          Редактирование аккаунта
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", paddingRight: "20px" }}>
            <div>
              {" "}
              <TextField
                label="Банк"
                placeholder="втб"
                value={editDialogCardBank}
                onChange={(e) => setEditDialogCardBank(e.target.value)}
                size="small"
              />
              <br />
              <TextField
                label="Номер карты"
                style={{ marginTop: "15px" }}
                placeholder="1032"
                value={editDialogCardNumber}
                onChange={(e) => setEditDialogCardNumber(e.target.value)}
                size="small"
              />
              <br />
              <TextField
                label="Сервис"
                size="small"
                style={{ width: "100%", marginTop: "10px" }}
                value={editDialogCardService}
                onChange={(e) => setEditDialogCardService(e.target.value)}
              />
              <br />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DatePicker
                  value={editDialogCardDate}
                  onChange={setEditDialogCardDate}
                />
                <FormControl
                  size="small"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Пользователь
                  </InputLabel>
                  <Select
                    value={editDialogCardUser}
                    onChange={(e) => setEditDialogCardUser(e.target.value)}
                  >
                    {drawUserSelect()}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label2">Валюта</InputLabel>
                  <Select
                    placeholder="Валюта"
                    size="small"
                    style={{ width: "100%", marginTop: "10px" }}
                    value={editDialogCardCurrency}
                    onChange={(e) => setEditDialogCardCurrency(e.target.value)}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="RUB">RUB</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <TextField
            label="Комментарий"
            size="small"
            multiline
            rows={7}
            style={{ width: "100%", marginTop: "20px" }}
            value={editDialogCardComment}
            onChange={(e) => setEditDialogCardComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => saveChanges()}>
            Сохранить
          </Button>
          <Button onClick={() => setEditDialog(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Card;
