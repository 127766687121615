import BuyersTable from './BuyersTable';
import ContractorsTable from './ContractorsTable';
import TagsTable from './TagsTable';

import './style.scss';

/**
 *
 * @param { searchFilter: string} param0
 * @returns
 */
const GoogleTags = ({ searchFilter }) => {
  return (
    <div style={{ display: 'flex', marginTop: '32px' }}>
      <BuyersTable searchFilter={searchFilter} />
      <ContractorsTable searchFilter={searchFilter} />
      <TagsTable searchFilter={searchFilter} />
    </div>
  );
};
export default GoogleTags;
