import React, { useContext } from "react";
import { useDrop } from "react-dnd";
import { observer } from 'mobx-react-lite'
import { CRMContext } from "..";

const ITEM_TYPE = "ITEM";

const DropWrapper = ({ onDrop, children, columnId, displayBlock }) => {

  const { crmStore } = useContext(CRMContext)

  const project = crmStore.currentProject

  const [{ isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    canDrop: (task, monitor) => {
      const statusIndex = project.columns.findIndex((col) => col.id === columnId);
      const blockDrop = project.columns.findIndex((col) => col.id === task.status)
      let canDropArr = Array.from(Array(project.columns.length).keys())
      delete canDropArr[blockDrop]
      return canDropArr.includes(statusIndex);

      // Array.from(Array(project.columns.length).keys())
    },
    drop: (task, monitor) => {
      onDrop(task, monitor, columnId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className="drop-wrapper" style={{ display: displayBlock }}>
      {React.cloneElement(children, { isOver })}
    </div>
  );
};

export default observer(DropWrapper);
