import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import mainAPI from "../../../../utils/mainAPI";
import { toast } from "react-toastify";

const OrganizationDialog = ({ open, close }) => {
  const [orgType, setOrgType] = useState("ffl");
  const [isOrs, setIsOrs] = useState(false);
  const [isRr, setIsRr] = useState(false);
  const [inn, setInn] = useState(null);
  const [name, setName] = useState(null);
  const [epayNumber, setEpayNumber] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [regNumber, setRegNumber] = useState(null);
  const [alternativeInn, setAlternativeInn] = useState(null);
  const [oksmNumber, setOksmNumber] = useState(null);
  const [rsUrl, setRsUrl] = useState(null);
  const [okveds, setOkveds] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [loadedPlatforms, setLoadedPlatforms] = useState([]);
  useEffect(() => {
    mainAPI.get("/ord/platforms").then((res) => {
      setLoadedPlatforms(res.data);
      console.log(res.data.length);
      if (res.data.length === 0) {
        toast.error("Сначала добавьте платформу");
        close();
      }
    });
  }, []);
  function addPlatform() {
    setPlatforms([...platforms, { id: "" }]);
  }
  function saveOrganization() {
    const data = {
      type: orgType,
      isOrs: isOrs,
      isRr: isRr,
      inn: inn,
      name: name,
      epayNumber: epayNumber,
      mobilePhone: mobilePhone,
      regNumber: regNumber,
      alternativeInn: alternativeInn,
      oksmNumber: oksmNumber,
      rsUrl: rsUrl,
      platforms: platforms,
      okveds: okveds,
    };
    mainAPI
      .post("/ord/organization", data)
      .then((res) => {
        toast.success("Успешно");
        close();
      })
      .catch((e) => {
        toast.error(JSON.stringify(e.response.data));
        console.log(e.response.data);
      });
  }
  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="watchtoken-title"
      aria-describedby="watchtoken-description"
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Добавление организации
      </DialogTitle>
      <DialogContent sx={{ minWidth: "200px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Тип организации"
          value={orgType}
          style={{ width: "48%" }}
          onChange={(e) => setOrgType(e.target.value)}
          size="small"
          required
        >
          <MenuItem value="ffl">Иностранное физическое лицо</MenuItem>
          <MenuItem value="ful">Иностранное юридическое лицо</MenuItem>
          <MenuItem value="ip">ИП (РФ)</MenuItem>
          <MenuItem value="fl">Физическое лицо (РФ)</MenuItem>
          <MenuItem value="ul">Юридическое лицо (РФ)</MenuItem>
        </Select>
        <TextField
          style={{ width: "48%", marginLeft: "10px" }}
          size="small"
          value={inn}
          onChange={(e) => setInn(e.target.value)}
          label="ИНН"
        ></TextField>
        <TextField
          style={{ marginTop: "15px", width: "48%" }}
          required
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Наименование организации или ФИО"
        ></TextField>
        <TextField
          style={{ marginTop: "15px", width: "48%", marginLeft: "10px" }}
          value={epayNumber}
          onChange={(e) => setEpayNumber(e.target.value)}
          size="small"
          label="Номер электронного средства платежа (для иностранных физлиц). "
        ></TextField>
        <TextField
          style={{ marginTop: "15px", width: "48%" }}
          value={mobilePhone}
          onChange={(e) => setMobilePhone(e.target.value)}
          size="small"
          label="Абонентский номер мобильного телефона (для иностранных физлиц). "
        ></TextField>
        <TextField
          style={{ marginTop: "15px", width: "48%", marginLeft: "10px" }}
          value={regNumber}
          onChange={(e) => setRegNumber(e.target.value)}
          size="small"
          label="Регистрационный номер  (для иностранных юр.лиц)."
        ></TextField>
        <TextField
          style={{ marginTop: "15px", width: "48%" }}
          size="small"
          value={alternativeInn}
          onChange={(e) => setAlternativeInn(e.target.value)}
          label="Альтернативный ИНН (для иностранных юр. и физ. лиц).  "
        ></TextField>
        <TextField
          style={{ marginTop: "15px", marginLeft: "10px", width: "48%" }}
          size="small"
          value={oksmNumber}
          onChange={(e) => setOksmNumber(e.target.value)}
          label="Код страны регистрации юрлица в соответствии с ОКСМ. "
        ></TextField>
        <TextField
          style={{ marginTop: "15px" }}
          size="small"
          fullWidth
          value={rsUrl}
          onChange={(e) => setRsUrl(e.target.value)}
          label="URL рекламной системы. "
        ></TextField>
        <TextField
          style={{ marginTop: "15px" }}
          size="small"
          fullWidth
          value={okveds}
          onChange={(e) => setOkveds(e.target.value)}
          label="Коды ОКВЭД рекламодателя (через запятую)."
          helperText="Если при отправке креатива не были указаны ОКВЭДы объекта рекламирования, будут использованы ОКВЭДы рекламодателя."
        ></TextField>
        <FormControlLabel
          control={
            <Checkbox
              required
              size="small"
              checked={isOrs}
              onChange={(e) => {
                setIsOrs(e.target.checked);
              }}
            />
          }
          label="Является оператором рекламных систем (ОРС)*"
        />
        <FormControlLabel
          control={
            <Checkbox
              required
              size="small"
              checked={isRr}
              onChange={(e) => {
                setIsRr(e.target.checked);
              }}
            />
          }
          label="Является рекламораспространителем (РР)*"
        />
        <p style={{ margin: "0px" }}>
          Платформы:<Button onClick={() => addPlatform()}>+</Button>
        </p>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {platforms.map((item, itemIndex) => {
            return (
              <Select
                key={"select" + itemIndex}
                onChange={(e) => {
                  const temp = [...platforms];
                  temp[itemIndex].id = e.target.value;

                  setPlatforms(temp);
                }}
                size="small"
                style={{ margin: "10px 0" }}
                value={item.id}
              >
                {loadedPlatforms.map((platform) => {
                  return (
                    <MenuItem
                      key={platform._id + itemIndex}
                      value={platform._id}
                      inputProps={{ "data-name": platform.name }}
                    >
                      {platform.name}
                    </MenuItem>
                  );
                })}
              </Select>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            saveOrganization();
          }}
        >
          Сохранить
        </Button>
        <Button onClick={() => close()}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationDialog;
