import { Container, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import mainAPI from '../../../utils/mainAPI';
import { DataGrid } from '@mui/x-data-grid';
import './style.scss';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
function Networks() {
  const [loadedNetworks, setLoadedNetworks] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [wasEdited, setWasEdited] = useState(false);

  useEffect(() => {
    mainAPI.get('/bot/networks').then((res) => {
      setLoadedNetworks(res.data);
    });
  }, []);

  const columns = [
    { field: 'name', headerName: 'Название', width: 200, editable: true },
    {
      field: 'binom_3_id',
      headerName: 'traffictraders',
      type: 'number',
      align: 'left',
      width: 130,
      editable: true,
    },
  ];
  function deleteSelectedRows() {
    setWasEdited(true);
    let temp = [...loadedNetworks];
    selectionModel.forEach((id) => {
      setSelectionModel(selectionModel.filter((item) => item !== id));
      temp = temp.filter((item) => item.id !== id);
    });
    setLoadedNetworks(temp);
  }
  function customToolbar() {
    return (
      <>
        <Button
          onClick={() => {
            setWasEdited(true);
            setLoadedNetworks([...loadedNetworks, { id: Date.now() }]);
          }}
        >
          Добавить
        </Button>
        <Button disabled={selectionModel.length < 1} onClick={deleteSelectedRows}>
          Удалить
        </Button>
      </>
    );
  }
  function saveData() {
    setWasEdited(false);
    mainAPI.post('/bot/updatenetworks', loadedNetworks).then(() => {
      mainAPI
        .get('/bot/networks')
        .then((res) => {
          setLoadedNetworks(res.data);
          if (res.status == 200) {
            toast.success('Успешно!');
          }
        })
        .catch((e) => {
          toast.error('Ошибка');
        });
    });
  }
  function customFooter() {
    return (
      <Button onClick={saveData} disabled={!wasEdited}>
        Сохранить
      </Button>
    );
  }
  const handleRowEditCommit = (params) => {
    setWasEdited(true);
    const id = params.id;
    const key = params.field;
    const value = params.value;
    const updated = [...loadedNetworks];
    updated.forEach((el) => {
      if (el.id === id) {
        el[key] = value;
      }
    });
  };
  function drawTable() {
    return (
      <Paper style={{ width: 700, margin: '0 auto' }}>
        <DataGrid
          rows={loadedNetworks}
          autoHeight
          onCellEditCommit={handleRowEditCommit}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          columns={columns}
          hideFooterRowCount
          hideFooterPagination
          disableSelectionOnClick
          hideFooterSelectedRowCount
          components={{
            Toolbar: customToolbar,
            Footer: customFooter,
          }}
        />
      </Paper>
    );
  }

  return (
    <>
      <CssBaseline />
      <Typography variant='h4' style={{ textAlign: 'left', marginLeft: 50, marginBottom: '14px' }}>
        Реклы
      </Typography>
      <Container>{loadedNetworks.length ? drawTable() : null}</Container>
    </>
  );
}

export default Networks;
