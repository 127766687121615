import "./style.scss";
import useStore from "../../hooks/useStore";
import React, { useEffect, useState } from "react";
import mainAPI from "../../utils/mainAPI";
import Loader from "../Loader";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { Paper, Typography } from "@mui/material";

function Editor() {
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [removedIndex, setRemovedIndex] = useState(null);
  const [state, setState] = useState(initialState);
  const { activeProject, appStore, setProject } = useStore();
  const [loading, setLoading] = useState(true);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [myProjects, setMyProjects] = useState([]);
  const [redirect, setRedirect] = useState();
  const [filter, setFilter] = useState("");
  const [myProjectsFiltered, setMyProjectsFiltered] = useState([]);

  useEffect(() => {
    mainAPI.get("/editor/defaultprojects").then((response) => {
      setLoading(false);
      setDefaultTemplates(response.data);
    });
    mainAPI.get("/editor/userprojects").then((response) => {
      setLoadingProjects(false);
      setMyProjects(response.data);
      setMyProjectsFiltered(response.data);
    });
  }, []);

  function handleClick(e) {
    appStore.setHasProject(true);
    setProject(defaultTemplates[e.target.name.slice(1)].defaults);
    activeProject.setActiveProject(
      defaultTemplates[e.target.name.slice(1)].name
    );
    appStore.hideMenu();
    setRedirect(<Navigate to="/editor" />);
  }

  function handleMyClick(e) {
    appStore.setHasProject(true);
    setProject(myProjects[e.target.name.slice(1)]);
    activeProject.setActiveProject(myProjects[e.target.name.slice(1)].landName);
    appStore.hideMenu();
    setRedirect(<Navigate to="/editor" />);
  }

  function handleMenuClick(event) {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setRemovedIndex(event.target.name.slice(1));
  }

  function handleMenuClose() {
    setState(initialState);
  }

  function handleRemoveClick() {
    setState(initialState);
    removeProject();
  }

  function removeProject() {
    mainAPI
      .post("removeProject", { id: myProjects[removedIndex]._id })
      .then((res) => {
        toast.success(res.statusText);
        setRemovedIndex(null);
        window.location.reload();
      });
  }

  function handleFilterChange(e) {
    if (e.target.value) {
      // const temp = myProjects.filter(item=>item.name.toLowerCase()==e.target.value.toLowerCase())
      // setMyProjectsFiltered(temp)
      const temp = myProjects.filter(
        (item) =>
          item.name
            .toLowerCase()
            .indexOf(e.target.value.toLocaleLowerCase()) !== -1
      );
      setMyProjectsFiltered(temp);
    } else {
      setMyProjectsFiltered(myProjects);
    }
    setFilter(e.target.value);
  }

  return (
    <>
      <Typography
        variant="h4"
        style={{ textAlign: "left", marginLeft: 50, marginBottom: "14px" }}
      >
        Редактор
      </Typography>
      {redirect}
      <Container>
        <Paper>
          <div className="projects ">
            <div className="findBox">
              <span>Мои проекты</span>
              <TextField
                value={filter}
                onChange={handleFilterChange}
                type="search"
                label="Поиск"
              />
            </div>
            <div className="projects_box projects_box_my">
              {loadingProjects ? (
                <Loader />
              ) : myProjects.length === 0 ? (
                <p className="none">Нет проектов</p>
              ) : (
                myProjectsFiltered.map((i, index) => {
                  return (
                    <div
                      className="projects_item"
                      onContextMenu={handleMenuClick}
                      style={{ cursor: "context-menu" }}
                      key={index}
                    >
                      <p className="title">{i.name}</p>
                      <img
                        className="preview"
                        name={"P" + index}
                        onClick={handleMyClick}
                        src={`${
                          process.env.NODE_ENV === "development"
                            ? "http://localhost:3030"
                            : "https://2lead.shop:3033"
                        }/api/editor/preview/${i.name}`}
                        alt=""
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="projects projects_default">
            <span>Шаблоны</span>
            <div className="projects_box">
              {loading ? (
                <Loader />
              ) : defaultTemplates.length === 0 ? (
                <p className="none">Нет доступных проектов</p>
              ) : (
                defaultTemplates.map((i, index) => {
                  return (
                    <div className="projects_item" key={index}>
                      <p className="title">{i.name}</p>
                      <img
                        className="preview"
                        name={"D" + index}
                        onClick={handleClick}
                        src={`${
                          process.env.NODE_ENV === "development"
                            ? "http://localhost:1337"
                            : "http://5.45.114.122:3009"
                        }/api/editor/defaultpreview/${i.name}`}
                        alt=""
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </Paper>
      </Container>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleRemoveClick}>Удалить</MenuItem>
      </Menu>
    </>
  );
}

export default observer(Editor);
