const geo = [
  'Global',
  'AT',
  'AU',
  'BE',
  'BR',
  'CA',
  'CH',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FL',
  'FR',
  'GB',
  'IL',
  'ID',
  'IT',
  'LU',
  'MY',
  'NL',
  'NZ',
  'PL',
  'PT',
  'RU',
  'SE',
  'SA',
  'SG',
  'TH',
  'TW',
  'US',
  'MX',
  'CO',
];
export default geo;
