import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import GoogleService from '../../../services/GoogleService';

const SelectContractorDialog = ({ open, closeCb, accId, contractors }) => {
  const [value, setValue] = useState('');

  async function updateContractor() {
    try {
      await GoogleService.updateAccountContractor(accId, value);
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Выбор подрядчика</DialogTitle>
      <DialogContent>
        <Select value={value} onChange={(e) => setValue(e.target.value)} style={{ minWidth: 200 }}>
          {contractors.length &&
            contractors.map((i) => (
              <MenuItem key={'cc' + i._id} value={i._id}>
                {i.label}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateContractor}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
export default SelectContractorDialog;
