import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Paper,
  Button,
  IconButton,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import './style.scss';

export default function PixelsV3() {
  const [pixels, setPixels] = useState([]);
  const [addPixelDialog, setAddPixelDialog] = useState(false);
  const [newPixelId, setNewPixelId] = useState('');
  const [newPixelToken, setNewPixelToken] = useState('');
  const [newPixelName, setNewPixelName] = useState('');
  const [tokenDialog, setTokenDialog] = useState(false);
  const [tokenDialogToken, setTokenDialogToken] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  useEffect(() => {
    loadPixels();
  }, []);

  function loadPixels() {
    axios
      .get('https://prosperwave.info/pixels', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setPixels(res.data.reverse());
      });
  }
  async function submitNewPixel() {
    axios
      .post(
        'https://prosperwave.info/pixel/add',
        { name: newPixelName, accessToken: newPixelToken, id: newPixelId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        toast.success('Успешно');
        setNewPixelId('');
        setNewPixelToken('');
        setNewPixelName('');
        setAddPixelDialog(false);
        loadPixels();
      })
      .catch((e) => {
        toast.error('Ошибка');
      });
  }

  async function deletePixel(id) {
    try {
      await axios.delete('https://prosperwave.info/pixel?id=' + id);
      toast.success('Успешно');
      setDeleteDialog(false);
      loadPixels();
    } catch (error) {
      toast.error('ERR');
    }
  }
  useEffect(() => {
    if (tokenDialog) {
      axios.get('https://prosperwave.info/pixeltoken?id=' + tokenDialog).then((res) => {
        setTokenDialogToken(res.data.token);
      });
    }
  }, [tokenDialog]);
  return (
    <>
      {pixels.map((item, index) => {
        return (
          <Paper className='pixelItem' variant={index % 2 !== 0 ? 'outlined' : 'elevation'} key={item._id + item.name}>
            <Typography>{item.name}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ marginRight: '30px' }}>{item.id}</Typography>
              <div className='pixelItem-actions'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setTokenDialog(item._id);
                  }}
                >
                  TOKEN
                </Button>
                <IconButton data-id={item.pixel_id} onClick={() => setDeleteDialog(item._id)} aria-label='delete'>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </Paper>
        );
      })}
      <Dialog open={addPixelDialog} onClose={() => setAddPixelDialog(false)} aria-labelledby='addnewpixel-title'>
        <DialogTitle id='addnewpixel'>Добавление нового пикселя</DialogTitle>
        <Box>
          <DialogContent>
            <TextField
              required
              margin='dense'
              label='Pixel Name'
              type='text'
              multiline
              fullWidth
              value={newPixelName}
              onChange={(e) => setNewPixelName(e.target.value)}
            />
            <TextField
              required
              margin='dense'
              label='Pixel ID'
              type='text'
              multiline
              fullWidth
              value={newPixelId}
              onChange={(e) => setNewPixelId(e.target.value)}
            />
            <TextField
              required
              margin='dense'
              id='pixel_token'
              label='Access Token'
              type='text'
              multiline
              fullWidth
              value={newPixelToken}
              onChange={(e) => setNewPixelToken(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddPixelDialog(false)} color='primary'>
              Отмена
            </Button>
            <Button disabled={!newPixelToken || !newPixelId || !newPixelName} onClick={submitNewPixel} color='primary'>
              Создать
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Fab
        sx={{ position: 'fixed', right: '20px', bottom: '30px' }}
        color='primary'
        aria-label='add'
        variant='contained'
        style={{ margin: '15px 20px', textTransform: 'none' }}
        endIcon={<AddIcon />}
        onClick={setAddPixelDialog}
      >
        <AddIcon />
      </Fab>
      <Dialog open={tokenDialog} onClose={() => setTokenDialog(false)}>
        <DialogContent>
          <TextField sx={{ width: 550 }} value={tokenDialogToken} multiline rows={4} />
        </DialogContent>
      </Dialog>
      <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
        <DialogTitle>Вы уверенны?</DialogTitle>
        <DialogActions>
          <Button onClick={() => deletePixel(deleteDialog)}>Удалить</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
