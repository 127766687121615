import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import useStore from '../../hooks/useStore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CapItem from '../Cap/Item/CapItem';
import mainAPI from '../../utils/mainAPI';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import 'react-dropzone-uploader/dist/styles.css';
import CheckCampaignModal from './CheckCampaignModal';
import UnicModal from './UnicModal';
import downloadLand from '../../utils/downloadLanding';

function Home() {
  const [hasFavoriteCaps, setHasFavoriteCaps] = useState(false);
  const [capsData, setCapsData] = useState(null);
  const [capsDataLoaded, setCapsDataLoaded] = useState(false);
  const [updateTokenDialog, setUpdateTokenDialog] = useState(false);
  const [creoModal, setCreoModal] = useState(false);
  const [updateTokenValue, setUpdateTokenValue] = useState(null);
  const [checkLand, setCheckLand] = useState(false);
  const [checkLandId, setCheckLandId] = useState(null);
  const [checkStatus, setCheckStatus] = useState(0);
  const [checkResult, setCheckResult] = useState({});
  const [campaignModal, setCampaignModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [binomVersion, setBinomVersion] = useState(false);
  const [unicModal, setUnicModal] = useState(null);

  const { capsStore, appStore } = useStore();

  const onDrop = useCallback((acceptedFiles) => {
    let fd = new FormData();
    acceptedFiles.map((file) => {
      fd.append('zip', file);
    });
    mainAPI
      .post('/primary/unic', fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success('Успешно!');
        } else {
          toast.error('ERROR');
        }
      })
      .catch((err) => {
        toast.error('ERROR');
      });
  });

  useEffect(() => {
    setCapsDataLoaded(false);
    document.title = '2leads Dev';
    setCapsDataLoaded(true);
    setCapsData(null);
    setHasFavoriteCaps(false);
  }, capsStore.favoriteItems);

  function checkValidLand() {
    setCheckStatus(1);
    mainAPI.post('/primary/checkapi', { id: checkLandId, newBinom: binomVersion }).then((res) => {
      setCheckResult(res.data);
      setCheckStatus(2);
    });
  }
  const download = () => {
    downloadLand(checkLandId, binomVersion, setOpenBackdrop);
  };

  function updateToken() {
    if (updateTokenValue.length > 20) {
      mainAPI
        .post('/primary/updateaccs-token', { token: updateTokenValue })
        .then((res) => {
          if (res.status === 200) {
            setUpdateTokenDialog(false);
            setUpdateTokenValue(null);
            toast.success('Успешно');
          }
        })
        .catch((e) => {
          setUpdateTokenDialog(false);
          setUpdateTokenValue(null);
          toast.error('ошибка');
        });
    }
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1500 }} open={openBackdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Typography variant='h4' style={{ textAlign: 'left', marginLeft: 50 }}>
        Главная
      </Typography>
      {/* <Container sx={{ height: "100%", display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", margin: "0 20px" }}
            onClick={setCheckLand}
          >
            Проверить/скачать преленд
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none", margin: "0 20px" }}
            onClick={setCampaignModal}
          >
            Проверить кампанию (Новый бином)
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none", margin: "0 20px" }}
            onClick={setUnicModal}
          >
            Уникализатор
          </Button>

          <Button variant="contained" sx={{textTransform: 'none', margin: "0 20px"}} onClick={setUnicDialog}>Уникализатор</Button>
        </Box>
      </Container> */}
      <Dialog
        open={checkLand}
        onClose={() => {
          setCheckResult({});
          setCheckLand(false);
          setCheckLandId(null);
          setCheckStatus(0);
        }}
        aria-labelledby='watchtoken-title'
        aria-describedby='watchtoken-description'
      >
        <DialogTitle id='watchtoken-slide-title'>{'Проверка Pixel API  в преленде'}</DialogTitle>
        <DialogContent sx={{ minWidth: '300px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <TextField
              autoFocus={true}
              placeholder='ID преленда'
              value={checkLandId}
              onChange={(e) => {
                setCheckLandId(e.target.value);
              }}
              sx={{ textAlign: 'center', marginBottom: '20px' }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  checkValidLand();
                }
              }}
              fullWidth
            />
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography>Кейтаро</Typography>
              <Switch
                checked={binomVersion}
                onChange={(e) => {
                  setBinomVersion(e.target.checked);
                }}
              />
              <Typography>Enchantsuccess</Typography>
            </Stack>
            <div
              style={{
                display: 'flex',
                width: '100%',
                margin: '10px 0',
                justifyContent: 'space-between',
              }}
            >
              {binomVersion ? (
                <Button variant='outlined' disabled={!checkLandId} onClick={checkValidLand}>
                  Проверить
                </Button>
              ) : null}
              <Button variant='outlined' disabled={!checkLandId} onClick={download}>
                Скачать
              </Button>
            </div>
            {checkStatus == 2 ? (
              <>
                <Typography variant='h5' style={{ margin: '20px 0' }}>
                  {checkResult.landName}
                </Typography>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>Корректный offer_link </Typography>
                  {checkResult.offerLink ? <CheckIcon color='primary' /> : <CloseIcon color='secondary' />}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>FB Pixel </Typography>
                  {checkResult.fbPixel ? <CheckIcon color='primary' /> : <CloseIcon color='secondary' />}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>API Code </Typography>
                  {checkResult.ourPixel ? <CheckIcon color='primary' /> : <CloseIcon color='secondary' />}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>API Code V3</Typography>
                  {checkResult.apiV3 ? <CheckIcon color='primary' /> : <CloseIcon color='secondary' />}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>Function API Code V3</Typography>
                  {checkResult.apiV3func ? <CheckIcon color='primary' /> : <CloseIcon color='secondary' />}
                </div>
              </>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => {
              setCheckResult({});
              setCheckLand(false);
              setCheckStatus(0);
              setCheckLandId(null);
            }}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <CheckCampaignModal
        open={campaignModal}
        close={() => {
          setCampaignModal(false);
        }}
      />
      <UnicModal
        open={unicModal}
        close={() => {
          setUnicModal(false);
        }}
      />
      <Dialog
        open={updateTokenDialog}
        onClose={() => {
          setUpdateTokenValue(null);
          setUpdateTokenDialog(false);
        }}
        aria-labelledby='watchtoken-title'
        aria-describedby='watchtoken-description'
      >
        <DialogTitle id='watchtoken-slide-title'>Смена токена</DialogTitle>
        <DialogContent sx={{ minWidth: '300px', display: 'flex', flexDirection: 'column' }}>
          <TextField
            autoFocus={true}
            multiline
            placeholder={
              'EAABsbCS1iHgBAGCZA4FcHMl7BUL9T9MTSypwiGJZAhLyKtcZAxuDBNRWisrWpQST1EWyLaUDt1CdIKL4f8G8LQ7HVn2bYTZAYNxBF7g8rK6lH8U7UEAPELgUZAxpHS1o8k4uMZCpvgopQNOF5pNwywAbd1qTJfXoQGFmIfe0n1KgZDZD'
            }
            value={updateTokenValue}
            onChange={(e) => {
              setUpdateTokenValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                updateToken();
              }
            }}
            style={{ width: '100%', margin: '20px 0' }}
          />
          <Button onClick={() => updateToken()}>Send</Button>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined'>отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default observer(Home);
