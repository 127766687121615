import { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { Container, Tabs, Tab, Typography } from '@mui/material';

import { StreamsAdspect } from './Tabs';

function Cloaker() {
  useEffect(() => {
    document.title = '2leads Dev - Клоакер';
  }, []);

  const [activeTab, setActiveTab] = useState('adspect');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Typography variant='h4' style={{ textAlign: 'left', marginLeft: 50, marginBottom: 20 }}>
        Клоакер
      </Typography>

      <Container>
        <Tabs value={activeTab} onChange={handleChange} aria-label='Cloaker tabs'>
          <Tab label='Adspect' value='adspect' />
        </Tabs>

        {
          {
            adspect: <StreamsAdspect />,
          }[activeTab]
        }
      </Container>
    </>
  );
}

export default observer(Cloaker);
