import * as React from "react";
import { Grid, TextField } from "@mui/material";
import DropWrapper from "../DropWrapper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { CRMContext } from "..";
import Column from "../Column";
import Task from "../Task";

import editingIcon from "../../../assets/images/editingIcon.svg";
import sortingIcon from "../../../assets/images/sortingIcon.svg";

const ColorButton = styled(Button)(() => ({
  width: "100%",
  color: "#00FF6F",
  background: "rgba(0, 0, 0, 0.5)",
  position: "absolute",
  bottom: "0px",
  padding: "0.5rem 0rem",
  fontSize: "1.4rem",
  "&:hover": {
    color: "black",
  },
}));

const SingleDesk = () => {
  const { crmStore } = React.useContext(CRMContext);

  const [colName, setColName] = React.useState("");
  const [toggleInput, setToggleInput] = React.useState("");
  const [collapsedColumns, setCollapsedColumns] = React.useState(() => {
    const storedColumns = localStorage.getItem("collapsedColumns");
    return storedColumns ? JSON.parse(storedColumns) : [];
  });

  const [columnSort, setColumnSort] = React.useState(
    crmStore.currentProject.columns.map((column) => {
      return { id: column.id, reversed: false };
    })
  );
  const [sortUp, setSortUp] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropdown = Boolean(anchorEl);
  const handleDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    let newColumns = crmStore.currentProject.columns.map((column) => {
      return { id: column.id, reversed: false };
    });
    setColumnSort(newColumns);
  }, [crmStore.currentProject.columns.length]);

  React.useEffect(() => {
    localStorage.setItem("collapsedColumns", JSON.stringify(collapsedColumns));
  }, [collapsedColumns]);

  React.useEffect(() => {
    console.log(collapsedColumns);
  }, [collapsedColumns]);


  async function onDrop(item, monitor, columnId) {
    const mapping = crmStore.currentProject.columns.find(
      (si) => si.id === columnId
    );
    const newTasksData = crmStore.currentProject.tasks
      .slice()
      .sort((a, b) => a.sortDate - b.sortDate)
      .filter((i) => i.id !== item.id)
      .concat({ ...item, status: columnId, icon: mapping.icon });

    console.log("change");
    await crmStore.setTasks(newTasksData, crmStore.currentProject.id);
  }

  // async function moveItem(dragIndex, hoverIndex) {
  //   const item = crmStore.currentProject.tasks[dragIndex];
  //   const newTasksData = crmStore.currentProject.tasks.filter(
  //     (i, idx) => idx !== dragIndex
  //   );
  //   newTasksData.splice(hoverIndex, 0, item);
  //   await crmStore.setTasks(newTasksData, crmStore.currentProject.id);
  // }

  const handleChangeColumnNameInput = (newColumnName) => {
    setColName(newColumnName);
  };

  const collapseColumn = (id) => {
    setCollapsedColumns([...collapsedColumns, id]);
  };

  const expandColumn = (id) => {
    setCollapsedColumns(collapsedColumns.filter(item => item !== id));
  };

  const handleBack = () => {
    crmStore.currentProject.columns.forEach((col) => {
      if (col.id === toggleInput) {
        setColName(col.columnName);
        setToggleInput(!toggleInput);
      }
    });
  };

  async function handleChangeColumnName() {
    await crmStore.changeColumnName(toggleInput, colName);
  }

  React.useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Escape") {
        handleBack();
      } else if (event.key === "Enter") {
        await handleChangeColumnName();
        setToggleInput(!toggleInput);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [colName]);

  return (
    <Grid
      container
      sx={{
        marginTop: "4rem",
        display: "flex",
        flexWrap: "nowrap",
        height: "85%",
        position: "absolute",
      }}
    >
      {crmStore.currentProject.columns.map((column, index) => (
        <Grid
          item
          xs={3}
          key={column.id}
          sx={{
            background: "rgba(0, 0, 0, 0.8)",
            flexBasis: "345px",
            minWidth: collapsedColumns.includes(column.id) ? "min-content !important" : "300px !important",
            maxWidth: collapsedColumns.includes(column.id) ? "min-content !important" : "310px !important",
            marginRight: "25px",
            borderRadius: collapsedColumns.includes(column.id) ? "20px" : "20px 20px 0 0",
            marginBottom: "12px",
            height: collapsedColumns.includes(column.id) ? "68px" : "100%",
            flexShrink: "0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px",
              height: collapsedColumns.includes(column.id) ? "68px" : "auto"
            }}
          >
            {toggleInput === column.id ? (
              <>
                <IconButton
                  onClick={async () => {
                    await handleChangeColumnName();
                    setToggleInput(!toggleInput);
                  }}
                >
                  <CheckIcon />
                </IconButton>
                <TextField
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.25rem",
                    padding: "0.5rem 0rem",
                  }}
                  inputProps={{
                    style: { fontSize: "1.25rem", textAlign: "center" },
                  }}
                  value={colName}
                  variant="standard"
                  size="medium"
                  onChange={(e) => handleChangeColumnNameInput(e.target.value)}
                />
              </>
            ) : (
              <>
                <IconButton onClick={() => {
                  collapsedColumns.includes(column.id)
                    ? expandColumn(column.id)
                    : collapseColumn(column.id);
                }}>
                  <img src={editingIcon} alt="editingIcon" />
                </IconButton>
                <h2
                  style={{
                    margin: "0",
                    fontSize: "1rem",
                    textAlign: "center",
                    cursor: crmStore.currentProject.admins.includes(
                      crmStore.user
                    )
                      ? "pointer"
                      : "unset",
                  }}
                  onClick={() => {
                    if (
                      crmStore.currentProject.admins.includes(crmStore.user)
                    ) {
                      setToggleInput(column.id);
                      setColName(column.columnName);
                    }
                  }}
                >
                  {column.columnName}
                </h2>
              </>
            )}
            <IconButton
              id="fade-button"
              aria-controls="fade-menu"
              aria-haspopup="true"
              aria-expanded={openDropdown ? "true" : undefined}
              sx={{ display: collapsedColumns.includes(column.id) ? "none" : "inline-flex" }}
              onClick={(e) => {
                handleDropdown(e);
                setSortUp(column.id);
              }}
            >
              <img src={sortingIcon} alt="sortingIcon" />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={openDropdown}
              onClose={() => {
                handleCloseDropdown();
              }}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  let newColumnSort = columnSort.map((col) => {
                    if (col.id === sortUp) {
                      col.reversed = false;
                      return col;
                    }
                    return col;
                  });
                  setColumnSort(newColumnSort);
                  handleCloseDropdown();
                }}
              >
                <ListItemIcon>
                  <ArrowCircleDownIcon />
                </ListItemIcon>
                Сначала старые
              </MenuItem>
              <MenuItem
                onClick={() => {
                  let newColumnSort = columnSort.map((col) => {
                    if (col.id === sortUp) {
                      col.reversed = true;
                      return col;
                    }
                    return col;
                  });
                  setColumnSort(newColumnSort);
                  handleCloseDropdown();
                }}
              >
                <ListItemIcon>
                  <ArrowCircleUpIcon />
                </ListItemIcon>
                Сначала новые
              </MenuItem>
              {crmStore.currentProject.admins.includes(crmStore.user) ? (
                <MenuItem
                  onClick={() => {
                    crmStore.deleteColumn(sortUp);
                    handleCloseDropdown();
                  }}
                >
                  <ListItemIcon>
                    <CancelIcon />
                  </ListItemIcon>
                  Удалить колонку
                </MenuItem>
              ) : null}
            </Menu>
          </div>
          <DropWrapper onDrop={onDrop} columnId={column.id} displayBlock={collapsedColumns.includes(column.id) ? "none" : "block"}>
            <Column>
              {crmStore.currentProject.tasks
                .slice()
                .filter((item) => item.status === column.id)
                .sort((a, b) => {
                  if (
                    columnSort[index].id === a.status &&
                    columnSort[index].reversed === true
                  ) {
                    return b.sortDate - a.sortDate;
                  } else {
                    return a.sortDate - b.sortDate;
                  }
                })
                .map((item, index) => (
                  <Task
                    key={item.id}
                    item={item}
                    index={index}
                    // moveItem={null}
                    status={column}
                  />
                ))}
            </Column>
          </DropWrapper>
          <ColorButton
            sx={{ display: collapsedColumns.includes(column.id) ? "none" : "block"}}
            onClick={async () => {
              await crmStore.createNewTask(
                column.id,
                column.icon,
                crmStore.currentProject.id
              );
            }}
            variant="contained"
          >
            +
          </ColorButton>
        </Grid>
      ))}
      {crmStore.currentProject.admins.includes(crmStore.user) ? (
        <Button
          onClick={() => crmStore.createNewСolumn()}
          sx={{
            background: "#fff",
            flexBasis: "345px",
            maxWidth: "8%",
            opacity: "0.5",
            marginRight: "25px",
            borderRadius: "15px",
            marginBottom: "12px",
            flexShrink: "0",
            height: "100%",
            position: "relative",
            fontSize: "xx-large",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              color: "#fff",
            },
          }}
        >
          +
        </Button>
      ) : null}
    </Grid>
  );
};

export default observer(SingleDesk);
