import React from 'react';
import './style.scss';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

const TagRow = ({ editCb, deleteCb, data }) => {
  return (
    <div className='g-tag-row'>
      <Chip
        size='small'
        style={data?.color ? { background: data.color, fontSize: '16px' } : { fontSize: '16px' }}
        label={data?.label || ''}
      />
      <div className='actions'>
        <IconButton onClick={() => editCb(data)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={deleteCb}>
          <DeleteIcon color='error' />
        </IconButton>
      </div>
    </div>
  );
};
export default TagRow;
