import { types } from "mobx-state-tree";
import { toJS } from "mobx";

const farmUser = types.model("farmUser", {
  id: types.string,
  login: types.string,
});
const cardsUser = types.model("cardsUser", {
  id: types.string,
  login: types.string,
});
const appStore = types
  .model("appStore", {
    isAuth: types.boolean,
    userId: types.maybe(types.string),
    appTheme: types.maybe(types.string),
    userLogin: types.maybe(types.string),
    userRoles: types.maybe(types.array(types.string)),
    farmUsers: types.maybe(types.array(farmUser)),
    cardsUsers: types.maybe(types.array(cardsUser)),
  })
  .actions((self) => ({
    setIsAuth(value) {
      self.isAuth = value;
    },
    setAppTheme(theme) {
      self.appTheme = theme;
    },
    setUserLogin(login) {
      self.userLogin = login;
    },
    setUserId(id) {
      self.userId = id;
    },
    setUserRoles(roles) {
      self.userRoles = roles;
    },
    setFarmUsers(users) {
      self.farmUsers = users.sort((a, b) => a < b);
    },
    setCardsUsers(users) {
      self.cardsUsers = users.sort((a, b) => a < b);
    },
  }))
  .views((self) => ({
    getTheme() {
      return self.appTheme;
    },
    getUserId() {
      return self.userId;
    },
    getUserRoles() {
      return self.userRoles.toJSON();
    },
    getUserName() {
      return self.userLogin;
    },
    getFarmUsers() {
      return toJS(self.farmUsers);
    },
    getCardsUsers() {
      return toJS(self.cardsUsers);
    },
  }));

export default appStore;
