import { toast } from "react-toastify";
import downloadAPI from "./downloadAPI";
import mainAPI from "./mainAPI";
import FileDownload from "js-file-download";

const downloadLand = async (id, binomBoolean, setModal) => {
  const binom_version = binomBoolean ? "new" : "keitaro";
  setModal(true);
  try {
    if (binom_version === "new") {
      const name = await mainAPI.post("/primary/get-name", {
        id,
        binom_version,
      });
      mainAPI
        .post(
          "/primary/download",
          { url: name.data, binom_name: binom_version },
          { responseType: "blob" }
        )
        .then((res) => {
          setModal(false);
          FileDownload(res.data, `${id}.zip`);
          toast.success("Успешно!");
        })
        .catch((e) => {
          setModal(false);
          if (e.response?.status == 403) {
            toast.error("Ошибка доступа");
          } else {
            toast.error("Непредвиденная ошибка, попробуй еще раз");
          }
          console.log(e);
        });
    } else {
      mainAPI
        .post("/primary/downloadkeitaro", { id: id }, { responseType: "blob" })
        .then((res) => {
          setModal(false);
          console.log(res);
          FileDownload(res.data, `${id}.zip`);
          toast.success("Успешно!");
        })
        .catch((e) => {
          setModal(false);
          if (e.response?.status == 403) {
            toast.error("Ошибка доступа");
          } else {
            toast.error("Непредвиденная ошибка, попробуй еще раз");
          }
          console.log(e);
        });
    }
  } catch (error) {
    setModal(false);
    toast.error("Непредвиденная ошибка, попробуй еще раз");
  }
};
export default downloadLand;
