import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';

import GoogleService from '../../../services/GoogleService';

export const SelectVerificationDialog = ({ open, closeCb, accId }) => {
  const [value, setValue] = useState();

  async function updateAccountVerif() {
    try {
      await GoogleService.updateAccountVerification(accId, value);
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>Выберите статус верификации</DialogTitle>
      <DialogContent>
        <Select value={value} onChange={(e) => setValue(e.target.value)} style={{ minWidth: 200 }}>
          <MenuItem value={1}>Верифицирован</MenuItem>
          <MenuItem value={0}>Не верифицирован</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCb()} color='secondary'>
          Отменить
        </Button>
        <Button onClick={updateAccountVerif} color='primary'>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
