import { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';

/**
 *
 * @param {open: boolean} param0
 * @param {onClose: () => void} param1
 * @param {contractorsList: { _id: string, name: string }[]} param2
 * @param {tagsList: { _id: string, name: string }[]} param3
 * @param {onApplyFilters: () => void} param4
 * @returns
 */
export const FilterDialog = ({ open, onClose, contractorsList, tagsList, onApplyFilters }) => {
  const [contractor, setContractor] = useState('');
  const [tags, setTags] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [balanceFrom, setBalanceFrom] = useState('');
  const [balanceTo, setBalanceTo] = useState('');

  const handleApplyFilters = () => {
    onApplyFilters({
      contractor,
      tags,
      dateFrom,
      dateTo,
      balanceFrom,
      balanceTo,
    });
    onClose();
  };

  const resetFilters = () => {
    setContractor('');
    setTags('');
    setDateFrom('');
    setDateTo('');
    setBalanceFrom('');
    setBalanceTo('');
    onApplyFilters({
      contractor: '',
      tags: '',
      dateFrom: '',
      dateTo: '',
      balanceFrom: '',
      balanceTo: '',
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Фильтры</DialogTitle>
      <Button onClick={resetFilters} sx={{ position: 'absolute', top: 10, right: 10 }}>
        Сбросить
      </Button>
      <DialogContent>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id='contractor-label'>Подрядчик</InputLabel>
          <Select labelId='contractor-label' value={contractor} onChange={(e) => setContractor(e.target.value)}>
            {contractorsList.map((contractor) => (
              <MenuItem key={contractor._id} value={contractor._id}>
                {contractor.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id='tag-label'>Теги</InputLabel>
          <Select labelId='tag-label' value={tags} onChange={(e) => setTags(e.target.value)}>
            {tagsList.map((tag) => (
              <MenuItem key={tag._id} value={tag._id}>
                {tag.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <TextField
              label='Дата от'
              type='date'
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Дата до'
              type='date'
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <TextField
              label='Баланс от'
              type='number'
              fullWidth
              value={balanceFrom}
              onChange={(e) => setBalanceFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Баланс до'
              type='number'
              fullWidth
              value={balanceTo}
              onChange={(e) => setBalanceTo(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button variant='contained' onClick={handleApplyFilters}>
          Применить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
