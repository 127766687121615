function customSort(a, b, direction) {
  if (direction) {
    if (a.label > b.label) {
      return -1;
    }
    if (a.label < b.label) {
      return 1;
    }
  } else {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
  }
}

export default customSort;
