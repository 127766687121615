import axios from "axios";
import { observer } from "mobx-react-lite";

const downloadAPI = axios.create({
  baseURL: "https://flowlatvia.com:3999",
  // baseURL: "http://localhost:3999",
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
  },
  withCredentials: true,
});

export default observer(downloadAPI);
