import { useState } from 'react';

import GoogleService from '../../../services/GoogleService';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

export const SelectEmailDialog = ({ open, closeCb, accId, email }) => {
  const [value, setValue] = useState(email);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  async function updateEmail() {
    if (!validateEmail(value)) {
      setError(true);
      setErrorMessage('Введите корректный e-mail');
      return;
    }

    try {
      await GoogleService.updateEmail(accId, value.trim());
      closeCb();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);

    if (validateEmail(inputValue)) {
      setError(false);
      setErrorMessage('');
    }
  };

  return (
    <Dialog open={open} onClose={() => closeCb()}>
      <DialogTitle>E-mail</DialogTitle>
      <DialogContent>
        <TextField
          value={value}
          onChange={handleChange}
          error={error}
          helperText={errorMessage}
          fullWidth
          label='E-mail'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCb()}>Отменить</Button>
        <Button onClick={updateEmail} disabled={!value.trim()}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
