import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import mainAPI from "../../../utils/mainAPI";
import { toast } from "react-toastify";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FaqTagModal from "./FaqTagModal";
function FaqSettings({
  open,
  close,
  reloadTags,
  categories,
  setCategories,
  tags,
  setTags,
}) {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [addCategoryRow, setAddCategoryRow] = useState(false);
  const [openTagColorDialog, setOpenTagColorDialog] = useState(false);
  async function saveNewCategory() {
    try {
      const response = await mainAPI.post("/faq/category", {
        name: newCategoryName,
      });
      setCategories(response.data);
      setAddCategoryRow(false);
      setNewCategoryName("");
    } catch (e) {
      toast.error(e.response.data);
    }
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Настройки</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex" }}>
          <div>
            <Card
              sx={{ p: 2, minHeight: 300, minWidth: 200 }}
              title="Категории"
            >
              <Typography sx={{ mb: 2 }}>Текущие категории:</Typography>
              {categories.map((i) => (
                <div>{`${i.name}  - ${i.articles.length}`}</div>
              ))}
            </Card>
            {addCategoryRow ? (
              <div>
                <TextField
                  sx={{ mt: 2 }}
                  value={newCategoryName}
                  size="small"
                  onChange={(e) => setNewCategoryName(e.target.value)}
                />
                <Button
                  disabled={!newCategoryName.length}
                  sx={{ mt: 2 }}
                  onClick={saveNewCategory}
                >
                  Сохранить
                </Button>
              </div>
            ) : (
              <Button sx={{ mt: 2 }} onClick={() => setAddCategoryRow(true)}>
                +
              </Button>
            )}
          </div>
          <div style={{ marginLeft: "20px" }}>
            <Card
              sx={{ p: 2, minHeight: 300, minWidth: 200 }}
              title="Категории"
            >
              <Typography sx={{ mb: 2 }}>Тэги</Typography>
              <div>
                {tags.map((i) => {
                  return (
                    <div>
                      <Chip
                        style={{ background: i.color, marginBottom: "10px" }}
                        label={i.title}
                        size="small"
                      />
                    </div>
                  );
                })}
              </div>
            </Card>

            <Button sx={{ mt: 2 }} onClick={() => setOpenTagColorDialog(true)}>
              +
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>Закрыть</Button>
      </DialogActions>
      <FaqTagModal
        reloadTags={reloadTags}
        openAddTag={openTagColorDialog}
        handleCloseAddTag={() => setOpenTagColorDialog(false)}
      />
    </Dialog>
  );
}

export default FaqSettings;
