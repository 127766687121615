import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, Checkbox, Chip, IconButton, TableCell, TableRow } from '@mui/material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import SelectTagsDialog from './SelectTagsDialog';
import EditNoteDialog from './EditNoteDialog';
import EditSocialDialog from './EditSocialDialog';
import SelectBuyerDialog from './SelectBuyerDialog';
import SelectContractorDialog from './SelectContractorDialog';
import { SelectEmailDialog } from './SelectEmailDialog';
import { SelectTopupDialog } from './SelectTopupDialog';
import { SelectVerificationDialog } from './SelectVerificationDialog';
import { EnabledDisabledIcon } from '../GoogleIcons';

import './style.scss';

function formatDate(data) {
  const date = new Date(data);
  const formatMap = {
    mm: date.getMonth() + 1,
    dd: date.getDate(),
    yy: date.getFullYear().toString().slice(-2),
    yyyy: date.getFullYear(),
  };
  const result = formatMap.yyyy + '-' + formatMap.mm + '-' + formatMap.dd;

  return result;
}

/**
 *
 * @param {data: {
 *  _id: string,
 *  name: string,
 *  archived: boolean,
 *  spent: number,
 *  buyerId?: string,
 *  contractorId?: string,
 *  date: string,
 *  enabled: boolean,
 *  note: string,
 *  social?: string,
 *  tags: string[],
 *}[]} param0
 * @param { tags: Object}  param1
 * @param { refreshAccs: Object}  param2
 * @param { adminMode: Object}  param3
 * @param { buyers: Object}  param4
 * @param { contractors: Object}  param5
 * @param { onSelect: (accounts: Object) => void}  param6
 * @param { selectedRows: []}  param7
 * @returns
 */
const AccountRow = ({ data, tags, refreshAccs, adminMode, buyers, contractors, onSelect, selectedRows }) => {
  const [tagsDialog, setTagsDialog] = useState(false);
  const [noteDialog, setNoteDialog] = useState(false);
  const [socialDialog, setSocialDialog] = useState(false);
  const [buyerDialog, setBuyerDialog] = useState(false);
  const [topupDialog, setTopupDialog] = useState(false);
  const [contractorDialog, setContractorDialog] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [verificationDialog, setVerificationDialog] = useState(false);

  const [rowStyle, setRowStyle] = useState({});

  const getRowStyle = (data) => {
    if (!data.enabled) {
      return { background: '#ff000062' };
    }

    if (!(data.social && data.buyerId && data.contractorId)) {
      return { background: '#013618' };
    }

    return {};
  };

  useEffect(() => {
    const style = getRowStyle(data);
    setRowStyle(style);
  }, [data]);

  const renderSocial = (social) => {
    if (!adminMode) {
      return social;
    }

    return <Chip onClick={() => setSocialDialog(true)} className='g-acc-chip' size='small' label={social || '+Add'} />;
  };

  const renderTags = (items) => {
    const tagElements = items.map((tag) => {
      const dbTag = tags.find((i) => i._id === tag);
      return dbTag ? (
        <Chip
          key={dbTag._id}
          size='small'
          style={{ background: dbTag.color || '#fff' }}
          label={dbTag.label}
          className='g-acc-chip'
        />
      ) : null;
    });

    tagElements.push(
      <Chip key='add-tag' onClick={() => setTagsDialog(true)} size='small' label='+' className='g-acc-chip' />
    );
    return <div style={{ display: 'flex', alignItems: 'center' }}>{tagElements}</div>;
  };

  const renderNote = (note) => {
    if (adminMode) {
      return (
        <IconButton onClick={() => setNoteDialog(true)}>
          {note ? <MarkChatUnreadIcon /> : <AddCommentIcon />}
        </IconButton>
      );
    }
    return note ? (
      <p style={{ cursor: 'pointer' }} onClick={() => setNoteDialog(true)}>
        {note.slice(0, 40) + '...'}
      </p>
    ) : (
      <IconButton onClick={() => setNoteDialog(true)}>
        <AddCommentIcon />
      </IconButton>
    );
  };

  const renderBuyer = (id) => {
    const buyer = buyers.find((i) => i._id === id)?.label;

    if (!adminMode) {
      return buyer || null;
    }

    return <Chip onClick={() => setBuyerDialog(true)} className='g-acc-chip' size='small' label={buyer || '+Add'} />;
  };

  const renderContractor = (id) => {
    const contractor = contractors.find((i) => i._id === id)?.label || null;

    if (!adminMode) {
      return contractor || null;
    }

    return (
      <Chip
        onClick={() => setContractorDialog(true)}
        className='g-acc-chip'
        size='small'
        label={contractor || '+Add'}
      />
    );
  };

  const renderTopup = (topup) => {
    if (!adminMode) {
      return topup ?? null;
    }

    return <Chip onClick={() => setTopupDialog(true)} className='g-acc-chip' size='small' label={topup ?? '+Add'} />;
  };

  const renderBalance = (topup = 0, spent = 0) => {
    const topupValue = parseFloat(topup) || 0;
    const spendValue = parseFloat(spent) || 0;
    const balance = topupValue - spendValue;

    return balance.toFixed(2);
  };

  const renderEmail = (email) => {
    if (!adminMode) {
      return email || null;
    }

    return <Chip onClick={() => setEmailDialog(true)} className='g-acc-chip' size='small' label={email || '+Add'} />;
  };

  const renderVerification = (verification) => {
    return (
      <Chip
        onClick={() => {
          adminMode && setVerificationDialog(true);
        }}
        className='g-acc-chip'
        label={verification ? 'Верифицирован' : 'Не верифицирован'}
        size='small'
        style={{
          border: `2px solid ${verification ? '#00FF6F' : '#FFF500'}`,
          background: 'transparent',
          fontSize: '12px',
          padding: '5px',
        }}
      />
    );
  };

  const closeDialogs = async () => {
    try {
      await refreshAccs({});
      setTagsDialog(false);
      setNoteDialog(false);
      setSocialDialog(false);
      setBuyerDialog(false);
      setContractorDialog(false);
      setTopupDialog(false);
      setEmailDialog(false);
      setVerificationDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(data.id)
      .then(() => {
        toast.success('Copied');
      })
      .catch((e) => {
        console.log(e);
        toast.error('Error');
      });
  };

  return (
    <>
      <TableRow style={rowStyle}>
        <TableCell>
          <Checkbox checked={selectedRows.some((row) => row._id === data._id)} onChange={() => onSelect(data)} />
        </TableCell>
        <TableCell>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                left: '-25%',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <EnabledDisabledIcon iconFillColor={data.enabled ? '#00FF6F' : '#FF0000'} />
            </Box>
            <p style={{ marginBottom: 0 }}>{data.name}</p>
            <p
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#666666',
                fontSize: '12px',
                marginTop: '0',
              }}
              onClick={() => handleCopy()}
            >
              {data.id}
              <ContentCopyIcon sx={{ ml: 1 }} fontSize='12px' color='#666666' />
            </p>
          </Box>
        </TableCell>
        <TableCell>{renderSocial(data.social)}</TableCell>
        {adminMode && <TableCell>{renderBuyer(data.buyerId)}</TableCell>}
        <TableCell>{renderContractor(data.contractorId)}</TableCell>
        <TableCell>{renderEmail(data.email)}</TableCell>
        <TableCell sx={{ textWrap: 'nowrap' }}>{formatDate(data.date)}</TableCell>
        <TableCell>{renderTopup(data.topup)}</TableCell>
        <TableCell>{renderBalance(data.topup, data.spent)}</TableCell>
        <TableCell>{renderNote(data.note)}</TableCell>
        <TableCell>{renderVerification(data.verification)}</TableCell>
        <TableCell>{renderTags(data.tags)}</TableCell>
      </TableRow>

      {tagsDialog && (
        <SelectTagsDialog accId={data._id} closeCb={closeDialogs} open={tagsDialog} tags={tags} userTags={data.tags} />
      )}
      {noteDialog && <EditNoteDialog accId={data._id} closeCb={closeDialogs} open={noteDialog} note={data.note} />}
      {socialDialog && (
        <EditSocialDialog accId={data._id} closeCb={closeDialogs} open={socialDialog} social={data.social} />
      )}
      {buyerDialog && <SelectBuyerDialog accId={data._id} closeCb={closeDialogs} open={buyerDialog} buyers={buyers} />}
      {topupDialog && (
        <SelectTopupDialog accId={data._id} closeCb={closeDialogs} open={topupDialog} topup={data.topup} />
      )}
      {emailDialog && (
        <SelectEmailDialog accId={data._id} closeCb={closeDialogs} open={emailDialog} email={data.email} />
      )}
      {verificationDialog && (
        <SelectVerificationDialog accId={data._id} closeCb={closeDialogs} open={verificationDialog} />
      )}
      {contractorDialog && (
        <SelectContractorDialog
          accId={data._id}
          closeCb={closeDialogs}
          open={contractorDialog}
          contractors={contractors}
        />
      )}
    </>
  );
};

export default AccountRow;
