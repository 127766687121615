/**
 *
 * @param {{ iconFillColor: string }} param0
 * @returns
 */
export const EnabledDisabledIcon = ({ iconFillColor }) => {
  return (
    <svg
      style={{
        width: 'min(12px, 100%)',
        minWidth: '12px',
        height: 'min(12px, 100%)',
      }}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8' r='8' fill={iconFillColor} />
    </svg>
  );
};

/**
 *
 * @param {{ type: 'edit' | 'export' | 'restore' | 'delete' }} param0
 * @param {{ color: string }} param1
 * @returns
 */
export const ActionIcons = ({ type, color }) => {
  const icons = {
    edit: (
      <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12 20H21' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
    export: (
      <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M7 10L12 15L17 10' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 15V3' stroke='#D88817' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    ),
    restore: (
      <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M15 3H21V9' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 14L21 3' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    ),
    delete: (
      <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M3 6H5H21' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M10 11V17' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14 11V17' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    ),
  };

  if (!icons[type]) {
    console.warn(`Icon type "${type}" not found`);
    return null;
  }
  return icons[type];
};
