import React from "react";
import "./style.scss";
import { Typography } from "@mui/material";

import { Draggable, Droppable } from "react-beautiful-dnd";
import Card from "../Card";
const CardsColumn = ({ status, data, refresh }) => {
  function switchStatus() {
    switch (status) {
      case "1":
        return "Активные";
      case "2":
        return "Заморожены";
      case "3":
        return "Запрос заморозить";
      case "4":
        return "Запрос разморозка";
      case "5":
        return "Бан/удалена";
      default:
        break;
    }
  }
  function drawCards() {
    return data
      .sort((a, b) => {
        if (a.number.slice(-4) > b.number.slice(-4)) {
          return 1;
        } else {
          return -1;
        }
      })
      .map((item, index) => {
        if (item.status === +status) {
          return (
            <Draggable key={item.name} draggableId={item._id} index={index}>
              {(provided) => (
                <div
                  key={"item_" + item.buyer_id + item.name}
                  index={index}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <Card refresh={refresh} data={item} />
                  {provided.placeholder}
                </div>
              )}
            </Draggable>
          );
        }
      });
  }
  return (
    <div className="cards_day">
      <Typography textAlign="center" className="card_title">
        {switchStatus(status)}
      </Typography>
      <div className="account_day_group card_group">
        <Droppable droppableId={"accounts_4_" + status}>
          {(provided) => (
            <div
              className="accounts_row"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {drawCards()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default CardsColumn;
