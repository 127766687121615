import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Login from "./components/Login";
import useStore from "./hooks/useStore";
import { observer } from "mobx-react-lite";
import Main from "./components/Main";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { toast, ToastContainer } from "react-toastify";
import mainAPI from "./utils/mainAPI";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#e3e3e3",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      dark: "#fff",
      main: "#72f584",
      light: "#fff",
    },

    secondary: {
      main: "#fffc3f",
    },
    background: {
      default: "#1e1e1e",
      paper: "#000000d1",
    },
  },
});

function App() {
  const { appStore, capsStore, fbStore } = useStore();
  const [currentTheme, setCurrentTheme] = useState(null);
  const [tokenChecked, setTokenChecked] = useState(false);
  useEffect(() => {
    capsStore.loadAllItems();

    appStore.setAppTheme("dark");
    setCurrentTheme("dark");
    const localToken = localStorage.getItem("FBtoken");

    if (localToken) {
      fbStore.setToken(localToken);
    }

    mainAPI
      .get("/primary/checktoken")
      .then((res) => {
        if (res.status === 200) {
          appStore.setIsAuth(true);
          appStore.setUserId(res.data.id);
          appStore.setUserLogin(res.data.login);
          appStore.setUserRoles(res.data.userRoles);
          setTokenChecked(true);
        } else {
          setTokenChecked(true);
        }
      })
      .catch((e) => {
        setTokenChecked(true);
      });
  }, [appStore.appTheme]);

  return (
    <Router>
      <ThemeProvider theme={currentTheme === "dark" ? darkTheme : lightTheme}>
        <CssBaseline />
        <div className="App">
          {tokenChecked ? appStore.isAuth ? <Main /> : <Login /> : null}

          <ToastContainer />
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default observer(App);
