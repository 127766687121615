import React from "react";
import "./style.scss";
import { Typography } from "@mui/material";
import Account from "../../Account";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
const AccountDay = ({ day, data, refresh }) => {
  function moveAccount(l) {
    console.log(l);
  }
  function drawDay() {
    switch (day) {
      case "1":
        return "Понедельник";
      case "2":
        return "Вторник";
      case "3":
        return "Среда";
      case "4":
        return "Четверг";
      case "5":
        return "Пятница";
      default:
        break;
    }
  }
  function drawActiveAccounts() {
    return data.map((item, index) => {
      if (item.day == day && item.status === 1) {
        return (
          <Draggable key={item.name} draggableId={item._id} index={index}>
            {(provided) => (
              <div
                key={"item_" + item.buyer_id + item.name}
                index={index}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Account refresh={refresh} data={item} />
              </div>
            )}
          </Draggable>
        );
      }
    });
  }
  function drawPendingAccounts() {
    return data.map((item, index) => {
      if (item.day == day && item.status === 2) {
        return (
          <Draggable key={item.name} draggableId={item._id} index={index}>
            {(provided) => (
              <div
                key={"item_" + item.buyer_id + item.name}
                index={index}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Account refresh={refresh} data={item} />
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      }
    });
  }
  function drawInactiveAccounts() {
    return data.map((item, index) => {
      if (item.day == day && item.status === 3) {
        return (
          <Draggable key={item.name} draggableId={item._id} index={index}>
            {(provided) => (
              <div
                key={"item_" + item.buyer_id + item.name}
                index={index}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Account refresh={refresh} data={item} />
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      }
    });
  }
  function drawUnwatchedAccounts() {
    return data.map((item, index) => {
      if (item.day == day && item.status === 4) {
        return (
          <Draggable key={item.name} draggableId={item._id} index={index}>
            {(provided) => (
              <div
                key={"item_" + item.buyer_id + item.name}
                index={index}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Account refresh={refresh} data={item} />
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      }
    });
  }
  return (
    <div className="account_day">
      <Typography variant="h5">{drawDay(day)}</Typography>
      <div className="account_day_group">
        <p>Не просмотрено баером</p>
        <Droppable droppableId={"accounts_4_" + day}>
          {(provided) => (
            <div
              className="accounts_row"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {drawUnwatchedAccounts()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <div className="account_day_group">
        <p>Активные</p>
        <Droppable droppableId={"accounts_1_" + day}>
          {(provided) => (
            <div
              className="accounts_row"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {drawActiveAccounts()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <div className="account_day_group">
        <p>На проверке</p>
        <Droppable droppableId={"accounts_2_" + day}>
          {(provided) => (
            <div
              className="accounts_row"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {drawPendingAccounts()}
            </div>
          )}
        </Droppable>
      </div>
      <div className="account_day_group">
        <p>Неактивные</p>
        <Droppable droppableId={"accounts_3_" + day}>
          {(provided) => (
            <div
              className="accounts_row"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {" "}
              {drawInactiveAccounts()}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default AccountDay;
