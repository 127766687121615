import axios from 'axios';

export default axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3323/control/'
      : 'https://2leads.dev/api/services/control/',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Expose-Headers': 'Set-Cookie',
    'Access-Control-Allow-Methods': 'GET, POST, DELETE',
  },
  withCredentials: true,
});
