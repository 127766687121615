import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import mainAPI from '../../utils/mainAPI';
import useStore from '../../hooks/useStore';

const Sms = () => {
  const [smsData, setSmsData] = useState([]);
  const { appStore } = useStore();
  function formatDate(data) {
    const date = new Date(data);
    const formatMap = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    };
    const result = formatMap.yyyy + '-' + formatMap.mm + '-' + formatMap.dd;

    return result;
  }
  useEffect(() => {
    if (appStore.getUserRoles().includes('sms_admin')) {
      mainAPI
        .get('/sms/all')
        .then((res) => {
          setSmsData(res.data.reverse());
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (appStore.getUserRoles().includes('sms_payoneer')) {
      mainAPI
        .get('/sms/payoneer')
        .then((res) => {
          setSmsData(res.data.reverse());
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <>
      <Typography variant='h5' textAlign='left'>
        SMS
      </Typography>
      <Paper sx={{ p: 2, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 100 }}>Date</TableCell>
              <TableCell style={{ width: 100 }}>Device</TableCell>
              <TableCell style={{ width: 100 }}>From</TableCell>
              <TableCell>Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smsData.map((i) => (
              <TableRow key={i._id}>
                <TableCell>{formatDate(i.createdAt)}</TableCell>
                <TableCell>{i.device}</TableCell>
                <TableCell>{i.from}</TableCell>
                <TableCell>{i.text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default Sms;
