import React, { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.scss";

import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import mainAPI from "../../../utils/mainAPI";
import { toast } from "react-toastify";
import useStore from "../../../hooks/useStore";
const Account = ({ data, refresh }) => {
  const [editDialogAccountNumber, setEditDialogAccountNumber] = useState("");
  const [editDialogAccountDay, setEditDialogAccountDay] = useState("");
  const [editDialogAccountType, setEditDialogAccountType] = useState("");
  const [editDialogOwnerName, setEditDialogOwnerName] = useState("");
  const [editDialogOwnerContact, setEditDialogOwnerContact] = useState("");
  const [editDialogIban, setEditDialogIban] = useState("");
  const [editDialogUser, setEditDialogUser] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const open = Boolean(anchorEl);
  const { appStore } = useStore();
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setEditDialogAccountNumber(data.name);
    setEditDialogAccountDay(data.day);
    setEditDialogAccountType(data.type);
    setEditDialogOwnerName(data.owner_name);
    setEditDialogOwnerContact(data.owner_contact);
    setEditDialogIban(data.iban);
    setEditDialogUser(data.buyer_id);
  }, []);
  function saveChanges() {
    const postData = {
      _id: data._id,
      buyer_id: editDialogUser,
      day: editDialogAccountDay,
      iban: editDialogIban,
      owner_name: editDialogOwnerName,
      owner_contact: editDialogOwnerContact,
      name: editDialogAccountNumber,
      type: editDialogAccountType,
    };
    mainAPI
      .post("/farm/updateaccount", { data: postData })
      .then((res) => {
        toast.success("Успешно");
        setEditDialog(false);
        refresh();
      })
      .catch((e) => {
        toast.error("Ошибка");
      });
  }
  function switchType() {
    switch (data.type) {
      case 1:
        return "TW ES";
      case 2:
        return "TW KE";
      case 3:
        return "PP KE";
      case 4:
        return "TW GE";
      default:
        break;
    }
  }
  function drawUserSelect() {
    return appStore.getFarmUsers()?.map((item) => {
      return (
        <MenuItem key={"user" + item.id} value={item.id}>
          {item.login}
        </MenuItem>
      );
    });
  }
  function deleteAccount() {
    mainAPI.delete("/farm/account?id=" + data._id).then((res) => {
      toast.success("Успешно");
      refresh();
      handleMenuClose();
    });
  }
  function getUserName() {
    const allUsers = appStore.getFarmUsers();
    const user = allUsers.filter((item) => item.id == data.buyer_id);
    return user[0]?.login || null;
  }
  return (
    <>
      <Tooltip title={data.time ? data.time : null}>
        <div
          onClick={
            appStore.getUserRoles().indexOf("farmer") !== -1
              ? handleMenuClick
              : null
          }
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className={
            data.status === 1
              ? "account_item account_item_active"
              : data.status === 2
              ? "account_item account_item_pending"
              : data.status === 3
              ? "account_item account_item_inactive"
              : "account_item account_item_unwatched"
          }
        >
          {data.name}
        </div>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <EditIcon
            onClick={() => setEditDialog(true)}
            style={{ height: "15px", margin: "0 5px", cursor: "pointer" }}
          />{" "}
          <p style={{ fontSize: "10px" }}>|</p>
          <DeleteIcon
            onClick={() => {
              deleteAccount();
            }}
            style={{
              height: "15px",
              margin: "0 5px",
              color: "red",
              cursor: "pointer",
            }}
          />
        </div>
        <MenuItem
          onClick={() => {
            handleMenuClose();
          }}
        >
          {data.name}
        </MenuItem>
        <MenuItem onClick={() => navigator.clipboard.writeText(switchType())}>
          {switchType()}
        </MenuItem>
        <MenuItem
          onClick={() => navigator.clipboard.writeText(data.owner_name)}
        >
          {data.owner_name}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(data.owner_contact);
          }}
        >
          {data.owner_contact}
        </MenuItem>
        <MenuItem onClick={() => navigator.clipboard.writeText(getUserName())}>
          {getUserName()}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(data.iban);
          }}
        >
          {data.iban}
        </MenuItem>
      </Menu>
      <Dialog
        open={editDialog}
        close={() => setEditDialog(false)}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">
          Редактирование аккаунта
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", paddingTop: "10px" }}>
            <TextField
              label="Номер"
              placeholder="1032"
              value={editDialogAccountNumber}
              onChange={(e) => setEditDialogAccountNumber(e.target.value)}
              style={{ width: "80px" }}
              size="small"
            />
            <FormControl style={{ width: "150px", marginLeft: "10px" }}>
              <InputLabel id="demo-simple-select-label">День</InputLabel>
              <Select
                value={editDialogAccountDay}
                onChange={(e) => setEditDialogAccountDay(e.target.value)}
                size="small"
              >
                <MenuItem value={1}>Понедельник</MenuItem>
                <MenuItem value={2}>Вторник</MenuItem>
                <MenuItem value={3}>Среда</MenuItem>
                <MenuItem value={4}>Четверг</MenuItem>
                <MenuItem value={5}>Пятница</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: "100px", marginLeft: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Тип аккаунта
              </InputLabel>
              <Select
                value={editDialogAccountType}
                onChange={(e) => setEditDialogAccountType(e.target.value)}
                size="small"
              >
                <MenuItem value={1}>TW ES</MenuItem>
                <MenuItem value={2}>TW KE</MenuItem>
                <MenuItem value={3}>PP KE</MenuItem>
                <MenuItem value={4}>TW GE</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            value={editDialogOwnerName}
            onChange={(e) => setEditDialogOwnerName(e.target.value)}
            label="Имя владельца"
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
          />
          <br />
          <TextField
            value={editDialogOwnerContact}
            onChange={(e) => setEditDialogOwnerContact(e.target.value)}
            label="Контакт владельца"
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
          />
          <br />
          <FormControl
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <InputLabel id="demo-simple-select-label">Пользователь</InputLabel>
            <Select
              value={editDialogUser}
              onChange={(e) => setEditDialogUser(e.target.value)}
            >
              {drawUserSelect()}
            </Select>
          </FormControl>
          <br />
          <TextField
            value={editDialogIban}
            onChange={(e) => setEditDialogIban(e.target.value)}
            label="IBAN / E-mail / Phone"
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => saveChanges()}>
            Сохранить
          </Button>
          <Button onClick={() => setEditDialog(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Account;
