import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GoogleService from '../../../../services/GoogleService';
import { SketchPicker } from 'react-color';

const TagDialog = ({ editModeData, open, closeAction }) => {
  const [label, setLabel] = useState('');
  const [color, setColor] = useState('#ffffff');

  useEffect(() => {
    setLabel(editModeData?.label?.trim() || '');
    setColor(editModeData?.color || '');
  }, [editModeData]);

  async function saveNewTag() {
    try {
      await GoogleService.createNewTag({ label: label.trim(), color });
      setLabel('');
      setColor('');
      closeAction();
    } catch (error) {
      console.log(error);
    }
  }

  async function updateTag() {
    try {
      await GoogleService.updateTag({ _id: editModeData._id, label: label.trim(), color });
      setLabel('');
      setColor('');
      closeAction();
    } catch (error) {
      console.log(error);
    }
  }

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  return (
    <Dialog open={open} onClose={() => closeAction()}>
      <DialogTitle>{editModeData.label ? 'Редактирование тега' : 'Добавление тега'}</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField label='Название' size='small' value={label} onChange={handleLabelChange} />
        <SketchPicker color={color} onChange={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <Button disabled={!label.trim().length} onClick={editModeData.label ? updateTag : saveNewTag}>
          {editModeData.label ? 'Обновить' : 'Сохранить'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagDialog;
