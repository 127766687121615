import React, { useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { SketchPicker } from "react-color";
import mainAPI from "../../../utils/mainAPI";
import { toast } from "react-toastify";

const FaqTagModal = ({ openAddTag, handleCloseAddTag, reloadTags }) => {
  const [tagName, setTagName] = useState("Новый таг");
  const [tagColor, setTagColor] = useState("#47A03B");

  const handleColorChange = (color) => {
    setTagColor(color.hex);
  };
  async function saveNewTag() {
    try {
      await mainAPI.post("/faq/tag", { title: tagName, color: tagColor });
      setTagColor("#fff");
      setTagName("Новый таг");
      reloadTags();
      handleCloseAddTag();
    } catch (error) {
      toast.error("err");
    }
  }
  return (
    <Dialog open={openAddTag} onClose={handleCloseAddTag}>
      <DialogTitle>
        Add Tag <br />
        <Chip style={{ background: tagColor }} label={tagName} />
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          size="small"
          label="Tag Name"
          fullWidth
          variant="standard"
          onChange={(e) => setTagName(e.target.value)}
        />
        <SketchPicker color={tagColor} onChange={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddTag}>Cancel</Button>
        <Button
          onClick={() => {
            saveNewTag();
          }}
          disabled={tagName.length === 0}
        >
          Add Tag
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaqTagModal;
