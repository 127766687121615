import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Box,
  Avatar,
  Button,
  Chip,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import SendIcon from '@mui/icons-material/Send';
import { observer } from 'mobx-react-lite';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Modal from 'react-modal';
import { CRMContext } from '..';

import style from '../../CRM/TaskModal2/style.scss'; // don't delete

import trashModal from '../../../assets/images/trashModal.svg';
import filesIcon from '../../../assets/images/filesIcon.svg';
import driveIcon from '../../../assets/images/driveIcon.svg';
import driveFiles from '../../../assets/images/driveFiles.svg';
import TagModal from './TagModal';
import DeleteModal from './DeleteModal';
import Comment from './Comment';
import Loader from '../../Loader';

Modal.setAppElement('#root');

const TaskModal2 = ({ item }) => {
  const { crmStore } = useContext(CRMContext);

  const [dragMedia, setDragMedia] = useState(false);

  const [saveText, setSaveText] = useState(false);

  const [saveLink, setSaveLink] = useState(false);

  const [files, setFiles] = useState(item.media);

  const [comment, setComment] = useState('');

  const [loading, setLoading] = useState(false);

  const [openAddTag, setOpenAddTag] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const [formData, setFormData] = useState({
    title: item.title,
    content: item.content,
    tag: item.tag,
    assignedTo: item.assignedTo,
    createdBy: item.createdBy,
    link: item.link,
    comments: item.comments ? item.comments : [],
    logs: item.logs ? item.logs : [],
  });

  const [pastedFile, setPastedFile] = useState(null);

  const fileSelectRef = useRef(null);

  useEffect(() => {
    setFormData({
      title: item.title,
      content: item.content,
      tag: item.tag,
      assignedTo: item.assignedTo,
      createdBy: item.createdBy,
      link: item.link,
      comments: item.comments ? item.comments : [],
      logs: item.logs ? item.logs : [],
    });
  }, []);

  useEffect(() => {
    const handlePaste = (e) => {
      const clipboardItems = Array.from(e.clipboardData.items);
      const lastItem = clipboardItems.find((item) => item.type.indexOf('image') !== -1);

      if (!lastItem) return;

      setLoading(true);
      const blob = lastItem.getAsFile();
      const newName = CyrillicToTranslit().transform(blob.name || 'pasted_image', '_');
      const modifiedFile = new File([blob], newName, { type: blob.type });
      setPastedFile(modifiedFile);
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        document.addEventListener('paste', handlePaste, { once: true });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!pastedFile) return;

    const uploadFile = async () => {
      try {
        await fileUpload({ target: { files: [pastedFile] }, preventDefault: () => {} });
      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
      } finally {
        setPastedFile(null);
        setLoading(false);
      }
    };

    uploadFile();
  }, [pastedFile]);

  useEffect(() => {
    setFiles(item.media);
  }, [item]);

  const handleSelectFiles = () => {
    fileSelectRef.current.click();
  };

  const handleOpenAddTag = () => {
    setOpenAddTag(true);
  };

  const handleCloseAddTag = () => {
    setOpenAddTag(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangeComment = (value) => {
    setComment(value);
  };

  const handleClearComments = () => {
    setComment('');
  };

  const handleChangeTags = (e, value) => {
    setFormData({ ...formData, tag: value });
  };

  const handleChangeAssign = (value) => {
    setFormData({ ...formData, assignedTo: value });
  };

  const handleChangeTitle = (value) => {
    setFormData({ ...formData, title: value });
  };

  const handleChangeContent = (value) => {
    setFormData({ ...formData, content: value });
  };

  const handleChangeLink = (value) => {
    setFormData({ ...formData, link: value });
  };

  const handleAddComment = () => {
    setFormData({
      ...formData,
      comments: [
        {
          id: `comment-${Math.random()}`,
          text: comment,
          date:
            ('0' + new Date().getHours()).slice(-2) +
            ':' +
            ('0' + new Date().getMinutes()).slice(-2) +
            ' ' +
            ('0' + new Date().getDate()).slice(-2) +
            '-' +
            ('0' + (new Date().getMonth() + 1)).slice(-2) +
            '-' +
            new Date().getFullYear(),
          user: crmStore.user,
        },
        ...formData.comments,
      ],
    });
    setComment('');
  };

  const dragStartHandler = (e) => {
    e.preventDefault();
    setDragMedia(true);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDragMedia(false);
  };

  const addTagsToProject = (tagName, tagColor) => {
    crmStore.changeProjectTags([
      ...crmStore.currentProject.tags,
      { id: `tag-${Math.random()}`, name: tagName, color: tagColor },
    ]);
  };

  const deleteTagFromProject = (id) => {
    crmStore.changeProjectTags(crmStore.currentProject.tags.filter((tag) => tag.id !== id));
  };

  async function deleteTask() {
    await crmStore.deleteTask(item.id, crmStore.currentProject.id);
    crmStore.setOpenModal(false);
  }

  const fixedOption = {
    id: 'fixed-option',
    name: '+ Create Tag',
    color: '#ff0000',
  };

  const availableTags = [
    ...crmStore.currentProject.tags.filter((tag) => !formData.tag.some((selectedTag) => selectedTag.id === tag.id)),
    fixedOption,
  ];

  async function onDropHandler(e) {
    e.preventDefault();
    let newFiles = Object.values(e.dataTransfer.files);
    let modifiedFiles = newFiles.map((file) => {
      const newName = CyrillicToTranslit().transform(file.name, '_');
      const modifiedFile = new File([file], newName, {
        type: file.type,
      });
      return modifiedFile;
    });
    // setFiles((prevState) => [...prevState, ...newFiles]);
    setDragMedia(false);
    await crmStore.changeTaskData(formData, item.id);
    await crmStore.uploadFiles(modifiedFiles, item.id);
  }

  async function fileUpload(e) {
    e.preventDefault();
    let newFiles = Object.values(e.target.files);
    let modifiedFiles = newFiles.map((file) => {
      const newName = CyrillicToTranslit().transform(file.name, '_');
      const modifiedFile = new File([file], newName, {
        type: file.type,
      });
      return modifiedFile;
    });
    // setFiles((prevState) => [...prevState, ...newFiles]);
    await crmStore.changeTaskData(formData, item.id);
    await crmStore.uploadFiles(modifiedFiles, item.id);
  }

  const httpsLink = /(https?:\/\/[^\s]+)/g;
  const httpLink = /(http?:\/\/[^\s]+)/g;

  return (
    <Modal
      isOpen={crmStore.openModal === item.id ? true : false}
      onRequestClose={async () => {
        setLoading(true);
        await crmStore.changeTaskData(formData, item.id);
        setLoading(false);
        crmStore.setOpenModal(false);
      }}
      className='modal'
      overlayClassName='overlay'
    >
      <div className='taskModalLeft'>
        <div className='taskModalName'>
          <TextField
            className='taskModalTitle bgInput'
            sx={{
              '& .MuiInput-underline:after': {
                borderBottom: 'none',
              },
              '& .MuiInput-underline:before': {
                borderBottom: 'none',
              },
            }}
            value={formData.title}
            disabled={!(crmStore.currentProject.admins.includes(crmStore.user) || crmStore.user === item.createdBy)}
            variant='standard'
            size='medium'
            name='title'
            onChange={(e) => handleChangeTitle(e.target.value)}
          />
          <img
            className='close-btn'
            onClick={() => {
              handleOpenDelete();
            }}
            src={trashModal}
            alt='trashModal'
          />
        </div>
        <div>
          <p className='taskModalId'>#{item.orderId}</p>
        </div>
        <h2 className='taskModalSelectPerson'>
          <>
            {/* {item.createdBy} */}
            <Select
              className='taskModalExecutor bgInput'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              variant='standard'
              MenuProps={{
                className: 'taskModalUserSelect',
                PaperProps: {
                  sx: { backgroundColor: '#121111ed', backgroundImage: 'none' },
                },
              }}
              disableUnderline
              disabled={
                !(
                  crmStore.currentProject.admins.includes(crmStore.user) ||
                  crmStore.user === item.createdBy ||
                  formData.assignedTo === 'all'
                )
              }
              value={formData.createdBy}
              // onChange={(e) => handleChangeAssign(e.target.value)}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={crmStore.user}>{crmStore.user}</MenuItem>
              {crmStore.userList.map((user) => {
                if (user.login === crmStore.user) {
                  return null;
                }
                if (crmStore.currentProject.admins.includes(crmStore.user) || crmStore.user === item.createdBy) {
                  return <MenuItem value={user.login}>{user.login}</MenuItem>;
                }
              })}
            </Select>
            <Autocomplete
              className='taskModalExecutor bgInputAutocomplete'
              disableClearable
              clearOnEscape
              sx={{
                '& .MuiInputBase-root:before': {
                  borderBottom: 'none',
                },
              }}
              PaperComponent={(props) => (
                <Paper {...props} sx={{ backgroundColor: '#121111ed', backgroundImage: 'none' }} />
              )}
              options={crmStore.userList.map((user) => {
                if (user.login === crmStore.user) {
                  return null;
                }
                if (crmStore.currentProject.admins.includes(crmStore.user) || crmStore.user === item.createdBy) {
                  return user.login;
                }
              })}
              getOptionLabel={(option) => option}
              value={formData.assignedTo}
              onChange={(e, value) => handleChangeAssign(value)}
              disabled={
                !(
                  crmStore.currentProject.admins.includes(crmStore.user) ||
                  crmStore.user === item.createdBy ||
                  formData.assignedTo === 'all'
                )
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{
                      '& .MuiInputBase-input': {
                        borderBottom: 'none',
                      },
                    }}
                    className='bgInputAutocompleteInput'
                    variant='standard'
                  />
                );
              }}
            />
          </>
        </h2>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginTop: '1.5rem',
          }}
        >
          <Autocomplete
            sx={{ minWidth: '300px' }}
            multiple
            fullWidth
            disableCloseOnSelect
            disableClearable
            clearOnEscape
            openOnFocus
            id='tags-standard'
            options={availableTags}
            getOptionDisabled={(option) => formData.tag.includes(option)}
            getOptionLabel={(option) => option.name}
            value={formData.tag}
            onChange={(e, value) => handleChangeTags(e, value)}
            renderOption={(props, option) => {
              console.log(option);
              if (option.id === 'fixed-option') {
                return (
                  <Button
                    {...props}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenAddTag();
                    }}
                    sx={{
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {option.name}
                  </Button>
                );
              }
              return (
                <li
                  {...props}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{option.name}</span>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTagFromProject(option.id);
                    }}
                  >
                    <img aria-label='delete' className='close-btn' src={trashModal} alt='trashModal' />
                  </IconButton>
                </li>
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.name}
                  label={option.name}
                  avatar={
                    <Avatar
                      sx={{
                        backgroundColor: option.color,
                        width: 20,
                        height: 20,
                      }}
                      children=''
                    />
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                size='large'
                label='Tags'
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                }}
                placeholder='Tags'
              />
            )}
          />
          {/* {crmStore.currentProject.admins.includes(crmStore.user) ? (
            <Button
              size="small"
              variant="text"
              sx={{ marginLeft: "1rem" }}
              onClick={() => handleOpenAddTag()}
            >
              +
            </Button>
          ) : null} */}
        </div>

        <div className='taskModalInfo'>
          <div className='taskModalDrive bgInput'>
            <img src={driveIcon} alt='driveIcon' />
            <p>ТЗ</p>
          </div>
          {saveLink ? (
            <TextField
              className='taskModalLink bgInput'
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottom: 'none',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: 'none',
                },
              }}
              variant='standard'
              size='medium'
              name='link'
              value={formData.link}
              onFocus={() => setSaveLink(true)}
              onBlur={() => setSaveLink(false)}
              onChange={(e) => handleChangeLink(e.target.value)}
            />
          ) : (
            <p className='taskModalLink bgInput' onClick={() => setSaveLink(!saveLink)}>
              {formData.link?.split('\n').map((word, index) => {
                if (httpsLink.test(word)) {
                  return (
                    <a key={index} href={word} target='_blank'>
                      {word}
                    </a>
                  );
                }
                if (httpLink.test(word)) {
                  return (
                    <a key={index} href={word} target='_blank'>
                      {word}
                    </a>
                  );
                }
                return <p>{word}</p>;
              })}
            </p>
          )}
        </div>
        {saveText ? (
          <TextField
            sx={{
              width: '100%',
              maxHeight: '300px',
              minHeight: '130px',
              overflow: 'scroll',
              padding: '0',
            }}
            onFocus={() => setSaveText(true)}
            onBlur={() => setSaveText(false)}
            className='bgInput'
            value={formData.content}
            multiline
            name='content'
            onChange={(e) => handleChangeContent(e.target.value)}
          />
        ) : (
          <p className='bgInput taskModaDescription' onClick={() => setSaveText(!saveText)}>
            {formData.content.split('\n').map((word, index) => {
              if (httpsLink.test(word)) {
                return (
                  <a key={index} href={word} target='_blank'>
                    {word}
                  </a>
                );
              }
              return <p>{word}</p>;
            })}
          </p>
        )}
        <div
          style={{
            maxHeight: '160px',
            overflow: 'scroll',
            marginTop: '1rem',
            position: 'relative',
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color='success' />
            </Box>
          ) : (
            files.map((file, index) => (
              <ul className='taskModalFiles bgInput'>
                <div className='taskModalFilesRow'>
                  {file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' ? (
                    <a
                      href={
                        process.env.NODE_ENV === 'development'
                          ? `http://localhost:1337/crm/download-file/${file.id}`
                          : `https://2leads.dev/api/crm/download-file/${file.id}`
                      }
                      style={{ textDecoration: 'none', height: '66px' }}
                    >
                      <img
                        className='image-preview'
                        src={
                          process.env.NODE_ENV === 'development'
                            ? `http://localhost:1337/crm/get-preview/${file.id}`
                            : `https://2leads.dev/api/crm/get-preview/${file.id}`
                        }
                        alt='Preview'
                      />
                    </a>
                  ) : (
                    <img src={driveFiles} alt='file' />
                  )}
                  <a
                    href={
                      process.env.NODE_ENV === 'development'
                        ? `http://localhost:1337/crm/download-file/${file.id}`
                        : `https://2leads.dev/api/crm/download-file/${file.id}`
                    }
                    style={{ textDecoration: 'none' }}
                  >
                    <p className='taskModalFileName' variant='contained'>
                      {file.name}
                    </p>
                  </a>
                </div>
                <li
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '0.5rem',
                  }}
                >
                  {file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' ? (
                    <>
                      <div className='file-buttons'>
                        <a
                          href={
                            process.env.NODE_ENV === 'development'
                              ? `http://localhost:1337/crm/download-file/${file.id}`
                              : `https://2leads.dev/api/crm/download-file/${file.id}`
                          }
                          style={{
                            textDecoration: 'none',
                            marginRight: '0.25rem',
                          }}
                        >
                          <Button sx={{ minWidth: '40px' }}>
                            <ArrowCircleDownIcon />
                          </Button>
                        </a>
                        <img
                          aria-label='delete'
                          onClick={() => crmStore.deleteFile(file.id, item.id)}
                          className='close-btn'
                          src={trashModal}
                          alt='trashModal'
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        aria-label='delete'
                        onClick={() => crmStore.deleteFile(file.id, item.id)}
                        className='close-btn'
                        src={trashModal}
                        alt='trashModal'
                      />
                    </>
                  )}
                </li>
              </ul>
            ))
          )}
        </div>
        {dragMedia ? (
          <div
            className='dropMedia-area-active bgInput'
            onDragStart={(e) => dragStartHandler(e)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragOver={(e) => dragStartHandler(e)}
            onDrop={(e) => onDropHandler(e)}
          >
            <UploadFileIcon /> Отпустите файлы для загрузки
          </div>
        ) : (
          <div
            className='dropMedia-area bgInput'
            onDragStart={(e) => dragStartHandler(e)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragOver={(e) => dragStartHandler(e)}
          >
            <img src={filesIcon} alt='filesIcon' /> Перетащите файлы для загрузки или
            <button className='uploadFilesBtn' variant='contained' component='span' onClick={handleSelectFiles}>
              Загрузить
            </button>
            <label htmlFor='contained-button-file'>
              <Input
                accept='*'
                sx={{ display: 'none' }}
                id='contained-button-file'
                inputProps={{ multiple: true }}
                type='file'
                ref={fileSelectRef}
                onChange={(e) => fileUpload(e)}
              />
            </label>
          </div>
        )}
      </div>
      <div className='taskModalRight'>
        <div className='taskModalRightTop'>
          <p>История</p>
          <div style={{ width: '100%' }} className='bgInput'>
            <div className='taskModalLog'>
              <p>12.12.2000 - Ваня Пупкин</p>
            </div>
          </div>
        </div>
        <div className='taskModalRightBottom'>
          <p>Комментарии</p>
          <TextField
            name='comment'
            className='taskModalTitle bgInput'
            sx={{
              '& .MuiInput-underline:after': {
                borderBottom: 'none',
              },
              '& .MuiInput-underline:before': {
                borderBottom: 'none',
              },
            }}
            value={comment}
            onChange={(e) => handleChangeComment(e.target.value)}
            variant='standard'
            size='medium'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddComment();
              } else if (e.key === 'Escape') {
                handleClearComments();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => handleAddComment()}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className='taskModalComments'>
            {formData.comments.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </div>
        </div>
      </div>
      <TagModal openAddTag={openAddTag} handleCloseAddTag={handleCloseAddTag} addTagsToProject={addTagsToProject} />
      <DeleteModal openDelete={openDelete} handleCloseDelete={handleCloseDelete} deleteTask={deleteTask} />
    </Modal>
  );
};

export default observer(TaskModal2);
