import { useState } from 'react';

import { AddAccountDialog } from './AddAccountDialog';

import { Button } from '@mui/material';

/**
 *
 * @param {{ socket: import('socket.io-client').Socket }} param0
 * @returns
 */
export const AddAccountButton = ({ socket }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant='contained'
        onClick={() => setOpen(true)}
        sx={{
          textWrap: 'nowrap',
          fontSize: '12px',
          minHeight: '56px',
          minWidth: '120px',
          backgroundColor: '#333',
          color: '#fff',
          padding: '10px 20px',
          borderRadius: '6px 6px 6px 6px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          transition: 'background-color 0.3s, color 0.3s',
          '&:hover': {
            color: '#333',
          },
          '&:hover svg': {
            fill: '#333',
          },
          '& svg': {
            minWidth: '24px',
          },
        }}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
          <path d='M12 5.5V19.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M5 12.5H19' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
        <span>Add account</span>
      </Button>
      <AddAccountDialog open={open} onClose={() => setOpen(false)} socket={socket} />
    </>
  );
};
