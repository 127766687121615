import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Contract from "./Contract";
import Invoice from "./Invoice";
import Creative from "./Creative";
import Organization from "./Organization";
import { Container, Typography } from "@mui/material";
import Platforms from "./Platforms";
import { Link } from "react-router-dom";

const ORD = () => {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    if (window.location.href.indexOf("platforms") !== -1) {
      setActiveTab(4);
    }
    if (window.location.href.indexOf("invoices") !== -1) {
      setActiveTab(3);
    }
    if (window.location.href.indexOf("contracts") !== -1) {
      setActiveTab(2);
    }
    if (window.location.href.indexOf("organizations") !== -1) {
      setActiveTab(1);
    }
    if (window.location.href.indexOf("creatives") !== -1) {
      setActiveTab(0);
    }
  }, {});

  return (
    <>
      <Typography variant="h4" style={{ textAlign: "left", marginLeft: 50 }}>
        ОРД
      </Typography>
      <Container>
        <Tabs
          value={activeTab}
          onChange={(e, n) => setActiveTab(n)}
          aria-label="basic tabs example"
        >
          <Tab
            label="Креативы"
            index={0}
            to={"/ord/creatives"}
            component={Link}
          />
          <Tab
            label="Организации"
            index={1}
            to={"/ord/organizations"}
            component={Link}
          />
          <Tab
            label="Договоры"
            index={2}
            to={"/ord/contracts"}
            component={Link}
          />
          <Tab label="Акты" index={3} to={"/ord/invoices"} component={Link} />
          <Tab
            label="Платформы"
            index={4}
            to={"/ord/platforms"}
            component={Link}
          />
        </Tabs>

        {activeTab === 0 ? <Creative /> : null}
        {activeTab === 1 ? <Organization /> : null}
        {activeTab === 2 ? <Contract /> : null}
        {activeTab === 3 ? <Invoice /> : null}
        {activeTab === 4 ? <Platforms /> : null}
      </Container>
    </>
  );
};

export default ORD;
