import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  color: '#fff',
  backgroundColor: '#000',
  fontSize: '14px',
  fontWeight: 'semibold',
  padding: '10px',
}));

/**
 *
 * @param {{ button: { icon: JSX.Element, label: string, onClick: () => void } }} param0
 * @returns {JSX.Element}
 */
export const ActionButton = ({ button }) => {
  return (
    <StyledButton key={button.label} onClick={button.onClick}>
      {button.icon}
      {button.label}
    </StyledButton>
  );
};
